
    .Spin{ 
        transform-origin: center center;
        animation: spin 2s linear infinite;
    }
        @keyframes spin {
            50% { transform: rotate(180deg); }
            100% { transform: rotate(360deg); }
        }
    
    
    .anim-trans0 {
        animation: anim1 4s ease-in-out;
    }
  
    .anim-trans1 {
        animation: anim1 4s ease-in-out;
    }
    .anim-trans2 {
        animation: anim1 4s ease-in-out;
    }
    .anim-trans3 {
        animation: anim1 4s ease-in-out;
    }
    .anim-trans4 {
        animation: anim1 4s ease-in-out;
    }
    .anim-trans5 {
        animation: anim1 4s ease-in-out;
    }

  @keyframes anim1{
    0% {
        background-color: blue;
    }
    20% { 
        background-color: red;
    }
    40% {
         background-color: yellow;
    }
    60% { 
        background-color: white;
    }
    80% { 
        background-color: black;
    }  
    95% { 
        background-color: orange;
    }
    100% { 
        background-color: green;
    }
}

  