	.BCPrimary{background:#ad182f;}
	.BCPrimaryDisabled{background:#D58B97;}
	.BCSecondary{background:#4693BD;}
	.BCSecondaryDisabled{background:#8BBBD5;}

	.FCPrimary{color:#ad182f;}
	.FCPrimaryDisabled{color:#D58B97;}
	.FCSecondary{color:#4693BD;}
	.FCSecondaryDisabled{color:#8BBBD5;}
	/************************ BACKGROUND ****************************/
	.Transparent{background:rgba(0,0,0,0);}
	.BCInherit{background-color:inherit;}
	.BCRed{background-color:red;}
	.BCDarkRed{background-color:#8B0000;}
	.BCDarkRed2{background-color:#cc3333;}
	.BCLightBlue{background-color:#5299e0;}
	.BCGreen{background-color:green;}
	.BCDarkGreen2{background-color:#005000;}
	.BCDarkGreen{background-color:#003c00;}
	.BCBlue{background-color:blue;}
	.BCDarkBlue{background-color:#001b64;}
	.BCYellow{background-color:yellow;}
	.BCWhite{background-color:white;}
	.BCBlack{background-color:#333333;}
	.BCBlack2{background-color:black;}
	.BCMagenta{background-color:magenta;}
	.BCGold{background-color:#ffd700;}
	.BCRed1{background-color:#FF0000;}
	.BCRed2{background-color:#FD1C03;}
	.BCRed3{background-color:#FF3300;}
	.BCRed4{background-color:#FF6600;}
	.BCGreen1{background-color:#00FF00;}
	.BCGreen2{background-color:#00FF33;}
	.BCGreen3{background-color:#00FF66;}
	.BCGreen4{background-color:#33FF00;}
	.BCPurple1{background-color:#9D00FF;}
	.BCPurple2{background-color:#CC00FF;}
	.BCPurple3{background-color:#6E0DD0;}
	.BCPurple4{background-color:#9900FF;}
	.BCYellow1{background-color:#FFFF00;}
	.BCYellow2{background-color:#FFFF33;}
	.BCYellow3{background-color:#F2EA02;}
	.BCYellow4{background-color:#E6FB04;}
	.BCPink1{background-color:#CC00FF;}
	.BCPink2{background-color:#FF0099;}
	.BCPink3{background-color:#FF00CC;}
	.BCPink4{background-color:#FF00FF;}
	.BCBlue1{background-color:#00FFFF;}
	.BCBlue2{background-color:#099FFF;}
	.BCBlue2Trans{background-color: #89beff;}
	.BCBlue3{background-color:#0062FF;}
	.BCBlue4{background-color:#0033FF;}

	.BC0{background-color:#9FB6CD;}
	.BC1{background-color:#7d9dbc;}/*9FB6CD/**/
	.BC2{background-color:#58ACFA;}
	.BC3{background-color:#D8D8D8;}
	.BC4{background-color:#7D9EC0;}
	.BC5{background-color:#E0E6F8;}
	.BC6{background-color:#BDBDBD;}
	.BC7{background-color:#CEECF5;}
	.BC8{background-color:#E6E6E6;}
	.BC9{background-color:#9FB6CD;}
	.BC10{background-color:#D3D3D3;}
	
	.BCDarkGray{background-color:#3d3d3d;}
	.BCGray1{background-color:#FAFAFA;}
	.BCGray2{background-color:#F2F2F2;}
	.BCGray3{background-color:#F8F8F8;}
	.BCGray4{background-color:#E6E6E6;}
	.BCGray5{background-color:#D8D8D8;}
	.BCGray6{background-color:#dfdfdf;}
	.BCGray7{background-color:#E5E5E5;}
	.BCGray8{background-color:#f0f0f0;}
	.BCGray9{background-color:#f3f3f3;}
	.BCGray10{background-color:#f6f6f6;}
	.BCGray11{background-color:#f7f7f7;}
	.BCFB{background-color:#3b5998;}
	.BCTwitter{background-color:#4099FF;}

	.BCOpaqueBlack{background-color:rgba(0,0,0,0.6);}
	.BCOpaqueWhite{background-color:rgba(255,255,255,0.6);}
	.BCTransparent1{background-color:rgba(20,20,20,0.60);}
	.BCTransparent13{background-color:rgba(20,20,20,0.40);}
	.BCTransparent14{background-color:rgba(20,20,20,0.20);}
	.BCTransparent15{background-color:rgba(70,70,70,0.60);}
	.BCTransparent16{background-color:rgba(70,70,70,0.40);}
	.BCTransparent17{background-color:rgba(70,70,70,0.20);}
	.BCTransparent2{background-color:rgba(70,70,70,0.25);}
	.BCTransparent3{background-color:rgba(110,110,110,0.60);}
	.BCTransparent20{background-color:rgba(110,110,110,0.40);}
	.BCTransparent21{background-color:rgba(110,110,110,0.20);}
	.BCTransparent4{background-color:rgba(255,255,255,0.7);}
	.BCTransparent5{background-color:rgba(0,0,0,0.4);}
	.BCTransparent6{background-color:rgba(30,30,30,0.4);}
	.BCTransparent7{background-color:rgba(249,247,102,0.25);}
	.BCTransparent8{background-color:rgba(0,92,70,0.25);}
	.BCTransparent9{background-color:rgba(103,166,249,0.60);}
	.BCTransparent18{background-color:rgba(103,166,249,0.40);}
	.BCTransparent19{background-color:rgba(103,166,249,0.20);}
	.BCTransparent10{background-color:rgba(249,176,103,0.25);}
	.BCTransparent11{background-color:rgba(177,53,168,0.25);}
	.BCTransparent12{background-color:rgba(53,76,177,0.60);}
	.BCInvisible{background-color:rgba(255,255,255,0);}


	/************************ COLOR ****************************/
	.FCRed{color:red;}
	.FCDarkRed{color:#8B0000;}
	.FCGreen{color:green;}
	.FCDarkGreen{color:#003c00;}
	.FCBlue{color:blue;}
	.FCDarkBlue{color:#001b64;}
	.FCYellow{color:yellow;}
	.FCOrange{color:orange;}
	.FCTOrange{color:orange;}
	.FCTurquoise{color:turquoise;}
	.FCWhite{color:white;}
	.FCBlack{color:black;}
	.FCMagenta{color:magenta;}
	.FCGold{color:#ffd700;}

	.FCRed1{color:#FF0000;}
	.FCRed2{color:#FD1C03;}
	.FCRed3{color:#FF3300;}
	.FCRed4{color:#FF6600;}
	.FCGreen1{color:#00FF00;}
	.FCGreen2{color:#00FF33;}
	.FCGreen3{color:#00FF66;}
	.FCGreen4{color:#33FF00;}
	.FCPurple1{color:#9D00FF;}
	.FCPurple2{color:#CC00FF;}
	.FCPurple3{color:#6E0DD0;}
	.FCPurple4{color:#9900FF;}
	.FCYellow1{color:#FFFF00;}
	.FCYellow2{color:#FFFF33;}
	.FCYellow3{color:#F2EA02;}
	.FCYellow4{color:#E6FB04;}
	.FCPink1{color:#CC00FF;}
	.FCPink2{color:#FF0099;}
	.FCPink3{color:#FF00CC;}
	.FCPink4{color:#FF00FF;}
	.FCBlue1{color:#00FFFF;}
	.FCBlue2{color:#099FFF;}
	.FCBlue3{color:#0062FF;}
	.FCBlue4{color:#0033FF;}
	.FCTwitter{color:#4099FF;}
	.FCFB{color:#3b5998;}

	.FCGray{color:gray;}
	.FCDarkGray{color:#3d3d3d;}
  .FCGray1{color:#FAFAFA;}
	.FCGray2{color:#F2F2F2;}
	.FCGray3{color:#F8F8F8;}
	.FCGray4{color:#E6E6E6;}
	.FCGray5{color:#D8D8D8;}
	.FCGray6{color:#dfdfdf;}
	.FCGray7{color:#E5E5E5;}
	.FCGray8{color:#f0f0f0;}
	.FCGray9{color:#f3f3f3;}
	.FCGray10{color:#f6f6f6;}
	.FCGray11{color:#f7f7f7;}
	.FCGray12{color:#b2b2b2;}

	.FC0{color:#9FB6CD;}
	.FC1{color:#7d9dbc;}/*9FB6CD/**/
	.FC2{color:#58ACFA;}
	.FC3{color:#D8D8D8;}
	.FC4{color:#7D9EC0;}
	.FC5{color:#E0E6F8;}
	.FC6{color:#BDBDBD;}
	.FC7{color:#CEECF5;}
	.FC8{color:#E6E6E6;}
	.FC9{color:#9FB6CD;}
	.FC10{color:#D3D3D3;}
	.FCRed{
	-webkit-input-placeholder: color red;
	-moz-placeholder: color red;
	-o-placeholder: color red;
	-ms-placeholder:color red;}

	/*************  Hover Color ************************/
	.FCOverWhite:hover{color:white;}
	.FCOverBlack:hover{color:black;}
	.FCOverRed:hover{color:red;}
	.FCOverDarkRed:hover{color:#8B0000;}
	.FCOverBlue:hover{color:blue;}
	.FCOverDarkBlue:hover{color:#001b64;}
	.FCOverGreen:hover{color:green;}
	.FCOverOrange:hover{color:orange;}
	.FCOverBlack:hover{color:black;}
	.FCOverMagenta:hover{color:magenta;}
	.FCOverYellow:hover{color:yellow;}
	.FCOverGray:hover{color:gray;}
	.FCOverDarkGray:hover{color:#3d3d3d;}
	.FCOverTurquoise:hover{color:turquoise;}
	.FCOver0:hover{color:#9FB6CD;}
	.FCOver1:hover{color:#7d9dbc;}/*9FB6CD/**/
	.FCOver2:hover{color:#58ACFA;}
	.FCOver3:hover{color:#D8D8D8;}
	.FCOver4:hover{color:#7D9EC0;}
	.FCOver5:hover{color:#E0E6F8;}
	.FCOver6:hover{color:#BDBDBD;}
	.FCOver7:hover{color:#CEECF5;}
	.FCOver8:hover{color:#E6E6E6;}
	.FCOver9:hover{color:#9FB6CD;}
	.FCOver10:hover{color:#D3D3D3;}
	.FCTwitter:hover{color:#4099FF;}
	.FCFB:hover{color:#3b5998;}

	.FCOverRed1:hover{color:#FF0000;}
	.FCOverRed2:hover{color:#FD1C03;}
	.FCOverRed3:hover{color:#FF3300;}
	.FCOverRed4:hover{color:#FF6600;}
	.FCOverGreen1:hover{color:#00FF00;}
	.FCOverGreen2:hover{color:#00FF33;}
	.FCOverGreen3:hover{color:#00FF66;}
	.FCOverGreen4:hover{color:#33FF00;}
	.FCOverPurple1:hover{color:#9D00FF;}
	.FCOverPurple2:hover{color:#CC00FF;}
	.FCOverPurple3:hover{color:#6E0DD0;}
	.FCOverPurple4:hover{color:#9900FF;}
	.FCOverYellow1:hover{color:#FFFF00;}
	.FCOverYellow2:hover{color:#FFFF33;}
	.FCOverYellow3:hover{color:#F2EA02;}
	.FCOverYellow4:hover{color:#E6FB04;}
	.FCOverPink1:hover{color:#CC00FF;}
	.FCOverPink2:hover{color:#FF0099;}
	.FCOverPink3:hover{color:#FF00CC;}
	.FCOverPink4:hover{color:#FF00FF;}
	.FCOverBlue1:hover{color:#00FFFF;}
	.FCOverBlue2:hover{color:#099FFF;}
	.FCOverBlue3:hover{color:#0062FF;}
	.FCOverBlue4:hover{color:#0033FF;}


	/***********  AFTER FC OVER **************/
	.FCAfterWhite:after{color:white;}
	.FCAfterRed:after{color:red;}
	.FCAfterDarkRed:after{color:#8B0000;}
	.FCAfterBlue:after{color:blue;}
	.FCAfterGreen:after{color:green;}
	.FCAfterOrange:after{color:orange;}
	.FCAfterBlack:after{color:black;}
	.FCAfterMagenta:after{color:magenta;}
	.FCAftverYellow:after{color:yellow;}
	.FCAfterGray:after{color:gray;}
	.FCAfterDarkGray:after{color:#3d3d3d;}
	.FCAfterTurquoise:after{color:turquoise;}
	.FCAfter0:after{color:#9FB6CD;}
	.FCAfter1:after{color:#7d9dbc;}/*9FB6CD/**/
	.FCAfter2:after{color:#58ACFA;}
	.FCAfter3:after{color:#E0E6F8;}
	.FCAfter4:after{color:#BDBDBD;}
	.FCAfter5:after{color:#CEECF5;}
	.FCAfter6:after{color:#E6E6E6;}
	.FCAfter7:after{color:#9FB6CD;}
	.FCTwitter:after{color:#4099FF;}
	.FCFB:after{color:#3b5998;}

	.FCRed1:after{color:#FF0000;}
	.FCRed2:after{color:#FD1C03;}
	.FCRed3:after{color:#FF3300;}
	.FCRed4:after{color:#FF6600;}
	.FCGreen1:after{color:#00FF00;}
	.FCGreen2:after{color:#00FF33;}
	.FCGreen3:after{color:#00FF66;}
	.FCGreen4:after{color:#33FF00;}
	.FCPurple1:after{color:#9D00FF;}
	.FCPurple2:after{color:#CC00FF;}
	.FCPurple3:after{color:#6E0DD0;}
	.FCPurple4:after{color:#9900FF;}
	.FCYellow1:after{color:#FFFF00;}
	.FCYellow2:after{color:#FFFF33;}
	.FCYellow3:after{color:#F2EA02;}
	.FCYellow4:after{color:#E6FB04;}
	.FCPink1:after{color:#CC00FF;}
	.FCPink2:after{color:#FF0099;}
	.FCPink3:after{color:#FF00CC;}
	.FCPink4:after{color:#FF00FF;}
	.FCBlue1:after{color:#00FFFF;}
	.FCBlue2:after{color:#099FFF;}
	.FCBlue3:after{color:#0062FF;}
	.FCBlue4:after{color:#0033FF;}

	/*************  active Color ************************/
	.ActiveRed i{color:red;}
	.ActiveBlue i{color:blue;}
	.ActiveGreen i{color:green;}
	.ActiveOrange i{color:orange;}
	.ActiveBlack i{color:black;}
	.ActiveMagenta i{color:magenta;}
	.ActiveYellow i{color:yellow;}
	.ActiveGray i{color:gray;}
	.ActiveDarkGray i{color:#3d3d3d;}
	.ActiveTurquoise i{color:turquoise;}

	/**************Hover Color Background ***************/
	.BCOverWhite:hover{background-color:white;}
	.BCOverRed:hover{background-color:red;}
	.BCOverDarkRed:hover{background-color:#8B0000;}
	.BCOverBlue:hover{background-color:blue;}
	.BCOverGreen:hover{background-color:green;}
	.BCOverOrange:hover{background-color:orange;}
	.BCOverBlack:hover{background-color:black;}
	.BCOverMagenta:hover{background-color:magenta;}
	.BCOverYellow:hover{background-color:yellow;}
	.BCOverGray:hover{background-color:gray;}
	.BCOverDarkGray:hover{background-color:#3d3d3d;}
	.BCOverTurquoise:hover{background-color:turquoise;}
	.BCOver0:hover{background-color:#9FB6CD;}
	.BCOver1:hover{background-color:#7d9dbc;}/*9FB6CD/**/
	.BCOver2:hover{background-color:#58ACFA;}
	.BCOver3:hover{background-color:#D8D8D8;}
	.BCOver4:hover{background-color:#58ACFA;}
	.BCOver5:hover{background-color:#E0E6F8;}
	.BCOver6:hover{background-color:#BDBDBD;}
	.BCOver7:hover{background-color:#CEECF5;}
	.BCOver8:hover{background-color:#E6E6E6;}
	.BCOver9:hover{background-color:#9FB6CD;}
	.BCOver10:hover{background-color:rgb(202, 185, 185);}
	
	.BCOverDarkGray:hover{background-color:#3d3d3d;}
	.BCOverGray1:hover{background-color:#FAFAFA;}
	.BCOverGray2:hover{background-color:#F2F2F2;}
	.BCOverGray3:hover{background-color:#F8F8F8;}
	.BCOverGray4:hover{background-color:#E6E6E6;}
	.BCOverGray5:hover{background-color:#D8D8D8;}
	.BCOverTransparent1:hover{background-color:rgba(20,20,20,0.25);}
	.BCOverTransparent2:hover{background-color:rgba(70,70,70,0.25);}
	.BCOverTransparent3:hover{background-color:rgba(110,110,110,0.25);}
	.BCOverTransparent4:hover{background-color:rgba(255,255,255,0.7);}
	.BCOverTransparent5:hover{background-color:rgba(0,0,0,0.4);}
	.BCOverTransparent6:hover{background-color:rgba(30,30,30,0.4);}
	.BCOverTransparent7:hover{background-color:rgba(249,247,102,0.25);}/*yellow*/
	.BCOverTransparent8:hover{background-color:rgba(0,92,70,0.25);}/*gree*/
	.BCOverTransparent9:hover{background-color:rgba(103,166,249,0.25);}/*bleu*/
	.BCOverTransparent10:hover{background-color:rgba(249,176,103,0.25);}/*orange*/
	.BCOverTransparent11:hover{background-color:rgba(177,53,168,0.25);}/*violet*/
	.BCOverTransparent12:hover{background-color:rgba(53,76,177,0.25);}/*bleu fonce*/
	.BCOverInvisible:hover{background-color:rgba(255,255,255,0);}
	.BCTwitter:hover{background-color:#4099FF;}
	.BCFB:hover{background-color:#3b5998;}

	.BCOverRed1:hover{background-color:#FF0000;}
	.BCOverRed2:hover{background-color:#FD1C03;}
	.BCOverRed3:hover{background-color:#FF3300;}
	.BCOverRed4:hover{background-color:#FF6600;}
	.BCOverGreen1:hover{background-color:#00FF00;}
	.BCOverGreen2:hover{background-color:#00FF33;}
	.BCOverGreen3:hover{background-color:#00FF66;}
	.BCOverGreen4:hover{background-color:#33FF00;}
	.BCOverPurple1:hover{background-color:#9D00FF;}
	.BCOverPurple2:hover{background-color:#CC00FF;}
	.BCOverPurple3:hover{background-color:#6E0DD0;}
	.BCOverPurple4:hover{background-color:#9900FF;}
	.BCOverYellow1:hover{background-color:#FFFF00;}
	.BCOverYellow2:hover{background-color:#FFFF33;}
	.BCOverYellow3:hover{background-color:#F2EA02;}
	.BCOverYellow4:hover{background-color:#E6FB04;}
	.BCOverPink1:hover{background-color:#CC00FF;}
	.BCOverPink2:hover{background-color:#FF0099;}
	.BCOverPink3:hover{background-color:#FF00CC;}
	.BCOverPink4:hover{background-color:#FF00FF;}
	.BCOverBlue1:hover{background-color:#00FFFF;}
	.BCOverBlue2:hover{background-color:#099FFF;}
	.BCOverBlue2Trans:hover{background-color:#89beff;}
	.BCOverBlue3:hover{background-color:#0062FF;}
	.BCOverBlue4:hover{background-color:#0033FF;}

	/***********  AFTER FC OVER **************/
	.BCAfterWhite:after{background-color:white;}
	.BCAfterRed:after{background-color:red;}
	.BCAfterDarkRed:after{background-color:#8B0000;}
	.BCAfterBlue:after{background-color:blue;}
	.BCAfterGreen:after{background-color:green;}
	.BCAfterOrange:after{background-color:orange;}
	.BCAfterBlack:after{background-color:black;}
	.BCAfterMagenta:after{background-color:magenta;}
	.BCAftverYellow:after{background-color:yellow;}
	.BCAfterGray:after{background-color:gray;}
	.BCAfterDarkGray:after{background-color:#3d3d3d;}
	.BCAfterTurquoise:after{background-color:turquoise;}
	
	.BCAfter0:after{background-color:#9FB6CD;}
	.BCAfter1:after{background-color:#7d9dbc;}/*9FB6CD/**/
	.BCAfter2:after{background-color:#58ACFA;}
	.BCAfter3:after{background-color:#D8D8D8;}
	.BCAfter4:after{background-color:#7D9EC0;}
	.BCAfter5:after{background-color:#E0E6F8;}
	.BCAfter6:after{background-color:#BDBDBD;}
	.BCAfter7:after{background-color:#CEECF5;}
	.BCAfter8:after{background-color:#E6E6E6;}
	.BCAfter9:after{background-color:#9FB6CD;}
	.BCAfter10:after{background-color:rgb(202, 185, 185);}
	
	.BCTwitter:after{background-color:#4099FF;}
	.BCFB:after{background-color:#3b5998;}

	.BCAfterDarkGray:after{background-color:#3d3d3d;}
	.BCAfterGray1:after{background-color:#FAFAFA;}
	.BCAfterGray2:after{background-color:#F2F2F2;}
	.BCAfterGray3:after{background-color:#F8F8F8;}
	.BCGray4:after{background-color:#E6E6E6;}
	.BCAfterGray5:after{background-color:#D8D8D8;}
	.BCAfterTransparent1:after{background-color:rgba(20,20,20,0.25);}
	.BCAfterTransparent2:after{background-color:rgba(70,70,70,0.25);}
	.BCAfterTransparent3:after{background-color:rgba(110,110,110,0.25);}
	.BCAfterTransparent4:after{background-color:rgba(255,255,255,0.7);}
	.BCAfterTransparent5:after{background-color:rgba(0,0,0,0.4);}
	.BCAfterTransparent6:after{background-color:rgba(30,30,30,0.4);}
	.BCAfterTransparent7:after{background-color:rgba(249,247,102,0.25);}/*yellow*/
	.BCAfterTransparent8:after{background-color:rgba(0,92,70,0.25);}/*gree*/
	.BCAfterTransparent9:after{background-color:rgba(103,166,249,0.25);}/*bleu*/
	.BCAfterTransparent10:after{background-color:rgba(249,176,103,0.25);}/*orange*/
	.BCAfterTransparent11:after{background-color:rgba(177,53,168,0.25);}/*violet*/
	.BCAfterTransparent12:after{background-color:rgba(53,76,177,0.25);}/*bleu fonce*/

	.BCAfterRed1:after{background-color:#FF0000;}
	.BCAfterRed2:after{background-color:#FD1C03;}
	.BCAfterRed3:after{background-color:#FF3300;}
	.BCAfterRed4:after{background-color:#FF6600;}
	.BCAfterGreen1:after{background-color:#00FF00;}
	.BCAfterGreen2:after{background-color:#00FF33;}
	.BCAfterGreen3:after{background-color:#00FF66;}
	.BCAfterGreen4:after{background-color:#33FF00;}
	.BCAfterPurple1:after{background-color:#9D00FF;}
	.BCAfterPurple2:after{background-color:#CC00FF;}
	.BCAfterPurple3:after{background-color:#6E0DD0;}
	.BCAfterPurple4:after{background-color:#9900FF;}
	.BCAfterYellow1:after{background-color:#FFFF00;}
	.BCAfterYellow2:after{background-color:#FFFF33;}
	.BCAfterYellow3:after{background-color:#F2EA02;}
	.BCAfterYellow4:after{background-color:#E6FB04;}
	.BCAfterPink1:after{background-color:#CC00FF;}
	.BCAfterPink2:after{background-color:#FF0099;}
	.BCAfterPink3:after{background-color:#FF00CC;}
	.BCAfterPink4:after{background-color:#FF00FF;}
	.BCAfterBlue1:after{background-color:#00FFFF;}
	.BCAfterBlue2:after{background-color:#099FFF;}
	.BCAfterBlue3:after{background-color:#0062FF;}
	.BCAfterBlue4:after{background-color:#0033FF;}


	.HighlightBlue2White{
		background-color: #099FFF;
		color: white;
	}
