	/***********************  BORDERS ****************************/
	.NoBorder{border-style: solid;border-width:0 0 0 0 !important;}
	.Border{border-style: solid;border-width:1px 1px 1px 1px;}
	.TopBorder{border-style: solid;border-width:1px 0 0 0;}
	.RightBorder{border-style: solid;border-width:0 1px 0 0;}
	.BottomBorder{border-style: solid;border-width:0 0 1px 0;}
	.LeftBorder{border-style:solid;border-width:0 0 0 1px;}
	
	.Border2px{border-style: solid;border-width:2px 2px 2px 2px;}
	.TopBorder2px{border-style: solid;border-width:2px 0 0 0;}
	.RightBorder2px{border-style: solid;border-width:0 2px 0 0;}
	.BottomBorder2px{border-style: solid;border-width:0 0 2px 0;}
	.LeftBorder2px{border-style:solid;border-width:0 0 0 2px;}
	
	.Border3px{border-style: solid;border-width:3px 3px 3px 3px;}
	.TopBorder3px{border-style: solid;border-width:3px 0 0 0;}
	.RightBorder3px{border-style: solid;border-width:0 3px 0 0;}
	.BottomBorder3px{border-style: solid;border-width:0 0 3px 0;}
	.LeftBorder3px{border-style:solid;border-width:0 0 0 3px;}
	
	.BorderV{border-style: solid;border-width:0 1px 0px 1px;}
	.BorderH{border-style: solid;border-width:1px 0px 1px 0;}
	.Border3R{border-style: solid;border-width:1px 0 1px 1px;}
	.Border3L{border-style: solid;border-width:1px 1px 1px 0;}
	.Border3T{border-style: solid;border-width:0 1px 1px 1px;}
	.Border3B{border-style: solid;border-width:1px 1px 0 1px;}
	
	.BorderV2px{border-style: solid;border-width:0 2px 0px 2px;}
	.BorderH2px{border-style: solid;border-width:2px 0px 2px 0;}
	.Border3R2px{border-style: solid;border-width:2px 0 2px 2px;}
	.Border3L2px{border-style: solid;border-width:2px 2px 2px 0;}
	.Border3T2px{border-style: solid;border-width:0 2px 2px 2px;}
	.Border3B2px{border-style: solid;border-width:2px 2px 0 2px;}
	/***********************  BORDERS ****************************/
	.DashedTopBorder{border-style: dashed;border-width:3px 0 0 0;}
	.DashedRightBorder{border-style: dashed;border-width:0 3px 0 0;}
	.DashedBottomBorder{border-style: dashed;border-width:0 0 3px 0;}
	.DashedLeftBorder{border-style: dashed;border-width:0 0 0 3px;}
	.DashedBorder{border-style: dashed;border-width:1px 1px 1px 1px;}
	.DashedBorder3R{border-style: dashed;border-width:3px 0 3px 3px;}
	.DashedBorder3L{border-style: dashed;border-width:3px 3px 3px 0;}
	.DashedBorder3T{border-style: dashed;border-width:0 3px 3px 3px;}
	.DashedBorder3B{border-style: dashed;border-width:3px 3px 0 3px;}
	/*****************  BORDER COLOR ****************************/
	.BorderCWhite{border-color:white;}
	.BorderCBlack{border-color:black;}
	.BorderCRed{border-color:red;}
	.BorderCDarkRed{border-color:#8B0000;}
	.BorderCGreen{border-color:green;}
	.BorderCBlue{border-color:blue;}
	.BorderCGold{border-color:gold;}
	.BorderCYellow{border-color:yellow;}
	.BorderCGray{border-color:#D9D9D9;}	
	.BorderCLightGray{border-color:#f4eeee;}
	.BorderC1{border-color:#9FB6CD;}
	.BorderC2{border-color:#a7c8db;}
	.BorderC3{border-color:#F1F1F1;}
	.BorderC4{border-color:#7D9EC0;}
	.BorderC5{border-color:#C9C9C9;}
	.BorderC6{border-color:#CAE1FF;}
	.BorderC7{border-color:#FDFCDC;}
	.BorderC8{border-color:#7D9EC0;}
	.BorderC9{border-color:#CEECF5;}
	.BorderC10{border-color:#E6E6E6;}
	.BorderC11{border-color:#9FB6CD;}
	
	.BorderOverCWhite:hover{border-color:white;}
	.BorderOverCBlack:hover{border-color:black;}
	.BorderOverCRed:hover{border-color:red;}
	.BorderOverCDarkRed:hover{border-color:#8B0000;}
	.BorderOverCGold:hover{border-color:gold;}
	.BorderOverCYellow:hover{border-color:yellow;}
	.BorderOverCGrey:hover{border-color:#D9D9D9;}	
	.BorderOverC1:hover{border-color:#9FB6CD;}
	.BorderOverC2:hover{border-color:#a7c8db;}
	.BorderOverC3:hover{border-color:#F1F1F1;}
	.BorderOverC4:hover{border-color:#7D9EC0;}
	.BorderOverC5:hover{border-color:#C9C9C9;}
	.BorderOverC6:hover{border-color:#CAE1FF;}
	.BorderOverC7:hover{border-color:#FDFCDC;}
	.BorderOverC8:hover{border-color:#7D9EC0;}
	.BorderOverC9:hover{border-color:#CEECF5;}
	.BorderOverC10:hover{border-color:#E6E6E6;}
	.BorderOverC11:hover{border-color:#9FB6CD;}
	
	/*****************  BORDER RADIUS *********************/
	.BorderRadius0{border-radius: 0 !important;}
	.TopBorderRadius0{border-radius: 0 0 0 0 !important;}
	.BottomBorderRadius0{border-radius: 0 0 0 0 !important;}
	.LeftBorderRadius0{border-radius: 0 0 0 0 !important;}
	.TopLeftBorderRadius0{border-radius: 0 0 0 0 !important;}
	.BottomLeftBorderRadius0{border-radius: 0 0 0 0 !important;}
	.RightBorderRadius0{border-radius: 0 0 0 0 !important;}
	.TopRightBorderRadius0{border-radius: 0 0 0 0 !important;}
	.BottomRightBorderRadius0{border-radius: 0 0 0 0 !important;}
	
	.BorderRadius5{border-radius: 5px !important;}
	.BorderRadius2{border-radius: 2px !important;}
	.TopBorderRadius2{border-radius: 2px 2px 0 0 !important;}
	.BottomBorderRadius2{border-radius: 0 0 2px 2px !important;}
	.LeftBorderRadius2{border-radius: 2px 0 0 2px !important;}
	.TopLeftBorderRadius2{border-radius: 2px 0 0 0 !important;}
	.BottomLeftBorderRadius2{border-radius: 0 0 0 2px !important;}
	.RightBorderRadius2{border-radius: 0 2px 2px 0 !important;}
	.TopRightBorderRadius2{border-radius: 0 2px 0 0 !important;}
	.BottomRightBorderRadius2{border-radius: 0 0 2px 0 !important;}
	
	.BorderRadius5{border-radius: 5px !important;}
	.TopBorderRadius5{border-radius: 5px 5px 0 0 !important;}
	.BottomBorderRadius5{border-radius: 0 0 5px 5px !important;}
	.LeftBorderRadius5{border-radius: 5px 0 0 5px !important;}
	.TopLeftBorderRadius5{border-radius: 5px 0 0 0 !important;}
	.BottomLeftBorderRadius5{border-radius: 0 0 0 5px !important;}
	.RightBorderRadius5{border-radius: 0 5px 5px 0 !important;}
	.TopRightBorderRadius5{border-radius: 0 5px 0 0 !important;}
	.BottomRightBorderRadius5{border-radius: 0 0 5px 0 !important;}
	
	.BorderRadius10{border-radius: 10px !important;}
	.TopBorderRadius10{border-radius: 10px 10px 0 0 !important;}
	.BottomBorderRadius10{border-radius: 0 0 10px 10px !important;}
	.LeftBorderRadius10{border-radius: 10px 0 0 10px !important;}
	.TopLeftBorderRadius10{border-radius: 10px 0 0 0 !important;}
	.BottomLeftBorderRadius10{border-radius: 0 0 0 10px !important;}
	.RightBorderRadius10{border-radius: 0 10px 10px 0 !important;}
	.TopRightBorderRadius10{border-radius: 0 10px 0 0 !important;}
	.BottomRightBorderRadius10{border-radius: 0 0 10px 0 !important;}
	
	.BorderRadius20{border-radius: 20px !important;}
	.TopBorderRadius20{border-radius: 20px 20px 0 0 !important;}
	.BottomBorderRadius20{border-radius: 0 0 20px 20px !important;}
	.LeftBorderRadius20{border-radius: 20px 0 0 20px !important;}
	.TopLeftBorderRadius20{border-radius: 20px 0 0 0 !important;}
	.BottomLeftBorderRadius20{border-radius: 0 0 0 20px !important;}
	.RightBorderRadius20{border-radius: 0 20px 20px 0 !important;}
	.TopRightBorderRadius20{border-radius: 0 20px 0 0 !important;}
	.BottomRightBorderRadius20{border-radius: 0 0 20px 0 !important;}
	
	.Circular1px{border-radius: 50%;padding-left: 1px; padding-right: 1px;}
	.Circular2px{border-radius: 50%;padding-left: 2px; padding-right: 2px;}
	.Circular3px{border-radius: 50%;padding-left: 3px; padding-right: 3px;}
	.Circular4px{border-radius: 50%;padding-left: 4px; padding-right: 4px;}
	.Circular5px{border-radius: 50%;padding-left: 5px; padding-right: 5px;}
	.Circular6px{border-radius: 50%;padding: 6px;}
	.Circular7px{border-radius: 50%;padding: 7px;}
	.Circular8px{border-radius: 50%;padding: 8px;}
	.Circular9px{border-radius: 50%;padding: 9px;}
	.Circular10px{border-radius: 50%;padding: 10px;}
	.Circular15px{border-radius: 50%;padding: 15px;}
	.Circular20px{border-radius: 50%;padding: 20px;}
	.Circular25px{border-radius: 50%;padding: 25px;}
	.Circular30px{border-radius: 50%;padding: 30px;}