p {font-size: inherit;}
span {font-size: inherit;}
blockquote {font-size: inherit;}
h1 {font-size: inherit;}
h2 {font-size: inherit;}
h3 {font-size: inherit;}
h4 {font-size: inherit;}

@media screen and (max-width: 1920px) {

    .FS43 {font-size: calc(8px + (8 - 8) * ((100vw - 600px) / (1920 - 600)));}
    .FS42 {font-size: calc(9px + (10 - 9) * ((100vw - 600px) / (1920 - 600)));}
    .FS41 {font-size: calc(9px + (12 - 9) * ((100vw - 600px) / (1920 - 600)));}
    .FS40 {font-size: calc(10px + (14 - 10) * ((100vw - 600px) / (1920 - 600)));}
    .FS39 {font-size: calc(12px + (16 - 12) * ((100vw - 600px) / (1920 - 600))); }
    .FS38 {font-size: calc(14px + (18 - 14) * ((100vw - 600px) / (1920 - 600))); }
    .FS37 {font-size: calc(15px + (20 - 15) * ((100vw - 600px) / (1920 - 600)));}
    .FS36 {font-size: calc(16px + (22 - 16) * ((100vw - 600px) / (1920 - 600)));}
    .FS35 {font-size: calc(17px + (24 - 17) * ((100vw - 600px) / (1920 - 600)));}
    .FS34 {font-size: calc(18px + (26 - 18) * ((100vw - 600px) / (1920 - 600)));}
    .FS33 {font-size: calc(19px + (28 - 19) * ((100vw - 600px) / (1920 - 600)));}
    .FS32 {font-size: calc(20px + (30 - 20) * ((100vw - 600px) / (1920 - 600)));}
    .FS31 {font-size: calc(21px + (32 - 21) * ((100vw - 600px) / (1920 - 600)));}
    .FS30 {font-size: calc(22px + (34 - 22) * ((100vw - 600px) / (1920 - 600)));}
    .FS29 {font-size: calc(23px + (36 - 23) * ((100vw - 600px) / (1920 - 600)));}
    .FS28 {font-size: calc(24px + (38 - 24) * ((100vw - 600px) / (1920 - 600)));}
    .FS27 {font-size: calc(25px + (40 - 25) * ((100vw - 600px) / (1920 - 600)));}
    .FS26 {font-size: calc(26px + (42 - 26) * ((100vw - 600px) / (1920 - 600)));}
    .FS25 {font-size: calc(27px + (44 - 27) * ((100vw - 600px) / (1920 - 600)));}
    .FS24 {font-size: calc(28px + (46 - 28) * ((100vw - 600px) / (1920 - 600)));}
    .FS23 {font-size: calc(29px + (48 - 29) * ((100vw - 600px) / (1920 - 600)));}
    .FS22 {font-size: calc(30px + (50 - 30) * ((100vw - 600px) / (1920 - 600)));}
    .FS21 {font-size: calc(31px + (52 - 31) * ((100vw - 600px) / (1920 - 600)));}
    .FS20 {font-size: calc(32px + (54 - 32) * ((100vw - 600px) / (1920 - 600)));}
    .FS19 {font-size: calc(33px + (56 - 33) * ((100vw - 600px) / (1920 - 600)));}
    .FS18 {font-size: calc(34px + (58 - 34) * ((100vw - 600px) / (1920 - 600)));}
    .FS17 {font-size: calc(35px + (60 - 35) * ((100vw - 600px) / (1920 - 600)));}
    .FS16 {font-size: calc(36px + (62 - 36) * ((100vw - 600px) / (1920 - 600)));}
    .FS15 {font-size: calc(37px + (64 - 37) * ((100vw - 600px) / (1920 - 600)));}
    .FS14 {font-size: calc(38px + (66 - 38) * ((100vw - 600px) / (1920 - 600)));}
    .FS13 {font-size: calc(39px + (68 - 39) * ((100vw - 600px) / (1920 - 600)));}
    .FS12 {font-size: calc(40px + (72 - 40) * ((100vw - 600px) / (1920 - 600)));}
    .FS11 {font-size: calc(41px + (74 - 41) * ((100vw - 600px) / (1920 - 600)));}
    .FS10 {font-size: calc(42px + (76 - 42) * ((100vw - 600px) / (1920 - 600)));}
    .FS9 {font-size: calc(43px + (78 - 43) * ((100vw - 600px) / (1920 - 600)));}
    .FS8 {font-size: calc(44px + (80 - 44) * ((100vw - 600px) / (1920 - 600)));}
    .FS7 {font-size: calc(45px + (82 - 45) * ((100vw - 600px) / (1920 - 600)));}
    .FS6 {font-size: calc(46px + (84 - 46) * ((100vw - 600px) / (1920 - 600)));}
    .FS5 {font-size: calc(47px + (86 - 47) * ((100vw - 600px) / (1920 - 600)));}
    .FS4 {font-size: calc(48px + (88 - 48) * ((100vw - 600px) / (1920 - 600)));}
    .FS3 {font-size: calc(49px + (90 - 49) * ((100vw - 600px) / (1920 - 600)));}
    .FS2 {font-size: calc(50px + (92 - 50) * ((100vw - 600px) / (1920 - 600)));}
    .FS1 {font-size: calc(51px + (94 - 51) * ((100vw - 600px) / (1920 - 600)));}
    .FS0 {font-size: calc(52px + (96 - 52) * ((100vw - 600px) / (1920 - 600)));}
    .FSM1 {font-size: calc(53px + (98 - 53) * ((100vw - 600px) / (1600 - 300)));}
}

.LH10 {line-height: 0.1;}
.LH15 {line-height: 0.15;}
.LH20 {line-height: 0.2;}
.LH25 {line-height: 0.25;}
.LH30 {line-height: 0.3;}
.LH35 {line-height: 0.35;}
.LH40 {line-height: 0.4;}
.LH45 {line-height: 0.45;}
.LH50 {line-height: 0.5;}
.LH55 {line-height: 0.55;}
.LH60 {line-height: 0.6;}
.LH65 {line-height: 0.65;}
.LH70 {line-height: 0.7;}
.LH75 {line-height: 0.75;}
.LH80 {line-height: 0.8;}
.LH85 {line-height: 0.85;}
.LH90 {line-height: 0.9;}
.LH95 {line-height: 0.95;}
.LH100 {line-height: 1;}
.LH110 {line-height: 1.1;}
.LH120 {line-height: 1.2;}
.LH130 {line-height: 1.3;}
.LH140 {line-height: 1.4;}
.LH150 {line-height: 1.5;}
.LH160 {line-height: 1.6;}
.LH170 {line-height: 1.7;}
.LH180 {line-height: 1.8;}
.LH190 {line-height: 1.9;}
.LH200 {line-height: 2.0;}


  @media screen and (min-width: 1921px) {

    .FS43 {font-size: calc(8px + (8 - 8) * (1000px / (1920 - 600)));}
    .FS42 {font-size: calc(9px + (10 - 9) * (1000px / (1920 - 600)));}
    .FS41 {font-size: calc(9px + (12 - 9) * (1000px / (1920 - 600)));}
    .FS40 {font-size: calc(10px + (14 - 10) * (1000px / (1920 - 600)));}
    .FS39 {font-size: calc(12px + (16 - 12) * (1000px / (1920 - 600))); }
    .FS38 {font-size: calc(14px + (18 - 14) * (1000px / (1920 - 600))); }
    .FS37 {font-size: calc(15px + (20 - 15) * (1000px / (1920 - 600)));}
    .FS36 {font-size: calc(16px + (22 - 16) * (1000px / (1920 - 600)));}
    .FS35 {font-size: calc(17px + (24 - 17) * (1000px / (1920 - 600)));}
    .FS34 {font-size: calc(18px + (26 - 18) * (1000px / (1920 - 600)));}
    .FS33 {font-size: calc(19px + (28 - 19) * (1000px / (1920 - 600)));}
    .FS32 {font-size: calc(20px + (30 - 20) * (1000px / (1920 - 600)));}
    .FS31 {font-size: calc(21px + (32 - 21) * (1000px / (1920 - 600)));}
    .FS30 {font-size: calc(22px + (34 - 22) * (1000px / (1920 - 600)));}
    .FS29 {font-size: calc(23px + (36 - 23) * (1000px / (1920 - 600)));}
    .FS28 {font-size: calc(24px + (38 - 24) * (1000px / (1920 - 600)));}
    .FS27 {font-size: calc(25px + (40 - 25) * (1000px / (1920 - 600)));}
    .FS26 {font-size: calc(26px + (42 - 26) * (1000px / (1920 - 600)));}
    .FS25 {font-size: calc(27px + (44 - 27) * (1000px / (1920 - 600)));}
    .FS24 {font-size: calc(28px + (46 - 28) * (1000px / (1920 - 600)));}
    .FS23 {font-size: calc(29px + (48 - 29) * (1000px / (1920 - 600)));}
    .FS22 {font-size: calc(30px + (50 - 30) * (1000px / (1920 - 600)));}
    .FS21 {font-size: calc(31px + (52 - 31) * (1000px / (1920 - 600)));}
    .FS20 {font-size: calc(32px + (54 - 32) * (1000px / (1920 - 600)));}
    .FS19 {font-size: calc(33px + (56 - 33) * (1000px / (1920 - 600)));}
    .FS18 {font-size: calc(34px + (58 - 34) * (1000px / (1920 - 600)));}
    .FS17 {font-size: calc(35px + (60 - 35) * (1000px / (1920 - 600)));}
    .FS16 {font-size: calc(36px + (62 - 36) * (1000px / (1920 - 600)));}
    .FS15 {font-size: calc(37px + (64 - 37) * (1000px / (1920 - 600)));}
    .FS14 {font-size: calc(38px + (66 - 38) * (1000px / (1920 - 600)));}
    .FS13 {font-size: calc(39px + (68 - 39) * (1000px / (1920 - 600)));}
    .FS12 {font-size: calc(40px + (72 - 40) * (1000px / (1920 - 600)));}
    .FS11 {font-size: calc(41px + (74 - 41) * (1000px / (1920 - 600)));}
    .FS10 {font-size: calc(42px + (76 - 42) * (1000px / (1920 - 600)));}
    .FS9 {font-size: calc(43px + (78 - 43) * (1000px / (1920 - 600)));}
    .FS8 {font-size: calc(44px + (80 - 44) * (1000px / (1920 - 600)));}
    .FS7 {font-size: calc(45px + (82 - 45) * (1000px / (1920 - 600)));}
    .FS6 {font-size: calc(46px + (84 - 46) * (1000px / (1920 - 600)));}
    .FS5 {font-size: calc(47px + (86 - 47) * (1000px / (1920 - 600)));}
    .FS4 {font-size: calc(48px + (88 - 48) * (1000px / (1920 - 600)));}
    .FS3 {font-size: calc(49px + (90 - 49) * (1000px / (1920 - 600)));}
    .FS2 {font-size: calc(50px + (92 - 50) * (1000px / (1920 - 600)));}
    .FS1 {font-size: calc(51px + (94 - 51) * (1000px / (1920 - 600)));}
    .FS0 {font-size: calc(52px + (96 - 52) * (1000px / (1920 - 600)));}
    .FSM1 {font-size: calc(53px + (98 - 53) * (1000px / (1920 - 600)));}

   
  }
