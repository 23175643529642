/************************ Width ****************************/
	.WideInherit{width:inherit;}
	.WideAuto{width:auto;}
	.Wide0VW{width:0vw;}
	.Wide1VW{width:1vw;}
	.Wide2VW{width:2vw;}
	.Wide3VW{width:3vw;}
	.Wide4VW{width:4vw;}
	.Wide5VW{width:5vw;}
	.Wide6VW{width:6vw;}
	.Wide7VW{width:7vw;}
	.Wide7P5VW{width:7.5vw;}
	.Wide8VW{width:8vw;}
	.Wide10VW{width:10vw;}
	.Wide12VW{width:12vw;}
	.Wide13VW{width:13vw;}
	.Wide142VW{width:14.285vw;}
	.Wide14VW{width:14vw;}
	.Wide15VW{width:15vw;}
	.Wide166VW{width:16.66vw;}
	.Wide18VW{width:18vw;}
	.Wide19VW{width:19vw;}
	.Wide20VW{width:20vw;}
	.Wide21VW{width:21vw;}
	.Wide22VW{width:22vw;}
	.Wide23VW{width:23vw;}
	.Wide24VW{width:24vw;}
	.Wide25VW{width:25vw;}
	.Wide26VW{width:26vw;}
	.Wide27VW{width:27vw;}
	.Wide28VW{width:28vw;}
	.Wide29VW{width:29vw;}
	.Wide30VW{width:30vw;}
	.Wide31VW{width:31vw;}
	.Wide32VW{width:32vw;}
	.Wide33VW{width:33vw;}
	.Wide34VW{width:34vw;}
	.Wide35VW{width:35vw;}
	.Wide40VW{width:40vw;}
	.Wide41VW{width:41vw;}
	.Wide42VW{width:42vw;}
	.Wide43VW{width:43vw;}
	.Wide44VW{width:44vw;}
	.Wide45VW{width:45vw;}
	.Wide46VW{width:46vw;}
	.Wide47VW{width:47vw;}
	.Wide48VW{width:48vw;}
	.Wide49VW{width:49vw;}
	.Wide50VW{width:50vw;}
	.Wide55VW{width:55vw;}
	.Wide60VW{width:60vw;}
	.Wide61VW{width:61vw;}
	.Wide62VW{width:62vw;}
	.Wide63VW{width:63vw;}
	.Wide64VW{width:64vw;}
	.Wide65VW{width:65vw;}
	.Wide66VW{width:66vw;}
	.Wide67VW{width:67vw;}
	.Wide68VW{width:68vw;}
	.Wide69VW{width:69vw;}
	.Wide70VW{width:70vw;}
	.Wide71VW{width:71vw;}
	.Wide72VW{width:72vw;}
	.Wide73VW{width:73vw;}
	.Wide74VW{width:74vw;}
	.Wide75VW{width:75vw;}
	.Wide76VW{width:76vw;}
	.Wide77VW{width:77vw;}
	.Wide78VW{width:78vw;}
	.Wide79VW{width:79vw;}
	.Wide80VW{width:80vw;}
	.Wide81VW{width:81vw;}
	.Wide82VW{width:82vw;}
	.Wide83VW{width:83vw;}
	.Wide84VW{width:84vw;}
	.Wide85VW{width:85vw;}
	.Wide86VW{width:86vw;}
	.Wide87VW{width:87vw;}
	.Wide88VW{width:88vw;}
	.Wide89VW{width:89vw;}
	.Wide90VW{width:90vw;}
	.Wide91VW{width:91vw;}
	.Wide92VW{width:92vw;}
	.Wide93VW{width:93vw;}
	.Wide94VW{width:94vw;}
	.Wide95VW{width:95vw;}
	.Wide96VW{width:96vw;}
	.Wide97VW{width:97vw;}
	.Wide98VW{width:98vw;}
	.Wide99VW{width:99vw;}
	.Wide100VW{width:100vw;}
	.Wide110VW{width:110vw;}
	.Wide120VW{width:120vw;}
	.Wide130VW{width:130vw;}
	.Wide140VW{width:140vw;}
	.Wide150VW{width:150vw;}
	.Wide160VW{width:160vw;}
	.Wide170VW{width:170vw;}
	.Wide180VW{width:180vw;}
	.Wide190VW{width:190vw;}
	.Wide200VW{width:200vw;}
	.Wide300VW{width:300vw;}
	.Wide400VW{width:400vw;}
	.Wide500VW{width:500vw;}
	
	.Wide0{width:0%;}
	.Wide1{width:1%;}
	.Wide2{width:2%;}
	.Wide3{width:3%;}
	.Wide4{width:4%;}
	.Wide5{width:5%;}
	.Wide6{width:6%;}
	.Wide7{width:7%;}
	.Wide75{width:7.5%;}
	.Wide8{width:8%;}
	.Wide10{width:10%;}
	.Wide12{width:12%;}
	.Wide13{width:13%;}
	.Wide142{width:14.285%;}
	.Wide14{width:14%;}
	.Wide15{width:15%;}
	.Wide166{width:16.66%;}
	.Wide18{width:18%;}
	.Wide19{width:19%;}
	.Wide20{width:20%;}
	.Wide21{width:21%;}
	.Wide22{width:22%;}
	.Wide23{width:23%;}
	.Wide24{width:24%;}
	.Wide25{width:25%;}
	.Wide26{width:26%;}
	.Wide27{width:27%;}
	.Wide28{width:28%;}
	.Wide29{width:29%;}
	.Wide30{width:30%;}
	.Wide31{width:31%;}
	.Wide32{width:32%;}
	.Wide33{width:33%;}
	.Wide34{width:34%;}
	.Wide35{width:35%;}
	.Wide40{width:40%;}
	.Wide41{width:41%;}
	.Wide42{width:42%;}
	.Wide43{width:43%;}
	.Wide44{width:44%;}
	.Wide45{width:45%;}
	.Wide46{width:46%;}
	.Wide47{width:47%;}
	.Wide48{width:48%;}
	.Wide49{width:49%;}
	.Wide50{width:50%;}
	.Wide55{width:55%;}
	.Wide60{width:60%;}
	.Wide61{width:61%;}
	.Wide62{width:62%;}
	.Wide63{width:63%;}
	.Wide64{width:64%;}
	.Wide65{width:65%;}
	.Wide66{width:66%;}
	.Wide67{width:67%;}
	.Wide68{width:68%;}
	.Wide69{width:69%;}
	.Wide70{width:70%;}
	.Wide71{width:71%;}
	.Wide72{width:72%;}
	.Wide73{width:73%;}
	.Wide74{width:74%;}
	.Wide75{width:75%;}
	.Wide76{width:76%;}
	.Wide77{width:77%;}
	.Wide78{width:78%;}
	.Wide79{width:79%;}
	.Wide80{width:80%;}
	.Wide81{width:81%;}
	.Wide82{width:82%;}
	.Wide83{width:83%;}
	.Wide84{width:84%;}
	.Wide85{width:85%;}
	.Wide86{width:86%;}
	.Wide87{width:87%;}
	.Wide88{width:88%;}
	.Wide89{width:89%;}
	.Wide90{width:90%;}
	.Wide91{width:91%;}
	.Wide92{width:92%;}
	.Wide93{width:93%;}
	.Wide94{width:94%;}
	.Wide95{width:95%;}
	.Wide96{width:96%;}
	.Wide97{width:97%;}
	.Wide98{width:98%;}
	.Wide99{width:99%;}
	.Wide100{width:100%;}
	.Wide110{width:110%;}
	.Wide120{width:120%;}
	.Wide130{width:130%;}
	.Wide140{width:140%;}
	.Wide150{width:150%;}
	.Wide160{width:160%;}
	.Wide170{width:170%;}
	.Wide180{width:180%;}
	.Wide190{width:190%;}
	.Wide200{width:200%;}
	.Wide300{width:300%;}
	.Wide400{width:400%;}
	.Wide500{width:500%;}
	
	.Wide5px{width:5px;}	
	.Wide10px{width:10px;}	
	.Wide15px{width:15px;}	
	.Wide20px{width:20px;}	
	.Wide25px{width:25px;}	
	.Wide30px{width:30px;}	
	.Wide35px{width:35px;}	
	.Wide40px{width:40px;}
	.Wide45px{width:45px;}	
	.Wide50px{width:50px;}
	.Wide55px{width:55px;}	
	.Wide60px{width:60px;}	
	.Wide65px{width:65px;}	
	.Wide70px{width:70px;}	
	.Wide75px{width:75px;}
	.Wide80px{width:80px;}	
	.Wide85px{width:85px;}	
	.Wide90px{width:90px;}	
	.Wide95px{width:95px;}	
	.Wide100px{width:100px;}
	.Wide150px{width:150px;}
	.Wide200px{width:200px;}
	.Wide210px{width:210px;}
	.Wide220px{width:220px;}
	.Wide230px{width:230px;}
	.Wide240px{width:240px;}
	.Wide250px{width:250px;}
	.Wide260px{width:260px;}
	.Wide270px{width:270px;}
	.Wide280px{width:280px;}
	.Wide290px{width:290px;}
	.Wide300px{width:300px;}
	.Wide310px{width:310px;}
	.Wide320px{width:320px;}
	.Wide330px{width:330px;}
	.Wide340px{width:340px;}
	.Wide350px{width:350px;}
	.Wide400px{width:400px;}
	.Wide450px{width:450px;}
	.Wide500px{width:500px;}
	.Wide600px{width:600px;}
	.Wide700px{width:700px;}
	.Wide750px{width:750px;}
	.Wide800px{width:800px;}
	.Wide900px{width:900px;}
	.Wide1000px{width:1000px;}
	.Wide1100px{width:1100px;}
	.Wide1200px{width:1200px;}
	.Wide1300px{width:1300px;}
	.Wide1400px{width:1400px;}
	.Wide1500px{width:1500px;}
	.Wide1600px{width:1600px;}
	.Wide1700px{width:1700px;}
	.Wide1800px{width:1800px;}
	.Wide1900px{width:1900px;}
	.Wide2000px{width:2000px;}
	.Wide2500px{width:2500px;}
	.Wide3000px{width:3000px;}
	.Wide3500px{width:3500px;}
	.Wide4000px{width:4000px;}
	.Wide4500px{width:4500px;}
	.Wide5000px{width:5000px;}
	/************************ MAX WIDTH ****************************/
	.MaxWide10{max-width:10%;}
	.MaxWide18{max-width:18%;}
	.MaxWide19{max-width:19%;}
	.MaxWide20{max-width:20%;}
	.MaxWide21{max-width:21%;}
	.MaxWide22{max-width:22%;}
	.MaxWide23{max-width:23%;}
	.MaxWide24{max-width:24%;}
	.MaxWide25{max-width:25%;}
	.MaxWide30{max-width:30%;}
	.MaxWide40{max-width:40%;}
	.MaxWide50{max-width:50%;}	
	.MaxWide55{max-width:55%;}
	.MaxWide60{max-width:60%;}
	.MaxWide65{max-width:65%;}
	.MaxWide70{max-width:70%;}
	.MaxWide75{max-width:75%;}
	.MaxWide80{max-width:80%;}
	.MaxWide85{max-width:85%;}
	.MaxWide90{max-width:90%;}
	.MaxWide95{max-width:95%;}
	.MaxWide96{max-width:96%;}
	.MaxWide97{max-width:97%;}
	.MaxWide98{max-width:98%;}
	.MaxWide99{max-width:99%;}
	.MaxWide100{max-width:100%;}
	
	.MaxWide50px{max-width:50px;}
	.MaxWide60px{max-width:60px;}
	.MaxWide70px{max-width:70px;}
	.MaxWide80px{max-width:80px;}
	.MaxWide90px{max-width:90px;}
	.MaxWide100px{max-width:100px;}
	.MaxWide110px{max-width:110px;}
	.MaxWide120px{max-width:120px;}
	.MaxWide130px{max-width:130px;}
	.MaxWide140px{max-width:140px;}
	.MaxWide150px{max-width:150px;}
	.MaxWide160px{max-width:160px;}
	.MaxWide170px{max-width:170px;}
	.MaxWide180px{max-width:180px;}
	.MaxWide190px{max-width:190px;}
	.MaxWide200px{max-width:200px;}
	.MaxWide210px{max-width:210px;}
	.MaxWide220px{max-width:220px;}
	.MaxWide230px{max-width:230px;}
	.MaxWide240px{max-width:240px;}
	.MaxWide250px{max-width:250px;}
	.MaxWide260px{max-width:260px;}
	.MaxWide270px{max-width:270px;}
	.MaxWide280px{max-width:280px;}
	.MaxWide290px{max-width:290px;}
	.MaxWide300px{max-width:300px;}
	.MaxWide350px{max-width:350px;}
	.MaxWide400px{max-width:400px;}
	.MaxWide450px{max-width:450px;}
	.MaxWide500px{max-width:500px;}
	.MaxWide600px{max-width:600px;}
	.MaxWide700px{max-width:700px;}
	.MaxWide750px{max-width:750px;}
	.MaxWide800px{max-width:800px;}
	.MaxWide900px{max-width:900px;}
	.MaxWide1000px{max-width:1000px;}
	.MaxWide1100px{max-width:1100px;}
	.MaxWide1200px{max-width:1200px;}
	.MaxWide1250px{max-width:1250px;}
	.MaxWide1300px{max-width:1300px;}
	.MaxWide1400px{max-width:1400px;}
	.MaxWide1500px{max-width:1500px;}
	.MaxWide1600px{max-width:1600px;}
	.MaxWide1700px{max-width:1700px;}
	.MaxWide1750px{max-width:1750px;}
	.MaxWide1800px{max-width:1800px;}
	.MaxWide1900px{max-width:1900px;}
	.MaxWide2000px{max-width:2000px;}
/*********************** MIN WIDTH ****************************/
	.MinWide5{min-width:5%;}
	.MinWide10{min-width:10%;}
	.MinWide18{min-width:18%;}
	.MinWide19{min-width:19%;}
	.MinWide20{min-width:20%;}
	.MinWide21{min-width:21%;}
	.MinWide22{min-width:22%;}
	.MinWide23{min-width:23%;}
	.MinWide24{min-width:24%;}
	.MinWide25{min-width:25%;}
	.MinWide30{min-width:30%;}
	.MinWide40{min-width:40%;}
	.MinWide50{min-width:50%;}
	.MinWide60{min-width:60%;}
	.MinWide70{min-width:70%;}
	.MinWide75{min-width:75%;}
	.MinWide80{min-width:80%;}
	.MinWide90{min-width:90%;}
	.MinWide100{min-width:100%;}
	.MinWide10px{min-width:10px;}
	.MinWide20px{min-width:20px;}
	.MinWide30px{min-width:30px;}
	.MinWide40px{min-width:40px;}
	.MinWide50px{min-width:50px;}
	.MinWide60px{min-width:60px;}
	.MinWide70px{min-width:70px;}
	.MinWide75px{min-width:75px;}
	.MinWide80px{min-width:80px;}
	.MinWide90px{min-width:90px;}
	.MinWide100px{min-width:100px;}
	.MinWide110px{min-width:110px;}
	.MinWide120px{min-width:120px;}
	.MinWide130px{min-width:130px;}
	.MinWide140px{min-width:140px;}
	.MinWide150px{min-width:150px;}
	.MinWide160px{min-width:160px;}
	.MinWide170px{min-width:170px;}
	.MinWide180px{min-width:180px;}
	.MinWide190px{min-width:190px;}
	.MinWide200px{min-width:200px;}
	.MinWide250px{min-width:250px;}
	.MinWide300px{min-width:300px;}
	.MinWide350px{min-width:350px;}
	.MinWide400px{min-width:400px;}
	.MinWide450px{min-width:450px;}
	.MinWide500px{min-width:500px;}
	.MinWide550px{min-width:550px;}
	.MinWide600px{min-width:600px;}
	.MinWide650px{min-width:650px;}
	.MinWide700px{min-width:700px;}
	.MinWide750px{min-width:750px;}
	.MinWide800px{min-width:800px;}
	.MinWide850px{min-width:850px;}
	.MinWide900px{min-width:900px;}
	.MinWide902px{min-width:902px;}
	.MinWide950px{min-width:950px;}
	.MinWide1000px{min-width:1000px;}