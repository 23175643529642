
/***********************  MARGIN ****************************/
	.MarginAuto{margin:auto;}
	.TopMarginAuto{margin-top:auto;}
	.LeftMarginAuto{margin-left:auto;}
	.BottomMarginAuto{margin-bottom:auto;}
	.RightMarginAuto{margin-right:auto;}

	.Margin-20{margin:-20%;}
	.TopMargin-20{margin-top:-20%;}
	.LeftMargin-20{margin-left:-20%;}
	.RightMargin-20{margin-right:-20%;}
	.BottomMargin-20{margin-bottom:-20%;}

	.Margin-20px{margin:-20px;}
	.TopMargin-20px{margin-top:-20px;}
	.LeftMargin-20px{margin-left:-20px;}
	.RightMargin-20px{margin-right:-20px;}
	.BottomMargin-20px{margin-bottom:-20px;}

	.Margin-15{margin:-15%;}
	.TopMargin-15{margin-top:-15%;}
	.LeftMargin-15{margin-left:-15%;}
	.RightMargin-15{margin-right:-15%;}
	.BottomMargin-15{margin-bottom:-15%;}

	.Margin-15px{margin:-15px;}
	.TopMargin-15px{margin-top:-15px;}
	.LeftMargin-15px{margin-left:-15px;}
	.RightMargin-15px{margin-right:-15px;}
	.BottomMargin-15px{margin-bottom:-15px;}

	.Margin-10{margin:-10%;}
	.TopMargin-10{margin-top:-10%;}
	.LeftMargin-10{margin-left:-10%;}
	.RightMargin-10{margin-right:-10%;}
	.BottomMargin-10{margin-bottom:-10%;}

	.Margin-10px{margin:-10px;}
	.TopMargin-10px{margin-top:-10px;}
	.LeftMargin-10px{margin-left:-10px;}
	.RightMargin-10px{margin-right:-10px;}
	.BottomMargin-10px{margin-bottom:-10px;}

	.Margin-9{margin:-9%;}
	.TopMargin-9{margin-top:-9%;}
	.LeftMargin-9{margin-left:-9%;}
	.RightMargin-9{margin-right:-9%;}
	.BottomMargin-9{margin-bottom:-9%;}

	.Margin-9px{margin:-9px;}
	.TopMargin-9px{margin-top:-9px;}
	.LeftMargin-9px{margin-left:-9px;}
	.RightMargin-9px{margin-right:-9px;}
	.BottomMargin-9px{margin-bottom:-9px;}

	.Margin-8{margin:-8%;}
	.TopMargin-8{margin-top:-8%;}
	.LeftMargin-8{margin-left:-8%;}
	.RightMargin-8{margin-right:-8%;}
	.BottomMargin-8{margin-bottom:-8%;}

	.Margin-8px{margin:-8px;}
	.TopMargin-8px{margin-top:-8px;}
	.LeftMargin-8px{margin-left:-8px;}
	.RightMargin-8px{margin-right:-8px;}
	.BottomMargin-8px{margin-bottom:-8px;}

	.Margin-7{margin:-7%;}
	.TopMargin-7{margin-top:-7%;}
	.LeftMargin-7{margin-left:-7%;}
	.RightMargin-7{margin-right:-7%;}
	.BottomMargin-7{margin-bottom:-7%;}

	.Margin-7px{margin:-7px;}
	.TopMargin-7px{margin-top:-7px;}
	.LeftMargin-7px{margin-left:-7px;}
	.RightMargin-7px{margin-right:-7px;}
	.BottomMargin-7px{margin-bottom:-7px;}

	.Margin-6{margin:-6%;}
	.TopMargin-6{margin-top:-6%;}
	.LeftMargin-6{margin-left:-6%;}
	.RightMargin-6{margin-right:-6%;}
	.BottomMargin-6{margin-bottom:-6%;}

	.Margin-6px{margin:-6px;}
	.TopMargin-6px{margin-top:-6px;}
	.LeftMargin-6px{margin-left:-6px;}
	.RightMargin-6px{margin-right:-6px;}
	.BottomMargin-6px{margin-bottom:-6px;}

	.Margin-5{margin:-5%;}
	.TopMargin-5{margin-top:-5%;}
	.LeftMargin-5{margin-left:-5%;}
	.RightMargin-5{margin-right:-5%;}
	.BottomMargin-5{margin-bottom:-5%;}

	.Margin-5px{margin:-5px;}
	.TopMargin-5px{margin-top:-5px;}
	.LeftMargin-5px{margin-left:-5px;}
	.RightMargin-5px{margin-right:-5px;}
	.BottomMargin-5px{margin-bottom:-5px;}

	.Margin-4{margin:-4%;}
	.TopMargin-4{margin-top:-4%;}
	.LeftMargin-4{margin-left:-4%;}
	.RightMargin-4{margin-right:-4%;}
	.BottomMargin-4{margin-bottom:-4%;}

	.Margin-4px{margin:-4px;}
	.TopMargin-4px{margin-top:-4px;}
	.LeftMargin-4px{margin-left:-4px;}
	.RightMargin-4px{margin-right:-4px;}
	.BottomMargin-4px{margin-bottom:-4px;}

	.Margin-3{margin:-3%;}
	.TopMargin-3{margin-top:-3%;}
	.LeftMargin-3{margin-left:-3%;}
	.RightMargin-3{margin-right:-3%;}
	.BottomMargin-3{margin-bottom:-3%;}

	.Margin-3px{margin:-3px;}
	.TopMargin-3px{margin-top:-3px;}
	.LeftMargin-3px{margin-left:-3px;}
	.RightMargin-3px{margin-right:-3px;}
	.BottomMargin-3px{margin-bottom:-3px;}

	.Margin-2{margin:-2%;}
	.TopMargin-2{margin-top:-2%;}
	.LeftMargin-2{margin-left:-2%;}
	.RightMargin-2{margin-right:-2%;}
	.BottomMargin-2{margin-bottom:-2%;}

	.Margin-2px{margin:-2px;}
	.TopMargin-2px{margin-top:-2px;}
	.LeftMargin-2px{margin-left:-2px;}
	.RightMargin-2px{margin-right:-2px;}
	.BottomMargin-2px{margin-bottom:-2px;}

	.Margin-1{margin:-1%;}
	.TopMargin-1{margin-top:-1%;}
	.LeftMargin-1{margin-left:-1%;}
	.RightMargin-1{margin-right:-1%;}
	.BottomMargin-1{margin-bottom:-1%;}

	.Margin-1px{margin:-1px;}
	.TopMargin-1px{margin-top:-1px;}
	.LeftMargin-1px{margin-left:-1px;}
	.RightMargin-1px{margin-right:-1px;}
	.BottomMargin-1px{margin-bottom:-1px;}

	.Margin0{margin:0%;}
	.TopMargin0{margin-top:0%;}
	.LeftMargin0{margin-left:0%;}
	.RightMargin0{margin-right:0%;}
	.BottomMargin0{margin-bottom:0%;}

	.Margin0px{margin:0px;}
	.TopMargin0px{margin-top:0px;}
	.LeftMargin0px{margin-left:0px;}
	.RightMargin0px{margin-right:0px;}
	.BottomMargin0px{margin-bottom:0px;}

	.Margin1{margin:1%;}
	.TopMargin1{margin-top:1%;}
	.LeftMargin1{margin-left:1%;}
	.RightMargin1{margin-right:1%;}
	.BottomMargin1{margin-bottom:1%;}

	.Margin1px{margin:1px;}
	.TopMargin1px{margin-top:1px;}
	.LeftMargin1px{margin-left:1px;}
	.RightMargin1px{margin-right:1px;}
	.BottomMargin1px{margin-bottom:1px;}

	.Margin2{margin:2%;}
	.TopMargin2{margin-top:2%;}
	.LeftMargin2{margin-left:2%;}
	.RightMargin2{margin-right:2%;}
	.BottomMargin2{margin-bottom:2%;}

	.Margin2px{margin:2px;}
	.TopMargin2px{margin-top:2px;}
	.LeftMargin2px{margin-left:2px;}
	.RightMargin2px{margin-right:2px;}
	.BottomMargin2px{margin-bottom:2px;}

	.Margin3{margin:3%;}
	.TopMargin3{margin-top:3%;}
	.LeftMargin3{margin-left:3%;}
	.RightMargin3{margin-right:3%;}
	.BottomMargin3{margin-bottom:3%;}

	.Margin3px{margin:3px;}
	.TopMargin3px{margin-top:3px;}
	.LeftMargin3px{margin-left:3px;}
	.RightMargin3px{margin-right:3px;}
	.BottomMargin3px{margin-bottom:3px;}
	
	.Margin4{margin:4%;}
	.TopMargin4{margin-top:4%;}
	.LeftMargin4{margin-left:4%;}
	.RightMargin4{margin-right:4%;}
	.BottomMargin4{margin-bottom:4%;}

	.Margin4px{margin:4px;}
	.TopMargin4px{margin-top:4px;}
	.LeftMargin4px{margin-left:4px;}
	.RightMargin4px{margin-right:4px;}
	.BottomMargin4px{margin-bottom:4px;}
	
	.Margin5{margin:5%;}
	.TopMargin5{margin-top:5%;}
	.LeftMargin5{margin-left:5%;}
	.RightMargin5{margin-right:5%;}
	.BottomMargin5{margin-bottom:5%;}

	.Margin5px{margin:5px;}
	.TopMargin5px{margin-top:5px;}
	.LeftMargin5px{margin-left:5px;}
	.RightMargin5px{margin-right:5px;}
	.BottomMargin5px{margin-bottom:5px;}

	.Margin6{margin:6%;}
	.TopMargin6{margin-top:6%;}
	.LeftMargin6{margin-left:6%;}
	.RightMargin6{margin-right:6%;}
	.BottomMargin6{margin-bottom:6%;}

	.Margin6px{margin:6px;}
	.TopMargin6px{margin-top:6px;}
	.LeftMargin6px{margin-left:6px;}
	.RightMargin6px{margin-right:6px;}
	.BottomMargin6px{margin-bottom:6px;}
	
	.Margin7{margin:7%;}
	.TopMargin7{margin-top:7%;}
	.LeftMargin7{margin-left:7%;}
	.RightMargin7{margin-right:7%;}
	.BottomMargin7{margin-bottom:7%;}

	.Margin7px{margin:7px;}
	.TopMargin7px{margin-top:7px;}
	.LeftMargin7px{margin-left:7px;}
	.RightMargin7px{margin-right:7px;}
	.BottomMargin7px{margin-bottom:7px;}
	
	.Margin8{margin:8%;}
	.TopMargin8{margin-top:8%;}
	.LeftMargin8{margin-left:8%;}
	.RightMargin8{margin-right:8%;}
	.BottomMargin8{margin-bottom:8%;}

	.Margin8px{margin:8px;}
	.TopMargin8px{margin-top:8px;}
	.LeftMargin8px{margin-left:8px;}
	.RightMargin8px{margin-right:8px;}
	.BottomMargin8px{margin-bottom:8px;}
	
	.Margin9{margin:9%;}
	.TopMargin9{margin-top:9%;}
	.LeftMargin9{margin-left:9%;}
	.RightMargin9{margin-right:9%;}
	.BottomMargin9{margin-bottom:9%;}

	.Margin9px{margin:9px;}
	.TopMargin9px{margin-top:9px;}
	.LeftMargin9px{margin-left:9px;}
	.RightMargin9px{margin-right:9px;}
	.BottomMargin9px{margin-bottom:9px;}
	
	.Margin10{margin:10%;}
	.TopMargin10{margin-top:10%;}
	.LeftMargin10{margin-left:10%;}
	.RightMargin10{margin-right:10%;}
	.BottomMargin10{margin-bottom:10%;}

	.Margin125{margin:12.5%;}
	.TopMargin125{margin-top:12.5%;}
	.LeftMargin125{margin-left:12.5%;}
	.RightMargin125{margin-right:12.5%;}
	.BottomMargin125{margin-bottom:12.5%;}

	.Margin10px{margin:10px;}
	.TopMargin10px{margin-top:10px;}
	.LeftMargin10px{margin-left:10px;}
	.RightMargin10px{margin-right:10px;}
	.BottomMargin10px{margin-bottom:10px;}

	.Margin11px{margin:11px;}
	.TopMargin11px{margin-top:11px;}
	.LeftMargin11px{margin-left:11px;}
	.RightMargin11px{margin-right:11px;}
	.BottomMargin11px{margin-bottom:11px;}

	.Margin12px{margin:12px;}
	.TopMargin12px{margin-top:12px;}
	.LeftMargin12px{margin-left:12px;}
	.RightMargin12px{margin-right:12px;}
	.BottomMargin12px{margin-bottom:12px;}

	.Margin13px{margin:13px;}
	.TopMargin13px{margin-top:13px;}
	.LeftMargin13px{margin-left:13px;}
	.RightMargin13px{margin-right:13px;}
	.BottomMargin13px{margin-bottom:13px;}

	.Margin14px{margin:14px;}
	.TopMargin14px{margin-top:14px;}
	.LeftMargin14px{margin-left:14px;}
	.RightMargin14px{margin-right:14px;}
	.BottomMargin14px{margin-bottom:14px;}

	.Margin15{margin:15%;}
	.TopMargin15{margin-top:15%;}
	.LeftMargin15{margin-left:15%;}
	.RightMargin15{margin-right:15%;}
	.BottomMargin15{margin-bottom:15%;}

	.Margin15px{margin:15px;}
	.TopMargin15px{margin-top:15px;}
	.LeftMargin15px{margin-left:15px;}
	.RightMargin15px{margin-right:15px;}
	.BottomMargin15px{margin-bottom:15px;}

	.Margin16px{margin:16px;}
	.TopMargin16px{margin-top:16px;}
	.LeftMargin16px{margin-left:16px;}
	.RightMargin16px{margin-right:16px;}
	.BottomMargin16px{margin-bottom:16px;}

	.Margin17px{margin:17px;}
	.TopMargin17px{margin-top:17px;}
	.LeftMargin17px{margin-left:17px;}
	.RightMargin17px{margin-right:17px;}
	.BottomMargin17px{margin-bottom:17px;}

	.Margin18px{margin:18px;}
	.TopMargin18px{margin-top:18px;}
	.LeftMargin18px{margin-left:18px;}
	.RightMargin18px{margin-right:18px;}
	.BottomMargin18px{margin-bottom:18px;}

	.Margin19px{margin:19px;}
	.TopMargin19px{margin-top:19px;}
	.LeftMargin19px{margin-left:19px;}
	.RightMargin19px{margin-right:19px;}
	.BottomMargin19px{margin-bottom:19px;}

	.Margin20{margin:20%;}
	.TopMargin20{margin-top:20%;}
	.LeftMargin20{margin-left:20%;}
	.RightMargin20{margin-right:20%;}
	.BottomMargin20{margin-bottom:20%;}

	.Margin20px{margin:20px;}
	.TopMargin20px{margin-top:20px;}
	.LeftMargin20px{margin-left:20px;}
	.RightMargin20px{margin-right:20px;}
	.BottomMargin20px{margin-bottom:20px;}

	.Margin25{margin:25%;}
	.TopMargin25{margin-top:25%;}
	.LeftMargin25{margin-left:25%;}
	.RightMargin25{margin-right:25%;}
	.BottomMargin25{margin-bottom:25%;}

	.Margin25px{margin:25px;}
	.TopMargin25px{margin-top:25px;}
	.LeftMargin25px{margin-left:25px;}
	.RightMargin25px{margin-right:25px;}
	.BottomMargin25px{margin-bottom:25px;}

	.Margin30{margin:30%;}
	.TopMargin30{margin-top:30%;}
	.LeftMargin30{margin-left:30%;}
	.RightMargin30{margin-right:30%;}
	.BottomMargin30{margin-bottom:30%;}

	.Margin30px{margin:30px;}
	.TopMargin30px{margin-top:30px;}
	.LeftMargin30px{margin-left:30px;}
	.RightMargin30px{margin-right:30px;}
	.BottomMargin30px{margin-bottom:30px;}

	.Margin35{margin:35%;}
	.TopMargin35{margin-top:35%;}
	.LeftMargin35{margin-left:35%;}
	.RightMargin35{margin-right:35%;}
	.BottomMargin35{margin-bottom:35%;}

	.Margin35px{margin:35px;}
	.TopMargin35px{margin-top:35px;}
	.LeftMargin35px{margin-left:35px;}
	.RightMargin35px{margin-right:35px;}
	.BottomMargin35px{margin-bottom:35px;}

	.Margin40{margin:40%;}
	.TopMargin40{margin-top:40%;}
	.LeftMargin40{margin-left:40%;}
	.RightMargin40{margin-right:40%;}
	.BottomMargin40{margin-bottom:40%;}

	.Margin40px{margin:40px;}
	.TopMargin40px{margin-top:40px;}
	.LeftMargin40px{margin-left:40px;}
	.RightMargin40px{margin-right:40px;}
	.BottomMargin40px{margin-bottom:40px;}

	.Margin45{margin:45%;}
	.TopMargin45{margin-top:45%;}
	.LeftMargin45{margin-left:45%;}
	.RightMargin45{margin-right:45%;}
	.BottomMargin45{margin-bottom:45%;}

	.Margin45px{margin:45px;}
	.TopMargin45px{margin-top:45px;}
	.LeftMargin45px{margin-left:45px;}
	.RightMargin45px{margin-right:45px;}
	.BottomMargin45px{margin-bottom:45px;}

	.Margin50{margin:50%;}
	.TopMargin50{margin-top:50%;}
	.LeftMargin50{margin-left:50%;}
	.RightMargin50{margin-right:50%;}
	.BottomMargin50{margin-bottom:50%;}

	.Margin50px{margin:50px;}
	.TopMargin50px{margin-top:50px;}
	.LeftMargin50px{margin-left:50px;}
	.RightMargin50px{margin-right:50px;}
	.BottomMargin50px{margin-bottom:50px;}

	.Margin60{margin:60%;}
	.TopMargin60{margin-top:60%;}
	.LeftMargin60{margin-left:60%;}
	.RightMargin60{margin-right:60%;}
	.BottomMargin60{margin-bottom:60%;}

	.Margin60px{margin:60px;}
	.TopMargin60px{margin-top:60px;}
	.LeftMargin60px{margin-left:60px;}
	.RightMargin60px{margin-right:60px;}
	.BottomMargin60px{margin-bottom:60px;}
	
	.Margin70{margin:70%;}
	.TopMargin70{margin-top:70%;}
	.LeftMargin70{margin-left:70%;}
	.RightMargin70{margin-right:70%;}
	.BottomMargin70{margin-bottom:70%;}

	.Margin70px{margin:70px;}
	.TopMargin70px{margin-top:70px;}
	.LeftMargin70px{margin-left:70px;}
	.RightMargin70px{margin-right:70px;}
	.BottomMargin70px{margin-bottom:70px;}
	
	.Margin80{margin:80%;}
	.TopMargin80{margin-top:80%;}
	.LeftMargin80{margin-left:80%;}
	.RightMargin80{margin-right:80%;}
	.BottomMargin80{margin-bottom:80%;}

	.Margin80px{margin:80px;}
	.TopMargin80px{margin-top:80px;}
	.LeftMargin80px{margin-left:80px;}
	.RightMargin80px{margin-right:80px;}
	.BottomMargin80px{margin-bottom:80px;}
	
	.Margin90{margin:90%;}
	.TopMargin90{margin-top:90%;}
	.LeftMargin90{margin-left:90%;}
	.RightMargin90{margin-right:90%;}
	.BottomMargin90{margin-bottom:90%;}

	.Margin90px{margin:90px;}
	.TopMargin90px{margin-top:90px;}
	.LeftMargin90px{margin-left:90px;}
	.RightMargin90px{margin-right:90px;}
	.BottomMargin90px{margin-bottom:90px;}
	
	.Margin100{margin:100%;}
	.TopMargin100{margin-top:100%;}
	.LeftMargin100{margin-left:100%;}
	.RightMargin100{margin-right:100%;}
	.BottomMargin100{margin-bottom:100%;}

	.Margin100px{margin:100px;}
	.TopMargin100px{margin-top:100px;}
	.LeftMargin100px{margin-left:100px;}
	.RightMargin100px{margin-right:100px;}
	.BottomMargin100px{margin-bottom:100px;}

	.Margin110px{margin:110px;}
	.TopMargin110px{margin-top:110px;}
	.LeftMargin110px{margin-left:110px;}
	.RightMargin110px{margin-right:110px;}
	.BottomMargin110px{margin-bottom:110px;}

	.Margin120px{margin:120px;}
	.TopMargin120px{margin-top:120px;}
	.LeftMargin120px{margin-left:120px;}
	.RightMargin120px{margin-right:120px;}
	.BottomMargin120px{margin-bottom:120px;}

	.Margin130px{margin:130px;}
	.TopMargin130px{margin-top:130px;}
	.LeftMargin130px{margin-left:130px;}
	.RightMargin130px{margin-right:130px;}
	.BottomMargin130px{margin-bottom:130px;}

	.Margin140px{margin:140px;}
	.TopMargin140px{margin-top:140px;}
	.LeftMargin140px{margin-left:140px;}
	.RightMargin140px{margin-right:140px;}
	.BottomMargin140px{margin-bottom:140px;}

	.Margin150px{margin:150px;}
	.TopMargin150px{margin-top:150px;}
	.LeftMargin150px{margin-left:150px;}
	.RightMargin150px{margin-right:150px;}
	.BottomMargin150px{margin-bottom:150px;}

	.Margin200px{margin:200px;}
	.TopMargin200px{margin-top:200px;}
	.LeftMargin200px{margin-left:200px;}
	.RightMargin200px{margin-right:200px;}
	.BottomMargin200px{margin-bottom:200px;}


	

@media screen and (max-width: 719px) {

	.margin5M0{margin: 0vw;}
	.marginT5M0{margin-top: 0vw;}
	.marginR5M0{margin-right: 0vw;}
	.marginB5M0{margin-bottom: 0vw;}
	.marginL5M0{margin-left: 0vw;}
	.marginH5M0{margin-right: 0vw;margin-left: 0vw;}
	.marginV5M0{margin-top: 0vw;margin-bottom: 0vw;}


	.margin10M0{margin: 0vw;}
	.marginT10M0{margin-top: 0vw;}
	.marginR10M0{margin-right: 0vw;}
	.marginB10M0{margin-bottom: 0vw;}
	.marginL10M0{margin-left: 0vw;}
	.marginH10M0{margin-right: 0vw;margin-left: 0vw;}
	.marginV10M0{margin-top: 0vw;margin-bottom: 0vw;}

	.margin10M5{margin: 5vw;}
	.marginT10M5{margin-top: 5vw;}
	.marginR10M5{margin-right: 5vw;}
	.marginB10M5{margin-bottom: 5vw;}
	.marginL10M5{margin-left: 5vw;}
	.marginH10M5{margin-right: 5vw;margin-left: 5vw;}
	.marginV10M5{margin-top: 5vw;margin-bottom: 5vw;}
	

	.margin15M0{margin: 0vw;}
	.marginT15M0{margin-top: 0vw;}
	.marginR15M0{margin-right: 0vw;}
	.marginB15M0{margin-bottom: 0vw;}
	.marginL15M0{margin-left: 0vw;}
	.marginH15M0{margin-right: 0vw;margin-left: 0vw;}
	.marginV15M0{margin-top: 0vw;margin-bottom: 0vw;}

	.margin15M5{margin: 5vw;}
	.marginT15M5{margin-top: 5vw;}
	.marginR15M5{margin-right: 5vw;}
	.marginB15M5{margin-bottom: 5vw;}
	.marginL15M5{margin-left: 5vw;}
	.marginH15M5{margin-right: 5vw;margin-left: 5vw;}
	.marginV15M5{margin-top: 5vw;margin-bottom: 5vw;}

	.margin15M10{margin: 10vw;}
	.marginT15M10{margin-top: 10vw;}
	.marginR15M10{margin-right: 10vw;}
	.marginB15M10{margin-bottom: 10vw;}
	.marginL15M10{margin-left: 10vw;}
	.marginH15M10{margin-right: 10vw;margin-left: 10vw;}
	.marginV15M10{margin-top: 10vw;margin-bottom: 10vw;}


	.margin20M0{margin: 0vw;}
	.marginT20M0{margin-top: 0vw;}
	.marginR20M0{margin-right: 0vw;}
	.marginB20M0{margin-bottom: 0vw;}
	.marginL20M0{margin-left: 0vw;}
	.marginH20M0{margin-right: 0vw;margin-left: 0vw;}
	.marginV20M0{margin-top: 0vw;margin-bottom: 0vw;}

	.margin20M5{margin: 5vw;}
	.marginT20M5{margin-top: 5vw;}
	.marginR20M5{margin-right: 5vw;}
	.marginB20M5{margin-bottom: 5vw;}
	.marginL20M5{margin-left: 5vw;}
	.marginH20M5{margin-right: 5vw;margin-left: 5vw;}
	.marginV20M5{margin-top: 5vw;margin-bottom: 5vw;}

	.margin20M10{margin: 10vw;}
	.marginT20M10{margin-top: 10vw;}
	.marginR20M10{margin-right: 10vw;}
	.marginB20M10{margin-bottom: 10vw;}
	.marginL20M10{margin-left: 10vw;}
	.marginH20M10{margin-right: 10vw;margin-left: 10vw;}
	.marginV20M10{margin-top: 10vw;margin-bottom: 10vw;}
	
	.margin20M15{margin: 15vw;}
	.marginT20M15{margin-top: 15vw;}
	.marginR20M15{margin-right: 15vw;}
	.marginB20M15{margin-bottom: 15vw;}
	.marginL20M15{margin-left: 15vw;}
	.marginH20M15{margin-right: 15vw;margin-left: 15vw;}
	.marginV20M15{margin-top: 15vw;margin-bottom: 15vw;}
}


@media screen and (min-width: 720px) {
	.margin5M0{margin: 5vw;}
	.marginT5M0{margin-top: 5vw;}
	.marginR5M0{margin-right: 5vw;}
	.marginB5M0{margin-bottom: 5vw;}
	.marginL5M0{margin-left: 5vw;}
	.marginH5M0{margin-right: 5vw;margin-left: 5vw;}
	.marginV5M0{margin-top: 5vw;margin-bottom: 5vw;}


	.margin10M0{margin: 10vw;}
	.marginT10M0{margin-top: 10vw;}
	.marginR10M0{margin-right: 10vw;}
	.marginB10M0{margin-bottom: 10vw;}
	.marginL10M0{margin-left: 10vw;}
	.marginH10M0{margin-right: 10vw;margin-left: 10vw;}
	.marginV10M0{margin-top: 10vw;margin-bottom: 10vw;}

	.margin10M5{margin: 10vw;}
	.marginT10M5{margin-top: 10vw;}
	.marginR10M5{margin-right: 10vw;}
	.marginB10M5{margin-bottom: 10vw;}
	.marginL10M5{margin-left: 10vw;}
	.marginH10M5{margin-right: 10vw;margin-left: 10vw;}
	.marginV10M5{margin-top: 10vw;margin-bottom: 10vw;}
	

	.margin15M0{margin: 15vw;}
	.marginT15M0{margin-top: 15vw;}
	.marginR15M0{margin-right: 15vw;}
	.marginB15M0{margin-bottom: 15vw;}
	.marginL15M0{margin-left: 15vw;}
	.marginH15M0{margin-right: 15vw;margin-left: 15vw;}
	.marginV15M0{margin-top: 15vw;margin-bottom: 15vw;}

	.margin15M5{margin: 15vw;}
	.marginT15M5{margin-top: 15vw;}
	.marginR15M5{margin-right: 15vw;}
	.marginB15M5{margin-bottom: 15vw;}
	.marginL15M5{margin-left: 15vw;}
	.marginH15M5{margin-right: 15vw;margin-left: 15vw;}
	.marginV15M5{margin-top: 15vw;margin-bottom: 15vw;}
	
	.margin15M10{margin: 15vw;}
	.marginT15M10{margin-top: 15vw;}
	.marginR15M10{margin-right: 15vw;}
	.marginB15M10{margin-bottom: 15vw;}
	.marginL15M10{margin-left: 15vw;}
	.marginH15M10{margin-right: 15vw;margin-left: 15vw;}
	.marginV15M10{margin-top: 15vw;margin-bottom: 15vw;}


	.margin20M0{margin: 20vw;}
	.marginT20M0{margin-top: 20vw;}
	.marginR20M0{margin-right: 20vw;}
	.marginB20M0{margin-bottom: 20vw;}
	.marginL20M0{margin-left: 20vw;}
	.marginH20M0{margin-right: 20vw;margin-left: 20vw;}
	.marginV20M0{margin-top: 20vw;margin-bottom: 20vw;}

	.margin20M5{margin: 20vw;}
	.marginT20M5{margin-top: 20vw;}
	.marginR20M5{margin-right: 20vw;}
	.marginB20M5{margin-bottom: 20vw;}
	.marginL20M5{margin-left: 20vw;}
	.marginH20M5{margin-right: 20vw;margin-left: 20vw;}
	.marginV20M5{margin-top: 20vw;margin-bottom: 20vw;}
	
	.margin20M10{margin: 20vw;}
	.marginT20M10{margin-top: 20vw;}
	.marginR20M10{margin-right: 20vw;}
	.marginB20M10{margin-bottom: 20vw;}
	.marginL20M10{margin-left: 20vw;}
	.marginH20M10{margin-right: 20vw;margin-left: 20vw;}
	.marginV20M10{margin-top: 20vw;margin-bottom: 20vw;}

	.margin20M15{margin: 20vw;}
	.marginT20M15{margin-top: 20vw;}
	.marginR20M15{margin-right: 20vw;}
	.marginB20M15{margin-bottom: 20vw;}
	.marginL20M15{margin-left: 20vw;}
	.marginH20M15{margin-right: 20vw;margin-left: 20vw;}
	.marginV20M15{margin-top: 20vw;margin-bottom: 20vw;}
}
