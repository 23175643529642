
/****************************** ZINDEX ***************************/
	
	.zm20{z-index:-20;}
	.zm10{z-index:-10;}
	.zm1{z-index:-1;}
	.z0{z-index:0;}
	.z1{z-index:1;}
	.z10{z-index:10;}
	.z20{z-index:20;}
	.z30{z-index:30;}
	.z40{z-index:40;}
	.z50{z-index:50;}
	.z60{z-index:60;}
	.z70{z-index:70;}
	.z80{z-index:80;}
	.z90{z-index:90;}
	.z100{z-index:100;}
	.z200{z-index:200;}
	.z300{z-index:300;}
	.z400{z-index:400;}
	.z500{z-index:500;}	
	.z600{z-index:600;}
	.z700{z-index:700;}
	.z800{z-index:800;}
	.z900{z-index:900;}
	.z1000{z-index:1000;}
	.z2000{z-index:2000;}
	.z3000{z-index:3000;}
	.z4000{z-index:4000;}
	.z5000{z-index:5000;}
	.z6000{z-index:6000;}
	.z7000{z-index:7000;}
	.z8000{z-index:8000;}