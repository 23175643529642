/***********************  TOP  *****************************/
	.Top0{top:0%;}	
	.Top1{top:1%;}	
	.Top2{top:2%;}	
	.Top5{top:5%;}
	.Top10{top:10%;}
	.Top15{top:15%;}
	.Top20{top:20%;}
	.Top25{top:25%;}
	.Top30{top:30%;}
	.Top35{top:35%;}
	.Top40{top:40%;}
	.Top45{top:45%;}
	.Top50{top:50%;}
	.Top55{top:55%;}	
	.Top60{top:60%;}
	.Top70{top:70%;}
	.Top80{top:80%;}
	.Top90{top:90%;}
	.Top100{top:100%;}
	
	.TopM10{top:-10%;}
	.TopM15{top:-15%;}
	.TopM20{top:-20%;}
	.TopM25{top:-25%;}
	.TopM30{top:-30%;}
	.TopM35{top:-35%;}
	.TopM40{top:-40%;}
	.TopM45{top:-45%;}
	.TopM50{top:-50%;}
	.TopM55{top:-55%;}	
	.TopM60{top:-60%;}
	.TopM70{top:-70%;}
	.TopM80{top:-80%;}
	.TopM90{top:-90%;}
	
	
	.TopM200px{top:-200px;}
	.TopM175px{top:-175px;}
	.TopM150px{top:-150px;}
	.TopM125px{top:-125px;}
	.TopM100px{top:-100px;}
	.TopM90px{top:-90px;}
	.TopM80px{top:-80px;}
	.TopM70px{top:-70px;}
	.TopM60px{top:-60px;}
	.TopM50px{Top:-50px;}
	.TopM40px{Top:-40px;}
	.TopM30px{Top:-30px;}
	.TopM20px{Top:-20px;}
	.TopM10px{Top:-10px;}
	.Top0px{top:0px;}	
	.Top1px{top:1px;}	
	.Top2px{top:2px;}	
	.Top3px{top:3px;}	
	.Top4px{top:4px;}	
	.Top5px{top:5px;}
	.Top6px{top:6px;}
	.Top7px{top:7px;}
	.Top8px{top:8px;}
	.Top9px{top:9px;}
	.Top10px{top:10px;}
	.Top11px{top:11px;}
	.Top12px{top:12px;}
	.Top13px{top:13px;}
	.Top14px{top:14px;}
	.Top15px{top:15px;}
	.Top16px{top:16px;}
	.Top17px{top:17px;}
	.Top18px{top:18px;}
	.Top19px{top:19px;}
	.Top20px{top:20px;}
	.Top25px{top:25px;}
	.Top30px{top:30px;}
	.Top35px{top:35px;}
	.Top40px{top:40px;}
	.Top45px{top:45px;}
	.Top50px{top:50px;}
	.Top55px{top:55px;}
	.Top60px{Top:60px;}
	.Top65px{top:65px;}
	.Top70px{Top:70px;}
	.Top75px{top:75px;}
	.Top80px{Top:80px;}
	.Top85px{top:85px;}
	.Top90px{Top:90px;}
	.Top95px{top:95px;}
	.Top100px{Top:100px;}
	.Top110px{Top:110px;}
	.Top120px{Top:120px;}
	.Top130px{Top:130px;}
	.Top140px{Top:140px;}
	.Top150px{Top:150px;}
	.Top200px{Top:200px;}
	.Top250px{Top:250px;}
	.Top300px{Top:300px;}
	.Top400px{Top:400px;}
	.Top500px{Top:500px;}
	.Top600px{Top:600px;}
	.Top700px{Top:700px;}
	.Top800px{Top:800px;}
	.Top900px{Top:900px;}
	.Top1000px{Top:1000px;}
	
	.BottomM600px{bottom:-600px;}
	.BottomM500px{bottom:-500px;}
	.BottomM400px{bottom:-400px;}
	.BottomM300px{bottom:-300px;}
	.BottomM200px{bottom:-200px;}
	.BottomM100px{bottom:-100px;}
	.BottomM90px{bottom:-90px;}
	.BottomM80px{bottom:-80px;}
	.BottomM70px{bottom:-70px;}
	.BottomM60px{bottom:-60px;}
	.BottomM50px{Bottom:-50px;}
	.BottomM40px{Bottom:-40px;}
	.BottomM30px{Bottom:-30px;}
	.BottomM20px{Bottom:-20px;}
	.BottomM10px{Bottom:-10px;}
	.Bottom0px{bottom:0px;}
	.Bottom1px{bottom:1px;}
	.Bottom2px{bottom:2px;}
	.Bottom3px{bottom:3px;}
	.Bottom4px{bottom:4px;}
	.Bottom5px{bottom:5px;}
	.Bottom10px{bottom:10px;}
	.Bottom15px{bottom:15px;}
	.Bottom20px{bottom:20px;}
	.Bottom25px{bottom:25px;}
	.Bottom30px{bottom:30px;}
	.Bottom35px{bottom:35px;}
	.Bottom40px{bottom:40px;}
	.Bottom45px{bottom:45px;}
	.Bottom50px{bottom:50px;}
	.Bottom55px{bottom:55px;}
	.Bottom60px{bottom:60px;}
	.Bottom65px{bottom:65px;}
	.Bottom70px{bottom:70px;}
	.Bottom75px{bottom:75px;}
	.Bottom80px{bottom:80px;}
	.Bottom85px{bottom:85px;}
	.Bottom90px{bottom:90px;}
	.Bottom95px{bottom:95px;}
	.Bottom100px{bottom:100px;}
	.Bottom110px{bottom:110px;}
	.Bottom120px{bottom:120px;}
	.Bottom130px{bottom:130px;}
	.Bottom140px{bottom:140px;}
	.Bottom150px{bottom:150px;}
	.Bottom160px{bottom:160px;}
	.Bottom170px{bottom:170px;}
	.Bottom180px{bottom:180px;}
	.Bottom190px{bottom:190px;}
	.Bottom200px{bottom:200px;}
	.Bottom210px{bottom:210px;}
	.Bottom220px{bottom:220px;}
	.Bottom230px{bottom:230px;}
	.Bottom240px{bottom:240px;}
	.Bottom250px{bottom:250px;}
	.Bottom260px{bottom:260px;}
	.Bottom270px{bottom:270px;}
	.Bottom280px{bottom:280px;}
	.Bottom290px{bottom:290px;}
	.Bottom300px{bottom:300px;}
	.Bottom0{bottom:0%;}	
	.Bottom1{bottom:1%;}	
	.Bottom2{bottom:2%;}	
	.Bottom5{bottom:5%;}
	.Bottom10{bottom:10%;}
	.Bottom15{bottom:15%;}
	.Bottom20{bottom:20%;}
	.Bottom25{bottom:25%;}
	.Bottom30{bottom:30%;}
	.Bottom35{bottom:35%;}
	.Bottom40{bottom:40%;}
	.Bottom45{bottom:45%;}
	.Bottom50{bottom:50%;}
	.Bottom55{bottom:55%;}	
	.Bottom60{bottom:60%;}
	.Bottom70{bottom:70%;}
	.Bottom80{bottom:80%;}
	.Bottom90{bottom:90%;}
	.Bottom100{bottom:100%;}
/***********************  LEFT  *****************************/
	.Left0{left:0%;}	
	.Left1{left:1%;}	
	.Left2{left:2%;}	
	.Left5{left:5%;}
	.Left10{left:10%;}
	.Left15{left:15%;}
	.Left16{left:16%;}
	.Left17{left:17%;}
	.Left18{left:18%;}
	.Left19{left:19%;}
	.Left20{left:20%;}
	.Left25{left:25%;}
	.Left30{left:30%;}
	.Left35{left:35%;}
	.Left40{left:40%;}
	.Left45{left:45%;}
	.Left46{left:46%;}
	.Left47{left:47%;}
	.Left48{left:48%;}
	.Left49{left:49%;}
	.Left50{left:50%;}
	.Left51{left:51%;}
	.Left52{left:52%;}
	.Left53{left:53%;}
	.Left54{left:54%;}
	.Left55{left:55%;}	
	.Left60{left:60%;}	
	.Left70{left:70%;}
	.Left80{left:80%;}
	.Left90{left:90%;}
	.Left100{left:100%;}
	
	.LeftM30px{left:-30px;}
	.LeftM25px{left:-25px;}
	.LeftM20px{left:-20px;}
	.LeftM10px{left:-10px;}
	.LeftM5px{left:-5px;}
	.LeftM2px{left:-2px;}	
	.LeftM1px{left:-1px;}	
	.Left0px{left:0px;}	
	.Left1px{left:1px;}	
	.Left2px{left:2px;}	
	.Left5px{left:5px;}
	.Left10px{left:10px;}
	.Left20px{left:20px;}
	.Left25px{left:25px;}
	.Left30px{left:30px;}
	.Left35px{left:35px;}
	.Left40px{left:40px;}
	.Left45px{left:45px;}
	.Left50px{left:50px;}
	.Left60px{left:60px;}
	.Left70px{left:70px;}
	.Left80px{left:80px;}
	.Left90px{left:90px;}
	.Left100px{left:100px;}
	.Left110px{left:110px;}
	.Left120px{left:120px;}
	.Left1300px{left:130px;}
	.Left1400px{left:140px;}
	.Left150px{left:150px;}
	.Left200px{left:200px;}
	.Left300px{left:300px;}
	.Left400px{left:400px;}
	.Left500px{left:500px;}
	.Left600px{left:600px;}
	.Left700px{left:700px;}
	.Left800px{left:800px;}
	.Left900px{left:900px;}
	.Left1000px{left:1000px;}

	.Left-2px{left:-2px;}	
	.Left-5px{left:-5px;}
	.Left-10px{left:-10px;}
	.Left-20px{left:-20px;}
	.Left-25px{left:-25px;}
	.Left-30px{left:-30px;}
	.Left-35px{left:-35px;}
	.Left-40px{left:-40px;}
	.Left-45px{left:-45px;}
	.Left-50px{left:-50px;}
	.Left-100px{left:-100px;}
	.Left-200px{left:-200px;}
	.Left-300px{left:-300px;}
	.Left-400px{left:-400px;}
	.Left-500px{left:-500px;}
	.Left-600px{left:-600px;}
	.Left-700px{left:-700px;}
	.Left-800px{left:-800px;}
	.Left-900px{left:-900px;}
	.Left-1000px{left:-1000px;}
	


/***********************  RIGHT  *****************************/
	.Right0{right:0%;}	
	.Right2{right:2%;}	
	.Right5{right:5%;}
	.Right10{right:10%;}
	.Right20{right:20%;}
	.Right25{right:25%;}
	.Right30{right:30%;}
	.Right35{right:35%;}
	.Right40{right:40%;}
	.Right45{right:45%;}
	.Right50{right:50%;}
	.Right55{right:55%;}	
	.Right60{right:60%;}	
	.Right70{right:70%;}
	.Right80{right:80%;}
	.Right90{right:90%;}
	.Right100{right:100%;}

	.Right-2px{right:-2px;}	
	.Right-5px{right:-5px;}
	.Right-10px{right:-10px;}
	.Right-20px{right:-20px;}
	.Right-25px{right:-25px;}
	.Right-30px{right:-30px;}
	.Right-35px{right:-35px;}
	.Right-40px{right:-40px;}
	.Right-45px{right:-45px;}
	.Right-50px{right:-50px;}

	.Right0px{right:0px;}	
	.Right1px{right:1px;}	
	.Right2px{right:2px;}	
	.Right3px{right:3px;}	
	.Right4px{right:4px;}	
	.Right5px{right:5px;}
	.Right6px{right:6px;}
	.Right7px{right:7px;}
	.Right8px{right:8px;}
	.Right9px{right:9px;}
	.Right10px{right:10px;}
	.Right11px{right:11px;}
	.Right12px{right:12px;}
	.Right13px{right:13px;}
	.Right14px{right:14px;}
	.Right15px{right:15px;}
	.Right16px{right:16px;}
	.Right17px{right:17px;}
	.Right18px{right:18px;}
	.Right19px{right:19px;}
	.Right20px{right:20px;}
	.Right25px{right:25px;}
	.Right30px{right:30px;}
	.Right35px{right:35px;}
	.Right40px{right:40px;}
	.Right45px{right:45px;}
	.Right50px{right:50px;}
	.Right55px{right:55px;}
	.Right60px{right:60px;}
	.Right65px{right:65px;}
	.Right70px{right:70px;}
	.Right75px{right:75px;}
	.Right80px{right:80px;}
	.Right85px{right:85px;}
	.Right90px{right:90px;}
	.Right95px{right:95px;}
	.Right100px{right:100px;}
	.Right110px{right:110px;}
	.Right120px{right:120px;}
	.Right130px{right:130px;}
	.Right140px{right:140px;}
	.Right150px{right:150px;}
	.Right160px{right:160px;}
	.Right170px{right:170px;}
	.Right180px{right:180px;}
	.Right190px{right:190px;}
	.Right200px{right:200px;}
	.Right210px{right:210px;}
	.Right220px{right:220px;}
	.Right230px{right:230px;}
	.Right240px{right:240px;}
	.Right250px{right:250px;}
	.Right260px{right:260px;}
	.Right270px{right:270px;}
	.Right280px{right:280px;}
	.Right290px{right:290px;}
	.Right300px{right:300px;}

