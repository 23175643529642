
	/************************ Orientation ****************************/
	.RightSide{right:0;}
	.LeftSide{left:0;}	
	.RightFloat{float:right;}
	.LeftFloat{float:left;}
	.RightAlign{text-align:right;}
	.CenterAlign{text-align:center;}		
	.LeftAlign{text-align:left;}
	.BottomAlign{vertical-align: text-bottom;}
	.BottomAlign2{bottom: 0;}
	.VCenterAlign{vertical-align: middle;}
	.VTopAlign{vertical-align: top;}
	.VBottomAlign{vertical-align: bottom;}
	/************************ Display ****************************/
	.Static{position:static;}
	.Relative{position:relative;}
	.Absolute{position:absolute;}
	.Fixed{position:fixed;}
	.Middle{top:50%;}
	
	.OverflowAuto{overflow:auto;}
	.OverflowHidden{overflow:hidden;}
	.XScroll{overflow-x: scroll;white-space: nowrap;}
	.YScroll{overflow-y: scroll;white-space: nowrap;}
	.XScrollHidden{overflow-x: hidden;overflow-y: scroll;white-space: nowrap;}
	.YScrollHidden{overflow-y: Hidden;overflow-x: scroll;white-space: nowrap;}
	.ScrollHidden{overflow-y: Hidden;overflow-x: Hidden;white-space: nowrap;}
	.ScrollbarHidden::-webkit-scrollbar{overflow:auto;}
	.WhiteSpaceNormal{white-space: normal;}
	
	.Block{display:block;}
	.DisplayNone{display:none;}
	.Hidden{visibility:hidden;}
	.Visible{visibility:visible;}	
	.Invisible{opacity:0;}
	
	.Table{display:table;}
	.TableCell{display:table-cell;}
	
	.InlineBlock{display:inline-block;}
	.Inline{display:inline;}
	
	/************************ Custom ****************************/
	.BRL{display:block;position:relative;float: left;}
    .BRR{display:block;position:relative;float: right;}
    .BR{display:block;position:relative;}
    .LoginMenu{
	    padding-top: 20px;
	    padding-bottom: 20px;
	    padding-left: 30px;
	    padding-right: 30px;
	    background-color: white;
	    border: 0;
	    border-radius: 5px;
	    z-index: 1000;
    }
 	
 	.RemindMenu{
	    padding-top: 20px;
	    padding-bottom: 20px;
	    padding-left: 30px;
	    padding-right: 30px;
	    background-color: white;
	    border: 1px;
	    border-color: black;
	    border-radius: 5px;
	    z-index: 1000;
    }
 	
	.Flex{display:flex;}
	.Flex1{flex: 1;}
	.Flex2{flex: 2;}
	.Flex3{flex: 3;}
	.Flex4{flex: 4;}
	.Flex5{flex: 5;}
	.Flex6{flex: 6;}
	.Flex7{flex: 7;}
	.Flex8{flex: 8;}
	.Flex9{flex: 9;}
	.FlexCol{flex-direction:column;}
	.FlexRow{flex-direction:row;}
	.JustifyCenter{justify-content: center;}
	.JustifyStart{justify-content: start;}
	.JustifyEnd{justify-content: end;}
	.AlignCenter{align-items: center;}
	.AlignStart{align-items: flex-start;}
	.AlignEnd{align-items: flex-end;}

