/**************** BACKGROUND TRANSITION ******************/

	
.BottomTrans {
	-webkit-transition: bottom .3s ease-in;
	-moz-transition: bottom .3s ease-in;
	-o-transition: bottom .3s ease-in;
	-ms-transition: bottom .3s ease-in;
	transition: bottom .3s ease-in;
	}

	.TopTrans {
	-webkit-transition: top 0.5s ease-out;
	-moz-transition: top 0.5s ease-out;
	-o-transition: top 0.5s ease-out;
	-ms-transition: top 0.5s ease-out;
	transition: top 0.5s ease-out;
	}
	
	.BCTrans {
	-webkit-transition: background-color .3s ease-in;
	-moz-transition: background-color .3s ease-in;
	-o-transition: background-color .3s ease-in;
	-ms-transition: background-color .3s ease-in;
	transition: background-color .3s ease-in;
	}

	.HTrans {
	-webkit-transition: height .4s ease-in;
	-moz-transition: height .4s ease-in;
	-o-transition: height .4s ease-in;
	-ms-transition: height .4s ease-in;
	transition: height .4s ease-in;
	}

	.FSTrans{
	-webkit-transition: font-size .05s ease-in;
	-moz-transition: font-size .05s ease-in;
	-o-transition: font-size .05s ease-in;
	-ms-transition: font-size .05s ease-in;
	transition: font-size .05s ease-in;
	}

	.PosTrans {
	-webkit-transition: position 5.2s ease-in;
	-moz-transition: position 5.2s ease-in;
	-o-transition: position 5.2s ease-in;
	-ms-transition: position 5.2s ease-in;
	transition: position 5.2s ease-in;
	}

	.LeftTrans {
	-webkit-transition: left 1.1s cubic-bezier(0.23, 1, 0.32, 1);
	-moz-transition: left 1.1s cubic-bezier(0.23, 1, 0.32, 1);
	-o-transition: left 1.1s cubic-bezier(0.23, 1, 0.32, 1);
	-ms-transition: left 1.1s cubic-bezier(0.23, 1, 0.32, 1);
	transition: left 1.1s cubic-bezier(0.23, 1, 0.32, 1);
	}

	.RightTrans {
	-webkit-transition: right .3s ease-in;
	-moz-transition: right .3s ease-in;
	-o-transition: right .3s ease-in;
	-ms-transition: right .3s ease-in;
	transition: right .3s ease-in;
	}
	
	.WTrans {
	-webkit-transition: width .2s ease-in;
	-moz-transition: width .2s ease-in;
	-o-transition: width .2s ease-in;
	-ms-transition: width .2s ease-in;
	transition: width .2s ease-in;
	}

	.FCTrans {
	-webkit-transition: color 0.3s ease-in;
	-moz-transition: color 0.3s ease-in;
	-o-transition: color 0.3s ease-in;
	-ms-transition: color 0.3s ease-in;
	transition: color 0.3s ease-in;
	}

	.OpacityTrans {
	-webkit-transition: opacity 1s ease-in;
	-moz-transition: opacity 1s ease-in;
	-o-transition: opacity 1s ease-in;
	-ms-transition: opacity 1s ease-in;
	transition: opacity 1s ease-in;
	}
	
	.TextDecorationTrans {
	-webkit-transition: text-decoration 0.3s ease-in;
	-moz-transition: text-decoration 0.3s ease-in;
	-o-transition: text-decoration 0.3s ease-in;
	-ms-transition: text-decoration 0.3s ease-in;
	transition: text-decoration 0.3s ease-in;
	}
	
	.AllTrans {
	-webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	-ms-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
	}
	
	.AllTrans1 {
	-webkit-transition: all 0.4s ease-in;
	-moz-transition: all 0.4s ease-in;
	-o-transition: all 0.4s ease-in;
	-ms-transition: all 0.4s ease-in;
	transition: all 0.4s ease-in;
	}

	.AllTrans2 {
	-webkit-transition: all 0.6s ease-in;
	-moz-transition: all 0.6s ease-in;
	-o-transition: all 0.6s ease-in;
	-ms-transition: all 0.6s ease-in;
	transition: all 0.6s ease-in;
	}

	.AllTrans3 {
	-webkit-transition: all 1.0s ease-in;
	-moz-transition: all 1.0s ease-in;
	-o-transition: all 1.0s ease-in;
	-ms-transition: all 1.0s ease-in;
	transition: all 1.0s ease-in;
	}


	.BSTrans {
	-webkit-transition: box-shadow 0.3s ease-in;
	-moz-transition: box-shadow 0.3s ease-in;
	-o-transition: box-shadow 0.3s ease-in;
	-ms-transition: box-shadow 0.3s ease-in;
	transition: box-shadow 0.3s ease-in;
	}
		
	.TransformTrans {
	-webkit-transition: transform 0.3s ease-in;
	-moz-transition: transform 0.3s ease-in;
	-o-transition: transform 0.3s ease-in;
	-ms-transition: transform 0.3s ease-in;
	transition: transform 0.3s ease-in;
	}