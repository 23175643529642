@font-face {font-family: AdventorB; src: url('../fonts/AdventorB.otf');}
.AdventorB{font-family:AdventorB;}

@font-face {font-family: AdventorBI; src: url('../fonts/AdventorBI.otf');}
.AdventorBI{font-family:AdventorBI;}

@font-face {font-family: AdventorI; src: url('../fonts/AdventorI.otf');}
.AdventorI{font-family:AdventorI;}

@font-face {font-family: AdventorR; src: url('../fonts/AdventorR.otf');}
.AdventorR{font-family:AdventorR;}

@font-face {font-family: AGP; src: url('../fonts/AGP.ttf');}
.AGP{font-family:AGP;}

@font-face {font-family: AileronB; src: url('../fonts/AileronB.otf');}
.AileronB{font-family:AileronB;}

@font-face {font-family: AileronR; src: url('../fonts/AileronR.otf');}
.AileronR{font-family:AileronR;}

@font-face {font-family: AileronSB; src: url('../fonts/AileronSB.otf');}
.AileronSB{font-family:AileronSB;}

@font-face {font-family: AileronT; src: url('../fonts/AileronT.otf');}
.AileronT{font-family:AileronT;}

@font-face {font-family:AkrobatB; src: url('../fonts/AkrobatB.otf');}
.AkrobatB{font-family:AkrobatB;}

@font-face {font-family:AkrobatR; src: url('../fonts/AkrobatR.otf');}
.AkrobatR{font-family:AkrobatR;}

@font-face {font-family:AkrobatSB; src: url('../fonts/AkrobatSB.otf');}
.AkrobatSB{font-family:AkrobatSB;}

@font-face {font-family: AlegreyaB;src: url('../fonts/AlegreyaB.otf');}
.AlegreyaB{font-family:AlegreyaB;}

@font-face {font-family: AlegreyaI;src: url('../fonts/AlegreyaI.otf');}
.AlegreyaI{font-family:AlegreyaI;}

@font-face {font-family: AlegreyaR;src: url('../fonts/AlegreyaR.otf');}
.AlegreyaR{font-family:AlegreyaR;}

@font-face {font-family: AleoB;src: url('../fonts/AleoB.otf');}
.AleoB{font-family:AleoB;}

@font-face {font-family: AleoL;src: url('../fonts/AleoL.otf');}
.AleoL{font-family:AleoL;}

@font-face {font-family: AleoR;src: url('../fonts/AleoR.otf');}
.AleoR{font-family:AleoR;}

@font-face {font-family: ArvoB;src: url('../fonts/ArvoB.ttf');}
.ArvoB{font-family:ArvoB;}

@font-face {font-family: ArvoR;src: url('../fonts/ArvoR.ttf');}
.ArvoR{font-family:ArvoR;}

@font-face {font-family: Awesome; src: url('../fonts/Awesome.ttf');}
.Awesome{font-family:Awesome;}

@font-face {font-family: Bauru;src: url('../fonts/Bauru.ttf');}
.Bauru{font-family:Bauru;}

@font-face {font-family: Befolk;src: url('../fonts/Befolk.ttf');}
.Befolk{font-family:Befolk;}

@font-face {font-family: BitterB;src: url('../fonts/Bitter-Bold.otf');}
.BitterB{font-family:BitterB;}

@font-face {font-family: BitterBI;src: url('../fonts/Bitter-BoldItalic.otf');}
.BitterBI{font-family:BitterBI;}

@font-face {font-family: BitterI;src: url('../fonts/Bitter-Italic.otf');}
.BitterI{font-family:BitterI;}

@font-face {font-family: BitterR;src: url('../fonts/Bitter-Regular.otf');}
.BitterR{font-family:BitterR;}

@font-face {font-family: BlackWillowR;src: url('../fonts/BlackWillowR.otf');}
.BlackWillowR{font-family:BlackWillowR;}
	
@font-face {font-family: BlackWillowRO;src: url('../fonts/BlackWillowRO.otf');}
.BlackWillowRO{font-family:BlackWillowRO;}
	
@font-face {font-family: BlocB;src: url('../fonts/BlocB.ttf');}
.BlocB{font-family:BlocB;}

@font-face {font-family: BlocI;src: url('../fonts/BlocI.ttf');}
.BlocI{font-family:BlocI;}

@font-face {font-family: BlocB;src: url('../fonts/BlocB.ttf');}
.BlocB{font-family:BlocB;}

@font-face {font-family: BlocI;src: url('../fonts/BlocI.ttf');}
.BlocI{font-family:BlocI;}

@font-face {font-family: BreeB;src: url('../fonts/BreeB.otf');}
.BreeB{font-family:BreeB;}

@font-face {font-family: BlocR;src: url('../fonts/BlocR.ttf');}
.BlocR{font-family:BlocR;}

@font-face {font-family: BreeR;src: url('../fonts/BreeR.otf');}
.BreeR{font-family:BreeR;}

@font-face {font-family: BrelaR;src: url('../fonts/BrelaR.otf');}
.BrelaR{font-family:BrelaR;}

@font-face {font-family: Brux;src: url('../fonts/Brux.ttf');}
.Brux{font-family:Brux;}

@font-face {font-family: Bud;src: url('../fonts/Bud.ttf');}
.Bud{font-family:Bud;}

@font-face {font-family: BuffaloR;src: url('../fonts/BuffaloR.otf');}
.BuffaloR{font-family:BuffaloR;}

@font-face {font-family: ButlerB;src: url('../fonts/ButlerB.otf');}
.ButlerB{font-family:ButlerB;}

@font-face {font-family: ButlerM;src: url('../fonts/ButlerM.otf');}
.ButlerM{font-family:ButlerM;}

@font-face {font-family: ButlerR;src: url('../fonts/ButlerR.otf');}
.ButlerR{font-family:ButlerR;}

@font-face {font-family: CabinM;src: url('../fonts/CabinM.otf');}
.CabinM{font-family:CabinM;}

@font-face {font-family: CabinR;src: url('../fonts/CabinR.otf');}
.CabinR{font-family:CabinR;}

@font-face {font-family: CamperR;src: url('../fonts/CamperR.ttf');}
.CamperR{font-family:CamperR;}

@font-face {font-family: ChivoB;src: url('../fonts/ChivoB.ttf');}
.ChivoB{font-family:ChivoB;}

@font-face {font-family: ChivoL;src: url('../fonts/ChivoL.ttf');}
.ChivoL{font-family:ChivoL;}

@font-face {font-family: ChivoR;src: url('../fonts/ChivoR.ttf');}
.ChivoR{font-family:ChivoR;}

@font-face {font-family: CinzelB;src: url('../fonts/CinzelB.otf');}
.CinzelB{font-family:CinzelB;}

@font-face {font-family: CinzelR;src: url('../fonts/CinzelR.otf');}
.CinzelR{font-family:CinzelR;}

@font-face {font-family: CinzelR2;src: url('../fonts/CinzelR2.otf');}
.CinzelR2{font-family:CinzelR2;}

@font-face {font-family: ClearSansB;src: url('../fonts/ClearSansB.ttf');}
.ClearSansB{font-family:ClearSansB;}

@font-face {font-family: ClearSansM;src: url('../fonts/ClearSansM.ttf');}
.ClearSansM{font-family:ClearSansM;}

@font-face {font-family: ClearSansR;src: url('../fonts/ClearSansR.ttf');}
.ClearSansR{font-family:ClearSansR;}

@font-face {font-family: ClearSansT;src: url('../fonts/ClearSansT.ttf');}
.ClearSansT{font-family:ClearSansT;}

@font-face {font-family: CodeB;src: url('../fonts/CodeB.otf');}
.CodeB{font-family:CodeB;}

@font-face {font-family: CodeL;src: url('../fonts/CodeL.otf');}
.CodeL{font-family:CodeL;}

@font-face {font-family: CormorantB;src: url('../fonts/CormorantB.ttf');}
.CormorantB{font-family:CormorantB;}

@font-face {font-family: CormorantB2;src: url('../fonts/CormorantB2.ttf');}
.CormorantB2{font-family:CormorantB2;}

@font-face {font-family: CormorantI;src: url('../fonts/CormorantI.ttf');}
.CormorantI{font-family:CormorantI;}

@font-face {font-family: CormorantM;src: url('../fonts/CormorantM.ttf');}
.CormorantM{font-family:CormorantM;}

@font-face {font-family: CormorantR;src: url('../fonts/CormorantR.ttf');}
.CormorantR{font-family:CormorantR;}

@font-face {font-family: CormorantR2;src: url('../fonts/CormorantR2.ttf');}
.CormorantR2{font-family:CormorantR2;}

@font-face {font-family: CormorantSB;src: url('../fonts/CormorantSB.ttf');}
.CormorantSB{font-family:CormorantSB;}

@font-face {font-family: CrimsonB;src: url('../fonts/CrimsonB.ttf');}
.CrimsonB{font-family:CrimsonB;}

@font-face {font-family: CrimsonBI;src: url('../fonts/CrimsonBI.ttf');}
.CrimsonBI{font-family:CrimsonBI;}

@font-face {font-family: CrimsonI;src: url('../fonts/CrimsonI.ttf');}
.CrimsonI{font-family:CrimsonI;}

@font-face {font-family: CrimsonR;src: url('../fonts/CrimsonR.ttf');}
.CrimsonR{font-family:CrimsonR;}

@font-face {font-family: CrimsonRO;src: url('../fonts/CrimsonRO.ttf');}
.CrimsonRO{font-family:CrimsonRO;}

@font-face {font-family: CrimsonSB;src: url('../fonts/CrimsonSB.ttf');}
.CrimsonSB{font-family:CrimsonSB;}

@font-face {font-family: CrimsonSBI;src: url('../fonts/CrimsonSBI.ttf');}
.CrimsonSBI{font-family:CrimsonSBI;}

@font-face {font-family: CurvadaB;src: url('../fonts/CurvadaB.ttf');}
.CurvadaB{font-family:CurvadaB;}

@font-face {font-family: CurvadaR;src: url('../fonts/CurvadaR.ttf');}
.CurvadaR{font-family:CurvadaR;}

@font-face {font-family: DecaloB;src: url('../fonts/DecaloB.otf');}
.DecaloB{font-family:DecaloB;}

@font-face {font-family: DecaloR;src: url('../fonts/DecaloR.otf');}
.DecaloR{font-family:DecaloR;}

@font-face {font-family: DecaloSB;src: url('../fonts/DecaloSB.otf');}
.DecaloSB{font-family:DecaloSB;}

@font-face {font-family: FanwoodI;src: url('../fonts/FanwoodI.otf');}
.FanwoodI{font-family:FanwoodI;}

@font-face {font-family: FanwoodR;src: url('../fonts/FanwoodR.otf');}
.FanwoodR{font-family:FanwoodR;}

@font-face {font-family: FenixR;src: url('../fonts/FenixR.otf');}
.FenixR{font-family:FenixR;}

@font-face {font-family: FriedR;src: url('../fonts/FriedR.otf');}
.FriedR{font-family:FriedR;}

@font-face {font-family: Futura;src: url('../fonts/Futura.ttf');}
.Futura{font-family:Futura;}

@font-face {font-family: FuturaBI;src: url('../fonts/FuturaBI.ttf');}
.FuturaBI{font-family:FuturaBI;}

@font-face {font-family: FuturaEB;src: url('../fonts/FuturaEB.ttf');}
.FuturaEB{font-family:FuturaEB;}

@font-face {font-family: FuturaH;src: url('../fonts/FuturaH.ttf');}
.FuturaH{font-family:FuturaH;}

@font-face {font-family: FuturaHI;src: url('../fonts/FuturaL.ttf');}
.FuturaHI{font-family:FuturaHI;}

@font-face {font-family: FuturaL;src: url('../fonts/FuturaL.ttf');}
.FuturaL{font-family:FuturaL;}

@font-face {font-family: FuturaLI;src: url('../fonts/FuturaLI.ttf');}
.FuturaLI{font-family:FuturaLI;}

@font-face {font-family: FuturaM;src: url('../fonts/FuturaM.ttf');}
.FuturaM{font-family:FuturaM;}

@font-face {font-family: FuturaMI;src: url('../fonts/FuturaMI.ttf');}
.FuturaMI{font-family:FuturaMI;}

@font-face {font-family: FuturaU;src: url('../fonts/FuturaU.ttf');}
.FuturaU{font-family:FuturaU;}

@font-face {font-family: GandhiB;src: url('../fonts/GandhiB.otf');}
.GandhiB{font-family:GandhiB;}

@font-face {font-family: GandhiI;src: url('../fonts/GandhiI.otf');}
.GandhiI{font-family:GandhiI;}

@font-face {font-family: GandhiR;src: url('../fonts/GandhiR.otf');}
.GandhiR{font-family:GandhiR;}

@font-face {font-family: GiroR;src: url('../fonts/GiroR.otf');}
.GiroR{font-family:GiroR;}	

@font-face {font-family: GWF;src: url('../fonts/GWF.ttf');}
.GWF{font-family:GWF;}	

@font-face {font-family: HelpBig;src: url('../fonts/HelpBig.ttf');}
.HelpBig{font-family:HelpBig;}

@font-face {font-family: HelpSmall;src: url('../fonts/HelpSmall.ttf');}
.HelpSmall{font-family:HelpSmall;}

@font-face {font-family: Hovel;src: url('../fonts/Hovel.ttf');}
.Hovel{font-family:Hovel;}

@font-face {font-family: HVM;src: url('../fonts/HVM.ttf');}
.HVM{font-family:HVM;}

@font-face {font-family: Inkferno;src: url('../fonts/Inkferno.otf');}
.Inkferno{font-family:Inkferno;}

@font-face {font-family: ITC;src: url('../fonts/ITC.ttf');}
.ITC{font-family:ITC;}

@font-face {font-family: JaapokkiR;src: url('../fonts/JaapokkiR.otf');}
.JaapokkiR{font-family:JaapokkiR;}

@font-face {font-family: JudsonB;src: url('../fonts/JudsonB.otf');}
.JudsonB{font-family:JudsonB;}

@font-face {font-family: JudsonI;src: url('../fonts/JudsonI.otf');}
.JudsonI{font-family:JudsonI;}

@font-face {font-family: JudsonR;src: url('../fonts/JudsonR.otf');}
.JudsonR{font-family:JudsonR;}

@font-face {font-family: JuraB;src: url('../fonts/JuraB.ttf');}
.JuraB{font-family:JuraB;}

@font-face {font-family: JuraBI;src: url('../fonts/JuraBI.ttf');}
.JuraBI{font-family:JuraBI;}

@font-face {font-family: JuraI;src: url('../fonts/JuraI.ttf');}
.JuraI{font-family:JuraI;}

@font-face {font-family: JuraR;src: url('../fonts/JuraR.ttf');}
.JuraR{font-family:JuraR;}

@font-face {font-family: KlavikaB; src: url('../fonts/KlavikaB.otf');}
.KlavikaB{font-family:KlavikaB;}

@font-face {font-family: Lady;src: url('../fonts/Lady.ttf');}
.Lady{font-family:Lady;}

@font-face {font-family: LeagueGothicCI;src: url('../fonts/LeagueGothicCI.otf');}
.LeagueGothicCI{font-family:LeagueGothicCI;}

@font-face {font-family: LeagueGothicCR;src: url('../fonts/LeagueGothicCR.otf');}
.LeagueGothicCR{font-family:LeagueGothicCR;}

@font-face {font-family: LeagueGothicI;src: url('../fonts/LeagueGothicI.otf');}
.LeagueGothicI{font-family:LeagueGothicI;}

@font-face {font-family: LeagueGothicR;src: url('../fonts/LeagueGothicR.otf');}
.LeagueGothicR{font-family:LeagueGothicR;}

@font-face {font-family: LeagueGothicR2;src: url('../fonts/LeagueGothicR2.otf');}
.LeagueGothicR2{font-family:LeagueGothicR2;}

@font-face {font-family: LGC;src: url('../fonts/LGC.otf');}
.LGC{font-family:LGC;}

@font-face {font-family: LGR;src: url('../fonts/LGR.otf');}
.LGR{font-family:LGR;}

@font-face {font-family: LibreB;src: url('../fonts/LibreB.otf');}
.LibreB{font-family:LibreB;}

@font-face {font-family: LibreI;src: url('../fonts/LibreI.otf');}
.LibreI{font-family:LibreI;}

@font-face {font-family: LibreR;src: url('../fonts/LibreR.otf');}
.LibreR{font-family:LibreR;}

@font-face {font-family: Lobster;src: url('../fonts/Lobster.otf');}
.Lobster{font-family:Lobster;}

@font-face {font-family: LSN;src: url('../fonts/LSN.ttf');}
.LSN{font-family:LSN;}

@font-face {font-family: LotR;src: url('../fonts/LotR.otf');}
.LotR{font-family:LotR;}

@font-face {font-family: LSN;src: url('../fonts/LSN.ttf');}
.LSN{font-family:LSN;}

@font-face {font-family: Lucida;src: url('../fonts/Lucida.ttf');}
.Lucida{font-family:Lucida;}

@font-face {font-family: LullaBelle;src: url('../fonts/LullaBelle.ttf');}
.LullaBelle{font-family:LullaBelle;}

@font-face {font-family: LuthierB;src: url('../fonts/LuthierB.ttf');}
.LuthierB{font-family:LuthierB;}

@font-face {font-family: LuthierR;src: url('../fonts/LuthierR.ttf');}
.LuthierR{font-family:LuthierR;}

@font-face {font-family: Lymph;src: url('../fonts/Lymph.ttf');}
.Lymph{font-family:Lymph;}

@font-face {font-family: MagnaBL;src: url('../fonts/MagnaBL.ttf');}
.MagnaBL{font-family:MagnaBL;}

@font-face {font-family: Mathilde;src: url('../fonts/Mathilde.otf');}
.Mathilde{font-family:Mathilde;}

@font-face {font-family: Metropolis;src: url('../fonts/Metropolis.otf');}
.Metropolis{font-family:Metropolis;}

@font-face {font-family: MFG;src: url('../fonts/MFG.ttf');}
.MFG{font-family:MFG;}

@font-face {font-family: Minotaur;src: url('../fonts/Minotaur.ttf');}
.Minotaur{font-family:Minotaur;}

@font-face {font-family: MonkeyWishes;src: url('../fonts/MonkeyWishes.ttf');}
.MonkeyWishes{font-family:MonkeyWishes;}

@font-face {font-family: MontserratB;src: url('../fonts/MontserratB.ttf');}
.MontserratB{font-family:MontserratB;}

@font-face {font-family: MontserratBI;src: url('../fonts/MontserratBI.ttf');}
.MontserratBI{font-family:MontserratBI;}

@font-face {font-family: MontserratBl;src: url('../fonts/MontserratBl.ttf');}
.MontserratBl{font-family:MontserratBl;}

@font-face {font-family: MontserratBlI;src: url('../fonts/MontserratBlI.ttf');}
.MontserratBlI{font-family:MontserratBlI;}

@font-face {font-family: MontserratEB;src: url('../fonts/MontserratEB.ttf');}
.MontserratEB{font-family:MontserratEB;}

@font-face {font-family: MontserratEBI;src: url('../fonts/MontserratEBI.ttf');}
.MontserratEBI{font-family:MontserratEBI;}

@font-face {font-family: MontserratEL;src: url('../fonts/MontserratEL.ttf');}
.MontserratEL{font-family:MontserratEL;}

@font-face {font-family: MontserratELI;src: url('../fonts/MontserratELI.ttf');}
.MontserratELI{font-family:MontserratELI;}

@font-face {font-family: MontserratI;src: url('../fonts/MontserratI.ttf');}
.MontserratI{font-family:MontserratI;}

@font-face {font-family: MontserratL;src: url('../fonts/MontserratL.ttf');}
.MontserratL{font-family:MontserratL;}

@font-face {font-family: MontserratLI;src: url('../fonts/MontserratLI.ttf');}
.MontserratLI{font-family:MontserratLI;}

@font-face {font-family: MontserratM;src: url('../fonts/MontserratM.ttf');}
.MontserratM{font-family:MontserratM;}

@font-face {font-family: MontserratMI;src: url('../fonts/MontserratMI.ttf');}
.MontserratMI{font-family:MontserratMI;}

@font-face {font-family: MontserratR;src: url('../fonts/MontserratR.ttf');}
.MontserratR{font-family:MontserratR;}

@font-face {font-family: MontserratSB;src: url('../fonts/MontserratSB.ttf');}
.MontserratSB{font-family:MontserratSB;}

@font-face {font-family: MontserratSBI;src: url('../fonts/MontserratSBI.ttf');}
.MontserratSBI{font-family:MontserratSBI;}

@font-face {font-family: MontserratT;src: url('../fonts/MontserratT.ttf');}
.MontserratT{font-family:MontserratT;}

@font-face {font-family: MontserratTI;src: url('../fonts/MontserratTI.ttf');}
.MontserratTI{font-family:MontserratTI;}

@font-face {font-family: MSR;src: url('../fonts/MSR.ttf');}
.MSR{font-family:MSR;}

@font-face {font-family: MusketB;src: url('../fonts/MusketB.otf');}
.MusketB{font-family:MusketB;}

@font-face {font-family: MusketR;src: url('../fonts/MusketR.otf');}
.MusketR{font-family:MusketR;}

@font-face {font-family: MyriadBold;src: url('../fonts/MyriadB.otf');}
.MyriadBold{font-family:MyriadBold;}
	
@font-face {font-family: MyriadRegular;src: url('../fonts/MyriadR.otf');}
.MyriadRegular{font-family:MyriadRegular;}

@font-face {font-family: NeutonI;src: url('../fonts/NeutonI.otf');}
.NeutonI{font-family:NeutonI;}

@font-face {font-family: NeutonR;src: url('../fonts/NeutonR.ttf');}
.NeutonR{font-family:NeutonR;}

@font-face {font-family: NeutonR2;src: url('../fonts/NeutonR2.ttf');}
.NeutonR2{font-family:NeutonR2;}

@font-face {font-family: NeutonB;src: url('../fonts/NeutonB.ttf');}
.NeutonB{font-family:NeutonB;}

@font-face {font-family: Nickley;src: url('../fonts/Nickley.otf');}
.Nickley{font-family:Nickley;}

@font-face {font-family: OIW;src: url('../fonts/OIW.ttf');}
.OIW{font-family:OIW;}

@font-face {font-family: Oranien;src: url('../fonts/Oranien.ttf');}
.Oranien{font-family:Oranien;}

@font-face {font-family: Oregon; src: url('../fonts/OregonLDO.ttf');}
.Oregon{font-family:Oregon;}

@font-face {font-family: OregonB; src: url('../fonts/OregonLDOBlack.ttf');}
.OregonB{font-family:OregonB;}

@font-face {font-family: ParalinesR;src: url('../fonts/ParalinesR.otf');}
.ParalinesR{font-family:ParalinesR;}

@font-face {font-family: PermianB;src: url('../fonts/PermianB.otf');}
.PermianB{font-family:PermianB;}

@font-face {font-family: PermianI;src: url('../fonts/PermianI.otf');}
.PermianI{font-family:PermianI;}

@font-face {font-family: PermianR;src: url('../fonts/PermianR.otf');}
.PermianR{font-family:PermianR;}

@font-face {font-family: PetitLatin;src: url('../fonts/PetitLatin.ttf');}
.PetitLatin{font-family:PetitLatin;}

@font-face {font-family: PlayB;src: url('../fonts/PlayB.ttf');}
.PlayB{font-family:PlayB;}

@font-face {font-family: PlayI;src: url('../fonts/PlayI.ttf');}
.PlayI{font-family:PlayI;}

@font-face {font-family: PlayR;src: url('../fonts/PlayR.ttf');}
.PlayR{font-family:PlayR;}

@font-face {font-family: PlayR2;src: url('../fonts/PlayR2.otf');}
.PlayR2{font-family:PlayR2;}

@font-face {font-family: Presu;src: url('../fonts/Presu.ttf');}
.Presu{font-family:Presu;}

@font-face {font-family: PT;src: url('../fonts/PT.ttf');}
.PT{font-family:PT;}

@font-face {font-family: PWM;src: url('../fonts/PWM.ttf');}
.PWM{font-family:PWM;}

@font-face {font-family: Radley;src: url('../fonts/Radley.otf');}
.Radley{font-family:Radley;}

@font-face {font-family: RalewayB;src: url('../fonts/RalewayB.ttf');}
.RalewayB{font-family:RalewayB;}

@font-face {font-family: RalewayB2;src: url('../fonts/RalewayB2.ttf');}
.RalewayB2{font-family:RalewayB2;}

@font-face {font-family: RalewayL;src: url('../fonts/RalewayL.ttf');}
.RalewayL{font-family:RalewayL;}

@font-face {font-family: RalewayM;src: url('../fonts/RalewayM.ttf');}
.RalewayM{font-family:RalewayM;}

@font-face {font-family: RalewayR;src: url('../fonts/RalewayR.ttf');}
.RalewayR{font-family:RalewayR;}

@font-face {font-family: RalewayT;src: url('../fonts/RalewayT.ttf');}
.RalewayT{font-family:RalewayT;}

@font-face {font-family: Razor;src: url('../fonts/Razor.otf');}
.Razor{font-family:Razor;}

@font-face {font-family: RidgeR;src: url('../fonts/RidgeR.otf');}
.RidgeR{font-family:RidgeR;}

@font-face {font-family: RomanaB;src: url('../fonts/RomanaB.ttf');}
.RomanaB{font-family:RomanaB;}

@font-face {font-family: RomanaI;src: url('../fonts/RomanaI.ttf');}
.RomanaI{font-family:RomanaI;}

@font-face {font-family: RomanaR;src: url('../fonts/RomanaR.ttf');}
.RomanaR{font-family:RomanaR;}

@font-face {font-family: Rutan;src: url('../fonts/Rutan.ttf');}
.Rutan{font-family:Rutan;}

@font-face {font-family: Ruthie;src: url('../fonts/Ruthie.ttf');}
.Ruthie{font-family:Ruthie;}

@font-face {font-family: Segan;	src: url('../fonts/Segan.ttf');}
.Segan{font-family:Segan;}

@font-face {font-family: Setta;	src: url('../fonts/Setta.ttf');}
.Setta{font-family:Setta;}

@font-face {font-family: SimonettaI;src: url('../fonts/SimonettaI.otf');}
.SimonettaI{font-family:SimonettaI;}

@font-face {font-family: SimonettaR;src: url('../fonts/SimonettaR.otf');}
.SimonettaR{font-family:SimonettaR;}

@font-face {font-family: Sorrow;src: url('../fonts/Sorrow.ttf');}
.Sorrow{font-family:Sorrow;}

@font-face {font-family: STR;src: url('../fonts/STR.ttf');}
.STR{font-family:STR;}

@font-face {font-family: Street;src: url('../fonts/Street.otf');}
.Street{font-family:Street;}

@font-face {font-family: SuperTypeR;src: url('../fonts/SuperTypeR.ttf');}
.SuperTypeR{font-family:SuperTypeR;}

@font-face {font-family: SuperTypeSB;src: url('../fonts/SuperTypeSB.ttf');}
.SuperTypeSB{font-family:SuperTypeSB;}

@font-face {font-family: TripB;src: url('../fonts/TripB.ttf');}
.TripB{font-family:TripB;}

@font-face {font-family: TripI;src: url('../fonts/TripI.ttf');}
.TripI{font-family:TripI;}

@font-face {font-family: TripR;src: url('../fonts/TripR.ttf');}
.TripR{font-family:TripR;}

@font-face {font-family: UbuntuBold;src: url('../fonts/UbuntuB.ttf');}
.UbuntuBold{font-family:UbuntuBold;}

@font-face {font-family: UbuntuMedium;src: url('../fonts/UbuntuM.ttf');}
.UbuntuMedium{font-family:UbuntuMedium;}

@font-face {font-family: UbuntuRegular;src: url('../fonts/UbuntuR.ttf');}
.UbuntuRegular{font-family:UbuntuRegular;}

@font-face {font-family: Unna;src: url('../fonts/Unna.otf');}
.Unna{font-family:Unna;}

@font-face {font-family: VolkhovB;src: url('../fonts/VolkhovB.ttf');}
.VolkhovB{font-family:VolkhovB;}

@font-face {font-family: VolkhovI;src: url('../fonts/VolkhovI.ttf');}
.VolkhovI{font-family:VolkhovI;}

@font-face {font-family: VolkhovR;src: url('../fonts/VolkhovR.ttf');}
.VolkhovR{font-family:VolkhovR;}

@font-face {font-family: WaywardR;src: url('../fonts/WaywardR.otf');}
.WaywardR{font-family:WaywardR;}

@font-face {font-family: Week;src: url('../fonts/Week.ttf');}
.Week{font-family:Week;}

@font-face {font-family: WildYouthR;src: url('../fonts/WildYouthR.otf');}
.WildYouthR{font-family:WildYouthR;}

@font-face {font-family: WL;src: url('../fonts/WL.ttf');}
.WL{font-family:WL;}

@font-face {font-family: WLM;src: url('../fonts/WLM.ttf');}
.WLM{font-family:WLM;}

/*	@font-face {font-family: WS;src: url('../fonts/WS.otf');}	
.WS{font-family:WS;}
/**/	
@font-face {font-family: WSY;src: url('../fonts/WSY.otf');}
.WSY{font-family:WSY;}

@font-face {font-family: YellowTailR;src: url('../fonts/YellowTailR.ttf');}
.YellowTailR{font-family:YellowTailR;}

@font-face {font-family: Zviro;src: url('../fonts/Zviro.ttf');}
.Zviro{font-family:Zviro;}

