a {
  /* color:inherit; */
  color: #000;
}
a:hover {
  /* color:inherit; */
   color: #000
}

/*############################################ FONT ##############################################*/
/*############################################ FONT ##############################################*/
.InputFont {
  font-family: FuturaM;
}
.ButtonFont {
  font-family: FuturaM;
}
.LabelFont {
  font-family: FuturaM;
}
.HeaderFont {
  font-family: FuturaH;
}
.TitleFont {
  font-family: FuturaU;
}
.TextFont {
  font-family: FuturaL;
}



/*############################################ BUTTONS ##############################################*/
/*############################################ BUTTONS ##############################################*/
.bSignin {
  background-color: #ad182f;
  color: #ffffff;
  border: 0;
  padding: 10px;
  font-family: FuturaM;
  box-shadow: 0px 1px 3px 0px #D3D3D3;-moz-box-shadow: 0px 1px 3px 0px #D3D3D3;-webkit-box-shadow: 0px 1px 3px 0px #D3D3D3;
  outline: none;
}

.bSignin:disabled {
  background: #f6f6f6;
}



/*############################################ INPUTS  ##############################################*/
/*############################################ INPUTS  ##############################################*/
.InputBasic {
  border: 0;
  /* box-shadow: 0px 1px 3px 0px #D3D3D3;-moz-box-shadow: 0px 1px 3px 0px #D3D3D3;-webkit-box-shadow: 0px 1px 3px 0px #D3D3D3; */
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  outline: none;
}


/*############################################  DIV  ##############################################*/
/*############################################  DIV  ##############################################*/

.react-switch-selector-wrapper{
}


.react-switch-selector-option-label{
  margin:0;
  padding:7px;
  font-size: 12px;
  font-family: FuturaH;
}

.react-switch-selector-option-label{
}


/*############################################  CARD  ##############################################*/
/*############################################  CARD  ##############################################*/
.card {
  margin-bottom: 30px;
}
.card-footer {
  padding: 0;
}

/*############################################  IMAGE  ##############################################*/
/*############################################  IMAGE  ##############################################*/
.ImageCentered {
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

@media screen and (min-width: 981px) {
  .PaddingResponsive{
    padding: 1% 3% 0% 3%;
  } 

  .MobileMenu{
    display:none;
  } 

  
  .DesktopMenu{
    display:block;
    position: relative;
    float:left;
    width:100%;
  } 


  .ImageFit {
    width: 100%;
    height: auto;
    max-height:1000px;
  }

  .OutletTopPadding{
    padding: 100px 10px 0 10px;
  }

  .GoHome{
    position: absolute;
    bottom:60px;
    right: 30px;
  } 

}

@media screen and (max-width: 980px) {
  .PaddingResponsive{
    padding: 5% 5% 0% 5%;
  } 

  .GoHome{
    position: absolute;
    bottom:100px;
    right: 20px; 
  } 

  .MobileMenu{
    display:block;
    padding: 0px;
  } 

  .DesktopMenu{
    display:none;
  } 
  
  
  .DesktopMenuFake{
    display:none;
  }   

  .ImageFit {
    width: auto;
    height: 100%;
    max-width:2000px;
  }

  .OutletTopPadding{
    padding: 0px 0 0 0;
  }
}

/*############################################  NAV ELEMENT  ##############################################*/
/*############################################  NAV ELEMENT  ##############################################*/
.navbar .dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu {
  top: 120% !important;
}
/*############################################  MODAL BG CLICKABLE ##############################################*/
/*############################################  MODAL BG CLICKABLE ##############################################*/
.modal-clickable {
  bottom: initial !important;
}
.modal-backdrop {
  display: none !important;
}

.modal-open {
  overflow: visible;
}
.modal-fluid {
  width: 95%;
}
.modal-lg {
  width: 90%;
  max-width: 1500px;
}
.modal-md {
  width: 60%;
}
.modal-sm {
  width: 30%;
}
