
	/* @@@@@@@@@@@@@@@@@@@ NUMBER COLUMN @@@@@@@@@@@@@@@@@@@@@@ /**/
	/* @@@@@@@@@@@@@@@@@@@ NUMBER COLUMN @@@@@@@@@@@@@@@@@@@@@@ /**/
	/* @@@@@@@@@@@@@@@@@@@ NUMBER COLUMN @@@@@@@@@@@@@@@@@@@@@@ /**/
	.Column1{column-count:1;-webkit-column-count: 1;-moz-column-count: 1;}
	.Column2{column-count:2;-webkit-column-count: 2;-moz-column-count: 2;}
	.Column3{column-count:3;-webkit-column-count: 3;-moz-column-count: 3;}
	.Column4{column-count:4;-webkit-column-count: 4;-moz-column-count: 4;}
	.Column5{column-count:5;-webkit-column-count: 5;-moz-column-count: 5;}
	
	
	/*#################### COLUMN GAP ####################/**/
	/*#################### COLUMN GAP ####################/**/
	/*#################### COLUMN GAP ####################/**/
	.ColumnGap1px{column-gap:1px;}
	.ColumnGap2px{column-gap:2px;}
	.ColumnGap3px{column-gap:3px;}
	.ColumnGap4px{column-gap:4px;}
	.ColumnGap5px{column-gap:5px;} 
	.ColumnGap10px{column-gap:10px;} 
	.ColumnGap15px{column-gap:15px;}
	.ColumnGap20px{column-gap:20px;}
	.ColumnGap25px{column-gap:25px;}
	.ColumnGap30px{column-gap:30px;}
	.ColumnGap35px{column-gap:35px;}
	.ColumnGap40px{column-gap:40px;}
	.ColumnGap45px{column-gap:45px;}
	.ColumnGap50px{column-gap:50px;}
	.ColumnGap60px{column-gap:60px;}
	.ColumnGap70px{column-gap:70px;}
	.ColumnGap80px{column-gap:80px;}
	.ColumnGap90px{column-gap:90px;}
	.ColumnGap100px{column-gap:100px;}
	
	
	/* ################### COLUMN RULE ################## /**/
	/* ################### COLUMN RULE ################## /**/
	/* ################### COLUMN RULE ################## /**/
	.ColumnRuleSolid{column-rule-style: solid;}
	.ColumnSolid{column-rule-style: dashed;}
	
	
	/* ################### COLUMN RULE SIZE ################## /**/
	/* ################### COLUMN RULE SIZE ################## /**/
	/* ################### COLUMN RULE SIZE ################## /**/
	.ColumnRule1px{column-rule-width:1px;}
	.ColumnRule2px{column-rule-width:2px;}
	.ColumnRule3px{column-rule-width:3px;}
	.ColumnRule4px{column-rule-width:4px;}
	.ColumnRule5px{column-rule-width:5px;}
	.ColumnRule6px{column-rule-width:6px;}
	.ColumnRule7px{column-rule-width:7px;}
	.ColumnRule8px{column-rule-width:8px;}
	.ColumnRule9px{column-rule-width:9px;}
	.ColumnRule10px{column-rule-width:10px;}
	
	/* ################### COLUMN WIDTH ################## /**/
	/* ################### COLUMN WIDTH ################## /**/
	/* ################### COLUMN WIDTH ################## /**/
	.ColumnWide50px{column-width:50px;}
	.ColumnWide60px{column-width:60px;}
	.ColumnWide70px{column-width:70px;}
	.ColumnWide80px{column-width:80px;}
	.ColumnWide90px{column-width:90px;}
	.ColumnWide100px{column-width:100px;}
	.ColumnWide110px{column-width:110px;}
	.ColumnWide120px{column-width:120px;}
	.ColumnWide130px{column-width:130px;}
	.ColumnWide140px{column-width:140px;}
	.ColumnWide150px{column-width:150px;}
	.ColumnWide160px{column-width:160px;}
	.ColumnWide170px{column-width:170px;}
	.ColumnWide180px{column-width:180px;}
	.ColumnWide190px{column-width:190px;}
	.ColumnWide200px{column-width:200px;}
	.ColumnWide200px{column-width:225px;}
	.ColumnWide200px{column-width:250px;}
	.ColumnWide200px{column-width:275px;}
	.ColumnWide300px{column-width:300px;}
	.ColumnWide300px{column-width:325px;}
	.ColumnWide300px{column-width:350px;}
	.ColumnWide300px{column-width:375px;}
	.ColumnWide400px{column-width:400px;}
	.ColumnWide400px{column-width:425px;}
	.ColumnWide400px{column-width:450px;}
	.ColumnWide400px{column-width:475px;}
	.ColumnWide500px{column-width:5000px;}
