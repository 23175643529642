@import url('https://fonts.googleapis.com/css2?family=Monoton&family=Montserrat:wght@100;400;600;700&display=swap');

body {
  margin: 0;
  color: #000000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* a { */
.nav-link.active {
  background-color: rgba(255,255,255,0) !important;
  color: #ad182f !important;
}

.mapboxgl-popup-content{
  background-color: rgba(0,0,0,0);
  padding: 0px;
  /* width: 160px; */
}

.mapboxgl-popup-tip{
  display: none;
}

.container_breakdown{
  width: 100% !important;
  
}

.element_breakdown{
  width:100% !important;
  border: solid 0px black;
  margin-bottom: 4px !important;
  border-radius: 5px !important;
  background-color: rgba(10, 10, 10, 0.9);
  padding: 5px !important;
}

.text_breakdown{
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  font-weight: 800;
}

.Monoton{
  font-family: 'Monoton', cursive;
  font-weight: 600;
}

.Montserrat{
  font-family: 'Montserrat', sans-serif;
}

.viewer_text{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  height: 80px;
}
