
	/*********************  INPUT FIELD ***********************/

	input[type="text"]{
 		/*border:1px solid white;/**/
	}

	input[type="button"]{}
	input[type="button"]:hover{border:1px solid black;}

  	.NoOutline{outline:none;}

	/********************************  REMOVE BUTTON  *******************************/
	#ResetIcon{
	border-radius:0 0 0 0;
	-moz-border-radius:0 0 0 0;
	-webkit-border-radius:0 0 0 0;
	background-color:white;	
	width:5%;
	}
	
	.close-icon {
	border:1px solid transparent;
	background-color: transparent;
	display: inline-block;
	vertical-align: middle;
  	outline: 0;
  	cursor: pointer;
	z-index:-3;		
	}
	.close-icon:after {
	font-size: 0.8vw;
	color:#9FB6CD;			
	content: "X";
	font-weight:bold;
	z-index:1;
	text-align: center;
	cursor: pointer;
	padding: 1px 2px 1px 2px;
	border-radius: 50%;
	border-style: solid;
	border-width:1px 1px 1px 1px;
	border-color:#9FB6CD;	
	}

	/********************************   LOGIN   *****************************************/
	.Login {
	clear: right;
	width: 100%;
	text-align: right;
	height: 100%;
	background-color: #F1F1F1;
	color: white;
	}
	.FormLogin {
	width:100%;
	margin-left:auto;
	margin-right:auto;
	height:50%;
	position:relative;
	float:top;
	}

	.Password {
	float: top;
	height:20%;
	width:70%;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;		
	-ms-border-radius:3px;
	-o-border-radius:3px;
	border-radius:3px;
	}	

	.ButtonLogin{
	float:right;
	height:103%;
	font-size: 0.9vw;		
	}	
