*,
*:after,
*::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {font-family: 'Raleway', sans-serif;}
nav a,nav a:visited {
	position: relative;
	display: inline-block;
	outline: none;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 1px;
}


nav a:hover{outline: none;}

nav a:focus {outline: none;}


/* Effect 1: Brackets */
.cl-effect-1 a::before,
.cl-effect-1 a::after {
	display: inline-block;
	opacity: 0;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
	-moz-transition: -moz-transform 0.3s, opacity 0.2s;
	transition: transform 0.3s, opacity 0.2s;
}

.cl-effect-1 a::before {
	margin-right: 5px;
	content: '[';
	-webkit-transform: translateX(20px);
	-moz-transform: translateX(20px);
	transform: translateX(20px);
}

.cl-effect-1 a::after {
	content: ']';
	-webkit-transform: translateX(-20px);
	-moz-transform: translateX(-20px);
	transform: translateX(-20px);
}

.cl-effect-1 a:hover::before,
.cl-effect-1 a:hover::after{
	opacity: 1;
	-webkit-transform: translateX(0px);
	-moz-transform: translateX(0px);
	transform: translateX(0px);
	color:#db2d2d;
}

.cl-effect-1 a:focus::before,
.cl-effect-1 a:focus::after {
	opacity: 1;
	-webkit-transform: translateX(0px);
	-moz-transform: translateX(0px);
	transform: translateX(0px);
	color:#db2d2d;
}


/* Effect 2: 3D rolling links, idea from http://hakim.se/thoughts/rolling-links */
.cl-effect-2 a {
	-webkit-perspective: 1000px;
	-moz-perspective: 1000px;
	perspective: 1000px;
}

.cl-effect-2 a span {
	position: relative;
	display: inline-block;
	padding: 0 14px;
	background: #db2d2d;
	-webkit-transition: -webkit-transform 0.3s;
	-moz-transition: -moz-transform 0.3s;
	transition: transform 0.3s;
	-webkit-transform-origin: 50% 0;
	-moz-transform-origin: 50% 0;
	transform-origin: 50% 0;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.csstransforms3d .cl-effect-2 a span::before {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 100%;
	background: #db2d2d;
	content: attr(data-hover);
	-webkit-transition: background 0.3s;
	-moz-transition: background 0.3s;
	transition: background 0.3s;
	-webkit-transform: rotateX(-90deg);
	-moz-transform: rotateX(-90deg);
	transform: rotateX(-90deg);
	-webkit-transform-origin: 50% 0;
	-moz-transform-origin: 50% 0;
	transform-origin: 50% 0;
}

.cl-effect-2 a:hover span,
.cl-effect-2 a:focus span {
	-webkit-transform: rotateX(90deg) translateY(-22px);
	-moz-transform: rotateX(90deg) translateY(-22px);
	transform: rotateX(90deg) translateY(-22px);
}

.csstransforms3d .cl-effect-2 a:hover span::before,
.csstransforms3d .cl-effect-2 a:focus span::before {
	background: #db2d2d;	
}

/* Effect 3: bottom line slides/fades in */
.cl-effect-3 a {
	padding: 8px 0;
}

.cl-effect-3 a::after {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 1px;
	/*background: rgba(255,255,255,0.8);/**/
	background:#db2d2d;
	content: '';
	opacity: 0;
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	-moz-transition: opacity 0.3s, -moz-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
	-webkit-transform: translateY(10px);
	-moz-transform: translateY(10px);
	transform: translateY(10px);
}

.cl-effect-3 a:hover::after,
.cl-effect-3 a:focus::after {
	opacity: 1;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	transform: translateY(0px);
}

/* Effect 4: bottom border enlarge */
.cl-effect-4 a {
	padding: 0 0 10px;
}

.cl-effect-4 a::after {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 1px;
	background: #fff;
	content: '';
	opacity: 0;
	-webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
	-moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
	transition: height 0.3s, opacity 0.3s, transform 0.3s;
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	transform: translateY(-10px);
}

.cl-effect-4 a:hover::after,
.cl-effect-4 a:focus::after {
	height: 5px;
	opacity: 1;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	transform: translateY(0px);
}

/* Effect 5: same word slide in */
.cl-effect-5 a {
	overflow: hidden;
	padding: 0 4px;
	height: 1em;
}

.cl-effect-5 a span {
	position: relative;
	display: inline-block;
	-webkit-transition: -webkit-transform 0.3s;
	-moz-transition: -moz-transform 0.3s;
	transition: transform 0.3s;
}

.cl-effect-5 a span::before {
	position: absolute;
	top: 100%;
	content: attr(data-hover);
	font-weight: 700;
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

.cl-effect-5 a:hover span,
.cl-effect-5 a:focus span {
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	transform: translateY(-100%);
}

/* Effect 5: same word slide in and border bottom */
.cl-effect-6 a {
	margin: 0 10px;
	padding: 10px 20px;
}

.cl-effect-6 a::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background: #fff;
	content: '';
	-webkit-transition: top 0.3s;
	-moz-transition: top 0.3s;
	transition: top 0.3s;
}

.cl-effect-6 a::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 2px;
	height: 2px;
	background: #fff;
	content: '';
	-webkit-transition: height 0.3s;
	-moz-transition: height 0.3s;
	transition: height 0.3s;
}

.cl-effect-6 a:hover::before {
	top: 100%;
	opacity: 1;
}

.cl-effect-6 a:hover::after {
	height: 100%;
} 

/* Effect 7: second border slides up */
.cl-effect-7 a {
	padding: 12px 10px 10px;
	color: #db2d2d;
	text-shadow: none;
	font-weight: 700;
}

.cl-effect-7 a::before,
.cl-effect-7 a::after {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 3px;
	background: #db2d2d;
	content: '';
	-webkit-transition: -webkit-transform 0.3s;
	-moz-transition: -moz-transform 0.3s;
	transition: transform 0.3s;
	-webkit-transform: scale(0.85);
	-moz-transform: scale(0.85);
	transform: scale(0.85);
}

.cl-effect-7 a::after {
	opacity: 0;
	-webkit-transition: top 0.3s, opacity 0.3s, -webkit-transform 0.3s;
	-moz-transition: top 0.3s, opacity 0.3s, -moz-transform 0.3s;
	transition: top 0.3s, opacity 0.3s, transform 0.3s;
}

.cl-effect-7 a:hover::before,
.cl-effect-7 a:hover::after,
.cl-effect-7 a:focus::before,
.cl-effect-7 a:focus::after {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	transform: scale(1);
}

.cl-effect-7 a:hover::after,
.cl-effect-7 a:focus::after {
	top: 0%;
	opacity: 1;
}

/* Effect 8: border slight translate */
.cl-effect-8 a {
	padding: 10px 20px;
}

.cl-effect-8 a::before,
.cl-effect-8 a::after  {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 3px solid #db2d2d;
	content: '';
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	-moz-transition: -moz-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
}

.cl-effect-8 a::after  {
	border-color: #fff;
	opacity: 0;
	-webkit-transform: translateY(-7px) translateX(6px);
	-moz-transform: translateY(-7px) translateX(6px);
	transform: translateY(-7px) translateX(6px);
}

.cl-effect-8 a:hover::before,
.cl-effect-8 a:focus::before {
	opacity: 0;
	-webkit-transform: translateY(5px) translateX(-5px);
	-moz-transform: translateY(5px) translateX(-5px);
	transform: translateY(5px) translateX(-5px);
}

.cl-effect-8 a:hover::after,
.cl-effect-8 a:focus::after  {
	opacity: 1;
	-webkit-transform: translateY(0px) translateX(0px);
	-moz-transform: translateY(0px) translateX(0px);
	transform: translateY(0px) translateX(0px);
}

/* Effect 9: second text and borders */
.cl-effect-9 a {
	margin: 0 20px;
	padding: 18px 20px;
}

.cl-effect-9 a::before,
.cl-effect-9 a::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background: #fff;
	content: '';
	opacity: 0.2;
	-webkit-transition: opacity 0.3s, height 0.3s;
	-moz-transition: opacity 0.3s, height 0.3s;
	transition: opacity 0.3s, height 0.3s;
}

.cl-effect-9 a::after {
	top: 100%;
	opacity: 0;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	-moz-transition: -moz-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	transform: translateY(-10px);
}

.cl-effect-9 a span:first-child {
	z-index: 2;
	display: block;
	font-weight: 300;
}

.cl-effect-9 a span:last-child {
	z-index: 1;
	display: block;
	padding: 8px 0 0 0;
	color: #db2d2d;
	text-shadow: none;
	text-transform: none;
	font-style: italic;
	font-size: 0.75em;
	font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
	opacity: 0;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	-moz-transition: -moz-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	transform: translateY(-100%);
}

.cl-effect-9 a:hover::before,
.cl-effect-9 a:focus::before {
	height: 6px;
}

.cl-effect-9 a:hover::before,
.cl-effect-9 a:hover::after,
.cl-effect-9 a:focus::before,
.cl-effect-9 a:focus::after {
	opacity: 1;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	transform: translateY(0px);
}

.cl-effect-9 a:hover span:last-child,
.cl-effect-9 a:focus span:last-child {
	opacity: 1;
	-webkit-transform: translateY(0%);
	-moz-transform: translateY(0%);
	transform: translateY(0%);
}

/* Effect 10: reveal, push out */
.cl-effect-10  {
	position: relative;
	z-index: 1;
}

.cl-effect-10 a {
	overflow: hidden;
	margin: 0 15px;
}

.cl-effect-10 a span {
	display: block;
	padding: 10px 20px;
	background: #db2d2d;
	-webkit-transition: -webkit-transform 0.3s;
	-moz-transition: -moz-transform 0.3s;
	transition: transform 0.3s;
}

.cl-effect-10 a::before {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	padding: 10px 20px;
	width: 100%;
	height: 100%;
	background: #fff;
	color: #db2d2d;
	content: attr(data-hover);
	-webkit-transition: -webkit-transform 0.3s;
	-moz-transition: -moz-transform 0.3s;
	transition: transform 0.3s;
	-webkit-transform: translateX(-25%);
}

.cl-effect-10 a:hover span,
.cl-effect-10 a:focus span {
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	transform: translateX(100%);
}

.cl-effect-10 a:hover::before,
.cl-effect-10 a:focus::before {
	-webkit-transform: translateX(0%);
	-moz-transform: translateX(0%);
	transform: translateX(0%);
}

/* Effect 11: text fill based on Lea Verou's animation http://dabblet.com/gist/6046779 */
.cl-effect-11 a {
	padding: 10px 0;
	border-top: 2px solid #db2d2d;
	color: #db2d2d;
	text-shadow: none;
} 

.cl-effect-11 a::before {
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	padding: 10px 0;
	max-width: 0;
	border-bottom: 2px solid #fff;
	color: #fff;
	content: attr(data-hover);
	-webkit-transition: max-width 0.5s;
	-moz-transition: max-width 0.5s;
	transition: max-width 0.5s;
}

.cl-effect-11 a:hover::before,
.cl-effect-11 a:focus::before {
	max-width: 100%;
}

/* Effect 12: circle */
.cl-effect-12 a::before,
.cl-effect-12 a::after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100px;
	height: 100px;
	border: 2px solid rgba(0,0,0,0.1);
	border-radius: 50%;
	content: '';
	opacity: 0;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	-moz-transition: -moz-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
	-webkit-transform: translateX(-50%) translateY(-50%) scale(0.2);
	-moz-transform: translateX(-50%) translateY(-50%) scale(0.2);
	transform: translateX(-50%) translateY(-50%) scale(0.2);
}

.cl-effect-12 a::after {
	width: 90px;
	height: 90px;
	border-width: 6px;
	-webkit-transform: translateX(-50%) translateY(-50%) scale(0.8);
	-moz-transform: translateX(-50%) translateY(-50%) scale(0.8);
	transform: translateX(-50%) translateY(-50%) scale(0.8);
}

.cl-effect-12 a:hover::before,
.cl-effect-12 a:hover::after,
.cl-effect-12 a:focus::before,
.cl-effect-12 a:focus::after {
	opacity: 1;
	-webkit-transform: translateX(-50%) translateY(-50%) scale(1);
	-moz-transform: translateX(-50%) translateY(-50%) scale(1);
	transform: translateX(-50%) translateY(-50%) scale(1);
}

/* Effect 13: three circles */
.cl-effect-13 a {
	-webkit-transition: color 0.3s;
	-moz-transition: color 0.3s;
	transition: color 0.3s;
}

.cl-effect-13 a::before {
	position: absolute;
	top: 100%;
	left: 50%;
	color: transparent;
	content: '•';
	text-shadow: 0 0 transparent;
	font-size: 1.2em;
	-webkit-transition: text-shadow 0.3s, color 0.3s;
	-moz-transition: text-shadow 0.3s, color 0.3s;
	transition: text-shadow 0.3s, color 0.3s;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	transform: translateX(-50%);
	pointer-events: none;
}

.cl-effect-13 a:hover::before,
.cl-effect-13 a:focus::before {
	color: #fff;
	text-shadow: 10px 0 #fff, -10px 0 #fff;
}

.cl-effect-13 a:hover,
.cl-effect-13 a:focus {
	color: #ba7700;
}

/* Effect 14: border switch */
.cl-effect-14 a {
	padding: 0 20px;
	height: 45px;
	line-height: 45px;
}

.cl-effect-14 a::before,
.cl-effect-14 a::after {
	position: absolute;
	width: 45px;
	height: 2px;
	background: #fff;
	content: '';
	opacity: 0.2;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	pointer-events: none;
}

.cl-effect-14 a::before {
	top: 0;
	left: 0;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	transform: rotate(90deg);
	-webkit-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	transform-origin: 0 0;
}

.cl-effect-14 a::after {
	right: 0;
	bottom: 0;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	transform: rotate(90deg);
	-webkit-transform-origin: 100% 0;
	-moz-transform-origin: 100% 0;
	transform-origin: 100% 0;
}

.cl-effect-14 a:hover::before,
.cl-effect-14 a:hover::after,
.cl-effect-14 a:focus::before,
.cl-effect-14 a:focus::after {
	opacity: 1;
}

.cl-effect-14 a:hover::before,
.cl-effect-14 a:focus::before {
	left: 50%;
	-webkit-transform: rotate(0deg) translateX(-50%);
	-moz-transform: rotate(0deg) translateX(-50%);
	transform: rotate(0deg) translateX(-50%);
}

.cl-effect-14 a:hover::after,
.cl-effect-14 a:focus::after {
	right: 50%;
	-webkit-transform: rotate(0deg) translateX(50%);
	-moz-transform: rotate(0deg) translateX(50%);
	transform: rotate(0deg) translateX(50%);
}

/* Effect 15: scale down, reveal */
.cl-effect-15 a {
	color: rgba(0,0,0,0.2);
	font-weight: 700;
	text-shadow: none;
}

.cl-effect-15 a::before {
	color: #fff;
	content: attr(data-hover);
	position: absolute;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	-moz-transition: -moz-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
}

.cl-effect-15 a:hover::before,
.cl-effect-15 a:focus::before {
	-webkit-transform: scale(0.9);
	-moz-transform: scale(0.9);
	transform: scale(0.9);
	opacity: 0;
}

/* Effect 16: fall down */
.cl-effect-16 a {
	color: #db2d2d;
	text-shadow: 0 0 1px rgba(111,134,134,0.3);
}

.cl-effect-16 a::before {
	color: #fff;
	content: attr(data-hover);
	position: absolute;
	opacity: 0;
	text-shadow: 0 0 1px rgba(255,255,255,0.3);
	-webkit-transform: scale(1.1) translateX(10px) translateY(-10px) rotate(4deg);
	-moz-transform: scale(1.1) translateX(10px) translateY(-10px) rotate(4deg);
	transform: scale(1.1) translateX(10px) translateY(-10px) rotate(4deg);
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	-moz-transition: -moz-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
	pointer-events: none;
}

.cl-effect-16 a:hover::before,
.cl-effect-16 a:focus::before {
	-webkit-transform: scale(1) translateX(0px) translateY(0px) rotate(0deg);
	-moz-transform: scale(1) translateX(0px) translateY(0px) rotate(0deg);
	transform: scale(1) translateX(0px) translateY(0px) rotate(0deg);
	opacity: 1;
}

/* Effect 17: move up fade out, push border */
.cl-effect-17 a {
	color: #db2d2d;
	text-shadow: none;
	padding: 10px 0;
}

.cl-effect-17 a::before {
	color: #fff;
	text-shadow: 0 0 1px rgba(255,255,255,0.3);
	content: attr(data-hover);
	position: absolute;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	-moz-transition: -moz-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
	pointer-events: none;
}

.cl-effect-17 a::after {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background: #fff;
	opacity: 0;
	-webkit-transform: translateY(5px);
	-moz-transform: translateY(5px);
	transform: translateY(5px);
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	-moz-transition: -moz-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
	pointer-events: none;
}

.cl-effect-17 a:hover::before,
.cl-effect-17 a:focus::before {
	opacity: 0;
	-webkit-transform: translateY(-2px);
	-moz-transform: translateY(-2px);
	transform: translateY(-2px);
}

.cl-effect-17 a:hover::after,
.cl-effect-17 a:focus::after {
	opacity: 1;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	transform: translateY(0px);
}

/* Effect 18: cross */
.cl-effect-18 {
	position: relative;
	z-index: 1;
}

.cl-effect-18 a {
	padding: 0 5px;
	color: #db2d2d;
	font-weight: 700;
	-webkit-transition: color 0.3s;
	-moz-transition: color 0.3s;
	transition: color 0.3s;
}

.cl-effect-18 a::before,
.cl-effect-18 a::after {
	position: absolute;
	width: 100%;
	left: 0;
	top: 50%;
	height: 2px;
	margin-top: -1px;
	background: #db2d2d;
	content: '';
	z-index: -1;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	-moz-transition: -moz-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
	pointer-events: none;
}

.cl-effect-18 a::before {
	-webkit-transform: translateY(-20px);
	-moz-transform: translateY(-20px);
	transform: translateY(-20px);
}

.cl-effect-18 a::after {
	-webkit-transform: translateY(20px);
	-moz-transform: translateY(20px);
	transform: translateY(20px);
}

.cl-effect-18 a:hover,
.cl-effect-18 a:focus {
	color: #fff;
}

.cl-effect-18 a:hover::before,
.cl-effect-18 a:hover::after,
.cl-effect-18 a:focus::before,
.cl-effect-18 a:focus::after {
	opacity: 0.7;
}

.cl-effect-18 a:hover::before,
.cl-effect-18 a:focus::before {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	transform: rotate(45deg);
}

.cl-effect-18 a:hover::after,
.cl-effect-18 a:focus::after {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

/* Effect 19: 3D side */
.cl-effect-19 a {
	line-height: 2em;
	margin: 15px;
	-webkit-perspective: 800px;
	-moz-perspective: 800px;
	perspective: 800px;
	width: 200px;
}

.cl-effect-19 a span {
	position: relative;
	display: inline-block;
	width: 100%;
	padding: 0 14px;
	background: #db2d2d;
	-webkit-transition: -webkit-transform 0.4s, background 0.4s;
	-moz-transition: -moz-transform 0.4s, background 0.4s;
	transition: transform 0.4s, background 0.4s;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform-origin: 50% 50% -100px;
	-moz-transform-origin: 50% 50% -100px;
	transform-origin: 50% 50% -100px;
}

.csstransforms3d .cl-effect-19 a span::before {
	position: absolute;
	top: 0;
	left: 100%;
	width: 100%;
	height: 100%;
	background: #b53a2d;
	content: attr(data-hover);
	-webkit-transition: background 0.4s;
	-moz-transition: background 0.4s;
	transition: background 0.4s;
	-webkit-transform: rotateY(90deg);
	-moz-transform: rotateY(90deg);
	transform: rotateY(90deg);
	-webkit-transform-origin: 0 50%;
	-moz-transform-origin: 0 50%;
	transform-origin: 0 50%;
	pointer-events: none;
}

.cl-effect-19 a:hover span,
.cl-effect-19 a:focus span {
	background: #db2d2d;
	-webkit-transform: rotateY(-90deg);
	-moz-transform: rotateY(-90deg);
	transform: rotateY(-90deg);
}

.csstransforms3d .cl-effect-19 a:hover span::before,
.csstransforms3d .cl-effect-19 a:focus span::before {
	background: #db2d2d;	
}

/* Effect 20: 3D side */
.cl-effect-20 a {
	line-height: 2em;
	-webkit-perspective: 800px;
	-moz-perspective: 800px;
	perspective: 800px;
}

.cl-effect-20 a span {
	position: relative;
	display: inline-block;
	padding: 3px 15px 0;
	background: #587285;
	box-shadow: inset 0 3px #db2d2d;
	-webkit-transition: background 0.6s;
	-moz-transition: background 0.6s;
	transition: background 0.6s;
	-webkit-transform-origin: 50% 0;
	-moz-transform-origin: 50% 0;
	transform-origin: 50% 0;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform-origin: 0% 50%;
	-moz-transform-origin: 0% 50%;
	transform-origin: 0% 50%;
}

.cl-effect-20 a span::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	color: #db2d2d;
	content: attr(data-hover);
	-webkit-transform: rotateX(270deg);
	-moz-transform: rotateX(270deg);
	transform: rotateX(270deg);
	-webkit-transition: -webkit-transform 0.6s;
	-moz-transition: -moz-transform 0.6s;
	transition: transform 0.6s;
	-webkit-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	transform-origin: 0 0;
	pointer-events: none;
}

.cl-effect-20 a:hover span,
.cl-effect-20 a:focus span {
	background: #db2d2d;
}

.cl-effect-20 a:hover span::before,
.cl-effect-20 a:focus span::before {
	-webkit-transform: rotateX(10deg);	
	-moz-transform: rotateX(10deg);
	transform: rotateX(10deg);
}

/* Effect 21: borders slight translate */
.cl-effect-21 a {
	padding: 10px;
	color: #db2d2d;
	font-weight: 700;
	text-shadow: none;
	-webkit-transition: color 0.3s;
	-moz-transition: color 0.3s;
	transition: color 0.3s;
}

.cl-effect-21 a::before,
.cl-effect-21 a::after {
	position: absolute;
	left: 0;
	width: 100%;
	height: 2px;
	background: #fff;
	content: '';
	opacity: 0;
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	-moz-transition: opacity 0.3s, -moz-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	transform: translateY(-10px);
}

.cl-effect-21 a::before {
	top: 0;
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	transform: translateY(-10px);
}

.cl-effect-21 a::after {
	bottom: 0;
	-webkit-transform: translateY(10px);
	-moz-transform: translateY(10px);
	transform: translateY(10px);
}

.cl-effect-21 a:hover,
.cl-effect-21 a:focus {
	color: #fff;
}

.cl-effect-21 a:hover::before,
.cl-effect-21 a:focus::before,
.cl-effect-21 a:hover::after,
.cl-effect-21 a:focus::after {
	opacity: 1;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	transform: translateY(0px);
}
