/************************ HEIGHT ****************************/
	.HighAuto{height:auto;}
	.HighInherit{height:inherit;}
	.High0VH{height:0vh;}
	.High1VH{height:1vh;}
	.High2VH{height:2vh;}
	.High3VH{height:3vh;}
	.High4VH{height:4vh;}
	.High5VH{height:5vh;}
	.High6VH{height:6vh;}
	.High7VH{height:7vh;}
	.High8VH{height:8vh;}
	.High9VH{height:9vh;}
	.High10VH{height:10vh;}
	.High11VH{height:11vh;}
	.High12VH{height:12vh;}
	.High13VH{height:13vh;}
	.High14VH{height:14vh;}
	.High15VH{height:15vh;}
	.High20VH{height:20vh;}
	.High25VH{height:25vh;}
	.High30VH{height:30vh;}
	.High35VH{height:35vh;}
	.High40VH{height:40vh;}
	.High45VH{height:45vh;}
	.High50VH{height:50vh;}
	.High55VH{height:55vh;}
	.High60VH{height:60vh;}
	.High65VH{height:65vh;}
	.High70VH{height:70vh;}
	.High75VH{height:75vh;}
	.High76VH{height:76vh;}
	.High77VH{height:77vh;}
	.High78VH{height:78vh;}
	.High79VH{height:79vh;}
	.High80VH{height:80vh;}
	.High85VH{height:85vh;}
	.High90VH{height:90vh;}
	.High91VH{height:91vh;}
	.High92VH{height:92vh;}
	.High93VH{height:93vh;}
	.High94VH{height:94vh;}
	.High95VH{height:95vh;}
	.High96VH{height:96vh;}
	.High97VH{height:97vh;}
	.High98VH{height:98vh;}
	.High99VH{height:99vh;}
	.High100VH{height:100vh;}
	.High110VH{height:110vh;}
	.High120VH{height:120vh;}
	.High130VH{height:130vh;}
	.High140VH{height:140vh;}
	.High150VH{height:150vh;}
	.High175VH{height:1750vh;}
	.High200VH{height:200vh;}
	.High250VH{height:250vh;}
	.High300VH{height:300vh;}
	.High400VH{height:400vh;}
	.High500VH{height:500vh;}
	.MinHigh0VH{min-height:0vh;}
	.MinHighAuto{min-height:auto;}
	.MinHighInherit{min-height:inherit;}
	.MinHigh0VH{min-height:0vh;}
	.MinHigh1VH{min-height:1vh;}
	.MinHigh2VH{min-height:2vh;}
	.MinHigh3VH{min-height:3vh;}
	.MinHigh4VH{min-height:4vh;}
	.MinHigh5VH{min-height:5vh;}
	.MinHigh6VH{min-height:6vh;}
	.MinHigh7VH{min-height:7vh;}
	.MinHigh8VH{min-height:8vh;}
	.MinHigh9VH{min-height:9vh;}
	.MinHigh10VH{min-height:10vh;}
	.MinHigh11VH{min-height:11vh;}
	.MinHigh12VH{min-height:12vh;}
	.MinHigh13VH{min-height:13vh;}
	.MinHigh14VH{min-height:14vh;}
	.MinHigh15VH{min-height:15vh;}
	.MinHigh20VH{min-height:20vh;}
	.MinHigh25VH{min-height:25vh;}
	.MinHigh30VH{min-height:30vh;}
	.MinHigh35VH{min-height:35vh;}
	.MinHigh40VH{min-height:40vh;}
	.MinHigh45VH{min-height:45vh;}
	.MinHigh50VH{min-height:50vh;}
	.MinHigh55VH{min-height:55vh;}
	.MinHigh60VH{min-height:60vh;}
	.MinHigh65VH{min-height:65vh;}
	.MinHigh70VH{min-height:70vh;}
	.MinHigh75VH{min-height:75vh;}
	.MinHigh76VH{min-height:76vh;}
	.MinHigh77VH{min-height:77vh;}
	.MinHigh78VH{min-height:78vh;}
	.MinHigh79VH{min-height:79vh;}
	.MinHigh80VH{min-height:80vh;}
	.MinHigh85VH{min-height:85vh;}
	.MinHigh90VH{min-height:90vh;}
	.MinHigh91VH{min-height:91vh;}
	.MinHigh92VH{min-height:92vh;}
	.MinHigh93VH{min-height:93vh;}
	.MinHigh94VH{min-height:94vh;}
	.MinHigh95VH{min-height:95vh;}
	.MinHigh96VH{min-height:96vh;}
	.MinHigh97VH{min-height:97vh;}
	.MinHigh98VH{min-height:98vh;}
	.MinHigh99VH{min-height:99vh;}
	.MinHigh100VH{min-height:100vh;}
	.MinHigh110VH{min-height:110vh;}
	.MinHigh120VH{min-height:120vh;}
	.MinHigh130VH{min-height:130vh;}
	.MinHigh140VH{min-height:140vh;}
	.MinHigh150VH{min-height:150vh;}
	.MinHigh175VH{min-height:1750vh;}
	.MinHigh200VH{min-height:200vh;}
	.MinHigh250VH{min-height:250vh;}
	.MinHigh300VH{min-height:300vh;}
	.MinHigh400VH{min-height:400vh;}
	.MinHigh500VH{min-height:500vh;}
	.MinHigh0VH{height:0vh;}
/************************ HEIGHT ****************************/
	.HighAuto{height:auto;}
	.HighInherit{height:inherit;}
	.High0{height:0%;}
	.High1{height:1%;}
	.High2{height:2%;}
	.High3{height:3%;}
	.High4{height:4%;}
	.High5{height:5%;}
	.High6{height:6%;}
	.High7{height:7%;}
	.High8{height:8%;}
	.High9{height:9%;}
	.High10{height:10%;}
	.High11{height:11%;}
	.High12{height:12%;}
	.High13{height:13%;}
	.High14{height:14%;}
	.High15{height:15%;}
	.High20{height:20%;}
	.High25{height:25%;}
	.High30{height:30%;}
	.High35{height:35%;}
	.High40{height:40%;}
	.High45{height:45%;}
	.High50{height:50%;}
	.High55{height:55%;}
	.High60{height:60%;}
	.High65{height:65%;}
	.High70{height:70%;}
	.High75{height:75%;}
	.High76{height:76%;}
	.High77{height:77%;}
	.High78{height:78%;}
	.High79{height:79%;}
	.High80{height:80%;}
	.High85{height:85%;}
	.High90{height:90%;}
	.High91{height:91%;}
	.High92{height:92%;}
	.High93{height:93%;}
	.High94{height:94%;}
	.High95{height:95%;}
	.High96{height:96%;}
	.High97{height:97%;}
	.High98{height:98%;}
	.High99{height:99%;}
	.High100{height:100%;}
	.High0{height:0px;}
/************************ HEIGHT PX ****************************/
	.High0px{height:0px;}
	.High10px{height:10px;}
	.High15px{height:15px;}
	.High20px{height:20px;}
	.High25px{height:25px;}
	.High26px{height:26px;}
	.High27px{height:27px;}
	.High28px{height:28px;}
	.High29px{height:29px;}
	.High30px{height:30px;}
	.High31px{height:31px;}
	.High32px{height:32px;}
	.High33px{height:33px;}
	.High34px{height:34px;}
	.High35px{height:35px;}
	.High40px{height:40px;}
	.High50px{height:50px;}
	.High60px{height:60px;}
	.High70px{height:70px;}
	.High80px{height:80px;}
	.High90px{height:90px;}
	.High100px{height:100px;}
	.High110px{height:110px;}
	.High120px{height:120px;}
	.High130px{height:130px;}
	.High140px{height:140px;}
	.High150px{height:150px;}
	.High160px{height:160px;}
	.High170px{height:170px;}
	.High175px{height:175px;}
	.High180px{height:180px;}
	.High190px{height:190px;}
	.High200px{height:200px;}
	.High210px{height:210px;}
	.High220px{height:220px;}
	.High230px{height:230px;}
	.High240px{height:240px;}
	.High250px{height:250px;}
	.High260px{height:260px;}
	.High270px{height:270px;}
	.High275px{height:275px;}
	.High280px{height:280px;}
	.High290px{height:290px;}
	.High300px{height:300px;}
	.High301px{height:301px;}
	.High302px{height:302px;}
	.High303px{height:303px;}
	.High310px{height:310px;}
	.High320px{height:320px;}
	.High330px{height:330px;}
	.High340px{height:340px;}
	.High350px{height:350px;}
	.High360px{height:360px;}
	.High370px{height:370px;}
	.High380px{height:380px;}
	.High390px{height:390px;}
	.High400px{height:400px;}
	.High410px{height:410px;}
	.High420px{height:420px;}
	.High430px{height:430px;}
	.High440px{height:440px;}
	.High450px{height:450px;}
	.High500px{height:500px;}
	.High550px{height:550px;}
	.High600px{height:600px;}
	.High650px{height:650px;}
	.High700px{height:700px;}
	.High750px{height:750px;}
	.High800px{height:800px;}
	.High900px{height:900px;}
	.High1000px{height:1000px;}
	.High1100px{height:1100px;}
	.High1200px{height:1200px;}
	.High1250px{height:1250px;}
	.High1300px{height:1300px;}
	.High1400px{height:1400px;}
	.High1500px{height:1500px;}
	.High2000px{height:2000px;}
	.High2500px{height:2500px;}
	.High3000px{height:3000px;}
	.High3500px{height:3500px;}
	.High4000px{height:4000px;}
	.High5000px{height:5000px;}
	/************************ MAX WIDTH ****************************/
	.MaxHigh0{max-height:0%;}
	.MaxHigh5{max-height:5%;}
	.MaxHigh6{max-height:6%;}
	.MaxHigh7{max-height:7%;}
	.MaxHigh8{max-height:8%;}
	.MaxHigh10{max-height:10%;}
	.MaxHigh15{max-height:15%;}
	.MaxHigh20{max-height:20%;}
	.MaxHigh25{max-height:25%;}
	.MaxHigh30{max-height:30%;}
	.MaxHigh40{max-height:40%;}
	.MaxHigh50{max-height:50%;}
	.MaxMaxHigh60{max-height:60%;}
	.MaxHigh70{max-height:70%;}
	.MaxHigh75{max-height:75%;}
	.MaxHigh77{max-height:77%;}
	.MaxHigh80{max-height:80%;}
	.MaxHigh85{max-height:85%;}
	.MaxHigh90{max-height:90%;}
	.MaxHigh91{max-height:91%;}
	.MaxHigh92{max-height:92%;}
	.MaxHigh93{max-height:93%;}
	.MaxHigh94{max-height:94%;}
	.MaxHigh95{max-height:95%;}
	.MaxHigh96{max-height:96%;}
	.MaxHigh97{max-height:97%;}
	.MaxHigh98{max-height:98%;}
	.MaxHigh99{max-height:99%;}
	.MaxHigh100{max-height:100%;}
	.MaxHigh0{max-height:0px;}
/************************ MAX WIDTH PX ****************************/
	.MaxHigh30px{max-height:30px;}
	.MaxHigh40px{max-height:40px;}
	.MaxHigh50px{max-height:50px;}
	.MaxHigh60px{max-height:60px;}
	.MaxHigh70px{max-height:70px;}
	.MaxHigh80px{max-height:80px;}
	.MaxHigh90px{max-height:90px;}
	.MaxHigh100px{max-height:100px;}
	.MaxHigh110px{max-height:110px;}
	.MaxHigh120px{max-height:120px;}
	.MaxHigh130px{max-height:130px;}
	.MaxHigh140px{max-height:140px;}
	.MaxHigh150px{max-height:150px;}
	.MaxHigh160px{max-height:160px;}
	.MaxHigh170px{max-height:170px;}
	.MaxHigh180px{max-height:180px;}
	.MaxHigh190px{max-height:190px;}
	.MaxHigh200px{max-height:200px;}
	.MaxHigh210px{max-height:210px;}
	.MaxHigh220px{max-height:220px;}
	.MaxHigh230px{max-height:230px;}
	.MaxHigh240px{max-height:240px;}
	.MaxHigh250px{max-height:250px;}
	.MaxHigh260px{max-height:260px;}
	.MaxHigh270px{max-height:270px;}
	.MaxHigh280px{max-height:280px;}
	.MaxHigh290px{max-height:290px;}
	.MaxHigh300px{max-height:300px;}
	.MaxHigh350px{max-height:350px;}
	.MaxHigh400px{max-height:400px;}
	.MaxHigh450px{max-height:450px;}
	.MaxHigh500px{max-height:500px;}
	.MaxHigh550px{max-height:550px;}
	.MaxHigh600px{max-height:600px;}
	.MaxHigh650px{max-height:650px;}
	.MaxHigh700px{max-height:700px;}
	.MaxHigh750px{max-height:750px;}
	.MaxHigh800px{max-height:800px;}
	.MaxHigh900px{max-height:900px;}
	.MaxHigh1000px{max-height:1000px;}
	.MaxHigh1100px{max-height:1100px;}
	.MaxHigh1200px{max-height:1200px;}
	.MaxHigh1250px{max-height:1250px;}
	.MaxHigh1300px{max-height:1300px;}
	.MaxHigh1400px{max-height:1400px;}
	.MaxHigh1500px{max-height:1500px;}
	.MaxHigh1600px{max-height:1600px;}
	.MaxHigh1700px{max-height:1700px;}
	.MaxHigh1750px{max-height:1750px;}
	.MaxHigh1800px{max-height:1800px;}
	.MaxHigh1900px{max-height:1900px;}
	.MaxHigh2000px{max-height:2000px;}
	/************************ MIN HEIGTH ****************************/
	.MinHigh0{min-height:0%;}
	.MinHigh5{min-height:5%;}
	.MinHigh6{min-height:6%;}
	.MinHigh7{min-height:7%;}
	.MinHigh8{min-height:8%;}
	.MinHigh10{min-height:10%;}
	.MinHigh15{min-height:15%;}
	.MinHigh20{min-height:20%;}
	.MinHigh25{min-height:25%;}
	.MinHigh30{min-height:30%;}
	.MinHigh40{min-height:40%;}
	.MinHigh50{min-height:50%;}
	.MinHigh60{min-height:60%;}
	.MinHigh70{min-height:70%;}
	.MinHigh75{min-height:75%;}
	.MinHigh77{min-height:77%;}
	.MinHigh80{min-height:80%;}
	.MinHigh85{min-height:85%;}
	.MinHigh90{min-height:90%;}
	.MinHigh91{min-height:91%;}
	.MinHigh92{min-height:92%;}
	.MinHigh93{min-height:93%;}
	.MinHigh94{min-height:94%;}
	.MinHigh95{min-height:95%;}
	.MinHigh96{min-height:96%;}
	.MinHigh97{min-height:97%;}
	.MinHigh98{min-height:98%;}
	.MinHigh99{min-height:99%;}
	.MinHigh100{min-height:100%;}
	.MinHigh0{min-height:0px;}
	
	.MinHigh10px{min-height:10px;}
	.MinHigh20px{min-height:20px;}
	.MinHigh25px{min-height:25px;}
	.MinHigh30px{min-height:30px;}
	.MinHigh40px{min-height:40px;}
	.MinHigh50px{min-height:50px;}
	.MinHigh60px{min-height:60px;}
	.MinHigh75px{min-height:75px;}
	.MinHigh100px{min-height:100px;}
	.MinHigh110px{min-height:110px;}
	.MinHigh120px{min-height:120px;}
	.MinHigh130px{min-height:130px;}
	.MinHigh140px{min-height:140px;}
	.MinHigh150px{min-height:150px;}
	.MinHigh160px{min-height:160px;}
	.MinHigh170px{min-height:170px;}
	.MinHigh180px{min-height:180px;}
	.MinHigh190px{min-height:190px;}
	.MinHigh200px{min-height:200px;}
	.MinHigh250px{min-height:250px;}
	.MinHigh300px{min-height:300px;}
	.MinHigh350px{min-height:350px;}
	.MinHigh400px{min-height:400px;}
	.MinHigh450px{min-height:450px;}
	.MinHigh500px{min-height:500px;}
	.MinHigh550px{min-height:550px;}
	.MinHigh600px{min-height:600px;}
	.MinHigh650px{min-height:650px;}
	.MinHigh700px{min-height:700px;}
	.MinHigh750px{min-height:750px;}
	.MinHigh800px{min-height:800px;}
	.MinHigh850px{min-height:850px;}
	.MinHigh900px{min-height:900px;}
	.MinHigh950px{min-height:950px;}
	.MinHigh1000px{min-height:1000px;}
	.MinHigh1100px{min-height:1100px;}
	.MinHigh1200px{min-height:1200px;}
	.MinHigh1250px{min-height:1250px;}
	.MinHigh1300px{min-height:1300px;}
	.MinHigh1400px{min-height:1400px;}
	.MinHigh1500px{min-height:1500px;}
	.MinHigh1600px{min-height:1600px;}
	.MinHigh1700px{min-height:1700px;}
	.MinHigh1750px{min-height:1750px;}
	.MinHigh1800px{min-height:1800px;}
	.MinHigh1900px{min-height:1900px;}
	.MinHigh2000px{min-height:2000px;}