/***********************  PADDING  *****************************/
	.PaddingAuto{padding:auto !important ;}
	.TopPaddingAuto{padding-top:auto !important ;}
	.LeftPaddingAuto{padding-left:auto !important ;}
	.BottomPaddingAuto{padding-bottom:auto !important ;}
	.RightPaddingAuto{padding-right:auto !important ;}
	.HPadding90{padding-right:auto;padding-left:auto !important ;}
	.VPadding90{padding-top:auto;padding-bottom:auto !important ;}

	.Padding0{padding:0vw !important ;}
	.TopPadding0{padding-top:0vw !important ;}
	.LeftPadding0{padding-left:0vw !important ;}
	.BottomPadding0{padding-bottom:0vw !important ;}
	.RightPadding0{padding-right:0vw !important ;}
	.HPadding0{padding-right:0vw;padding-left:0vw !important ;}
	.VPadding0{padding-top:0vw;padding-bottom:0vw !important ;}
	
	.Padding0px{padding:0px !important ;}
	.TopPadding0px{padding-top:0px !important ;}
	.LeftPadding0px{padding-left:0px !important ;}
	.BottomPadding0px{padding-bottom:0px !important ;}
	.RightPadding0px{padding-right:0px !important ;}
	.HPadding0px{padding-right:0px;padding-left:0px !important ;}
	.VPadding0px{padding-top:0px;padding-bottom:0px !important ;}

	.Padding1{padding:1vw !important ;}
	.TopPadding1{padding-top:1vw !important ;}
	.LeftPadding1{padding-left:1vw !important ;}
	.BottomPadding1{padding-bottom:1vw !important ;}
	.RightPadding1{padding-right:1vw !important ;}
	.HPadding1{padding-right:1vw;padding-left:1vw !important ;}
	.VPadding1{padding-top:1vw;padding-bottom:1vw !important ;}
	
	.Padding1px{padding:1px !important ;}
	.TopPadding1px{padding-top:1px !important ;}
	.LeftPadding1px{padding-left:1px !important ;}
	.BottomPadding1px{padding-bottom:1px !important ;}
	.RightPadding1px{padding-right:1px !important ;}
	.HPadding1px{padding-right:1px;padding-left:1px !important ;}
	.VPadding1px{padding-top:1px;padding-bottom:1px !important ;}

	.Padding2{padding:2vw !important ;}
	.TopPadding2{padding-top:2vw !important ;}
	.LeftPadding2{padding-left:2vw !important ;}
	.BottomPadding2{padding-bottom:2vw !important ;}
	.RightPadding2{padding-right:2vw !important ;}
	.HPadding2{padding-right:2vw;padding-left:2vw !important ;}
	.VPadding2{padding-top:2vw;padding-bottom:2vw !important ;}
	
	.Padding2px{padding:2px !important ;}
	.TopPadding2px{padding-top:2px !important ;}
	.LeftPadding2px{padding-left:2px !important ;}
	.BottomPadding2px{padding-bottom:2px !important ;}
	.RightPadding2px{padding-right:2px !important ;}
	.HPadding2px{padding-right:2px;padding-left:2px !important ;}
	.VPadding2px{padding-top:2px;padding-bottom:2px !important ;}

	.Padding3{padding:3vw !important ;}
	.TopPadding3{padding-top:3vw !important ;}
	.LeftPadding3{padding-left:3vw !important ;}
	.BottomPadding3{padding-bottom:3vw !important ;}
	.RightPadding3{padding-right:3vw !important ;}
	.HPadding3{padding-right:3vw;padding-left:3vw !important ;}
	.VPadding3{padding-top:3vw;padding-bottom:3vw !important ;}
	
	.Padding3px{padding:3px !important ;}
	.TopPadding3px{padding-top:3px !important ;}
	.LeftPadding3px{padding-left:3px !important ;}
	.BottomPadding3px{padding-bottom:3px !important ;}
	.RightPadding3px{padding-right:3px !important ;}
	.HPadding3px{padding-right:3px;padding-left:3px !important ;}
	.VPadding3px{padding-top:3px;padding-bottom:3px !important ;}

	.Padding4{padding:4vw !important ;}
	.TopPadding4{padding-top:4vw !important ;}
	.LeftPadding4{padding-left:4vw !important ;}
	.BottomPadding4{padding-bottom:4vw !important ;}
	.RightPadding4{padding-right:4vw !important ;}
	.HPadding4{padding-right:4vw;padding-left:4vw !important ;}
	.VPadding4{padding-top:4vw;padding-bottom:4vw !important ;}
	
	.Padding4px{padding:4px !important ;}
	.TopPadding4px{padding-top:4px !important ;}
	.LeftPadding4px{padding-left:4px !important ;}
	.BottomPadding4px{padding-bottom:4px !important ;}
	.RightPadding4px{padding-right:4px !important ;}
	.HPadding4px{padding-right:4px;padding-left:4px !important ;}
	.VPadding4px{padding-top:4px;padding-bottom:4px !important ;}
	
	.Padding5{padding:5vw !important ;}	
	.TopPadding5{padding-top:5vw !important ;}
	.LeftPadding5{padding-left:5vw !important ;}
	.BottomPadding5{padding-bottom:5vw !important ;}
	.RightPadding5{padding-right:5vw !important ;}
	.HPadding5{padding-right:5vw;padding-left:5vw !important ;}
	.VPadding5{padding-top:5vw;padding-bottom:5vw !important ;}
	
	.Padding5px{padding:5px !important ;}
	.TopPadding5px{padding-top:5px !important ;}
	.LeftPadding5px{padding-left:5px !important ;}
	.BottomPadding5px{padding-bottom:5px !important ;}
	.RightPadding5px{padding-right:5px !important ;}
	.HPadding5px{padding-right:5px;padding-left:5px !important ;}
	.VPadding5px{padding-top:5px;padding-bottom:5px !important ;}
	
	.Padding6{padding:6vw !important ;}	
	.TopPadding6{padding-top:6vw !important ;}
	.LeftPadding6{padding-left:6vw !important ;}
	.BottomPadding6{padding-bottom:6vw !important ;}
	.RightPadding6{padding-right:6vw !important ;}
	.HPadding6{padding-right:6vw;padding-left:6vw !important ;}
	.VPadding6{padding-top:6vw;padding-bottom:6vw !important ;}
	
	.Padding6px{padding:6px !important ;}
	.TopPadding6px{padding-top:6px !important ;}
	.LeftPadding6px{padding-left:6px !important ;}
	.BottomPadding6px{padding-bottom:6px !important ;}
	.RightPadding6px{padding-right:6px !important ;}
	.HPadding6px{padding-right:6px;padding-left:6px !important ;}
	.VPadding6px{padding-top:6px;padding-bottom:6px !important ;}
	
	.Padding7{padding:7vw !important ;}	
	.TopPadding7{padding-top:7vw !important ;}
	.LeftPadding7{padding-left:7vw !important ;}
	.BottomPadding7{padding-bottom:7vw !important ;}
	.RightPadding7{padding-right:7vw !important ;}
	.HPadding7{padding-right:7vw;padding-left:7vw !important ;}
	.VPadding7{padding-top:7vw;padding-bottom:7vw !important ;}
	
	.Padding7px{padding:7px !important ;}
	.TopPadding7px{padding-top:7px !important ;}
	.LeftPadding7px{padding-left:7px !important ;}
	.BottomPadding7px{padding-bottom:7px !important ;}
	.RightPadding7px{padding-right:7px !important ;}
	.HPadding7px{padding-right:7px;padding-left:7px !important ;}
	.VPadding7px{padding-top:7px;padding-bottom:7px !important ;}
	
	.Padding8{padding:8vw !important ;}	
	.TopPadding8{padding-top:8vw !important ;}
	.LeftPadding8{padding-left:8vw !important ;}
	.BottomPadding8{padding-bottom:8vw !important ;}
	.RightPadding8{padding-right:8vw !important ;}
	.HPadding8{padding-right:8vw;padding-left:8vw !important ;}
	.VPadding8{padding-top:8vw;padding-bottom:8vw !important ;}
	
	.Padding8px{padding:8px !important ;}
	.TopPadding8px{padding-top:8px !important ;}
	.LeftPadding8px{padding-left:8px !important ;}
	.BottomPadding8px{padding-bottom:8px !important ;}
	.RightPadding8px{padding-right:8px !important ;}
	.HPadding8px{padding-right:8px;padding-left:8px !important ;}
	.VPadding8px{padding-top:8px;padding-bottom:8px !important ;}
	
	.Padding9{padding:9vw !important ;}	
	.TopPadding9{padding-top:9vw !important ;}
	.LeftPadding9{padding-left:9vw !important ;}
	.BottomPadding9{padding-bottom:9vw !important ;}
	.RightPadding9{padding-right:9vw !important ;}
	.HPadding9{padding-right:9vw;padding-left:9vw !important ;}
	.VPadding9{padding-top:9vw;padding-bottom:9vw !important ;}
	
	.Padding9px{padding:9px !important ;}
	.TopPadding9px{padding-top:9px !important ;}
	.LeftPadding9px{padding-left:9px !important ;}
	.BottomPadding9px{padding-bottom:9px !important ;}
	.RightPadding9px{padding-right:9px !important ;}
	.HPadding9px{padding-right:9px;padding-left:9px !important ;}
	.VPadding9px{padding-top:9px;padding-bottom:9px !important ;}

	.Padding10{padding:10vw !important ;}
	.TopPadding10{padding-top:10vw !important ;}
	.LeftPadding10{padding-left:10vw !important ;}
	.BottomPadding10{padding-bottom:10vw !important ;}
	.RightPadding10{padding-right:10vw !important ;}
	.HPadding10{padding-right:10vw;padding-left:10vw !important ;}
	.VPadding10{padding-top:10vw;padding-bottom:10vw !important ;}
	
	.Padding10px{padding:10px !important ;}
	.TopPadding10px{padding-top:10px !important ;}
	.LeftPadding10px{padding-left:10px !important ;}
	.BottomPadding10px{padding-bottom:10px !important ;}
	.RightPadding10px{padding-right:10px !important ;}
	.HPadding10px{padding-right:10px;padding-left:10px !important ;}
	.VPadding10px{padding-top:10px;padding-bottom:10px !important ;}

	.Padding11{padding:11vw !important ;}
	.TopPadding11{padding-top:11vw !important ;}
	.LeftPadding11{padding-left:11vw !important ;}
	.BottomPadding11{padding-bottom:11vw !important ;}
	.RightPadding11{padding-right:11vw !important ;}
	.HPadding11{padding-right:11vw;padding-left:11vw !important ;}
	.VPadding11{padding-top:11vw;padding-bottom:11vw !important ;}
	
	.Padding11px{padding:11px !important ;}
	.TopPadding11px{padding-top:11px !important ;}
	.LeftPadding11px{padding-left:11px !important ;}
	.BottomPadding11px{padding-bottom:11px !important ;}
	.RightPadding11px{padding-right:11px !important ;}
	.HPadding11px{padding-right:11px;padding-left:11px !important ;}
	.VPadding11px{padding-top:11px;padding-bottom:11px !important ;}

	.Padding12{padding:12vw !important ;}
	.TopPadding12{padding-top:12vw !important ;}
	.LeftPadding12{padding-left:12vw !important ;}
	.BottomPadding12{padding-bottom:12vw !important ;}
	.RightPadding12{padding-right:12vw !important ;}
	.HPadding12{padding-right:12vw;padding-left:12vw !important ;}
	.VPadding12{padding-top:12vw;padding-bottom:12vw !important ;}
	
	.Padding12px{padding:12px !important ;}
	.TopPadding12px{padding-top:12px !important ;}
	.LeftPadding12px{padding-left:12px !important ;}
	.BottomPadding12px{padding-bottom:12px !important ;}
	.RightPadding12px{padding-right:12px !important ;}
	.HPadding12px{padding-right:12px;padding-left:12px !important ;}
	.VPadding12px{padding-top:12px;padding-bottom:12px !important ;}

	.Padding13{padding:13vw !important ;}
	.TopPadding13{padding-top:13vw !important ;}
	.LeftPadding13{padding-left:13vw !important ;}
	.BottomPadding13{padding-bottom:13vw !important ;}
	.RightPadding13{padding-right:13vw !important ;}
	.HPadding13{padding-right:13vw;padding-left:13vw !important ;}
	.VPadding13{padding-top:13vw;padding-bottom:13vw !important ;}
	
	.Padding13px{padding:13px !important ;}
	.TopPadding13px{padding-top:13px !important ;}
	.LeftPadding13px{padding-left:13px !important ;}
	.BottomPadding13px{padding-bottom:13px !important ;}
	.RightPadding13px{padding-right:13px !important ;}
	.HPadding13px{padding-right:13px;padding-left:13px !important ;}
	.VPadding13px{padding-top:13px;padding-bottom:13px !important ;}

	.Padding14{padding:14vw !important ;}
	.TopPadding14{padding-top:14vw !important ;}
	.LeftPadding14{padding-left:14vw !important ;}
	.BottomPadding14{padding-bottom:14vw !important ;}
	.RightPadding14{padding-right:14vw !important ;}
	.HPadding14{padding-right:14vw;padding-left:14vw !important ;}
	.VPadding14{padding-top:14vw;padding-bottom:14vw !important ;}
	
	.Padding14px{padding:14px !important ;}
	.TopPadding14px{padding-top:14px !important ;}
	.LeftPadding14px{padding-left:14px !important ;}
	.BottomPadding14px{padding-bottom:14px !important ;}
	.RightPadding14px{padding-right:14px !important ;}
	.HPadding14px{padding-right:14px;padding-left:14px !important ;}
	.VPadding14px{padding-top:14px;padding-bottom:14px !important ;}

	.Padding15{padding:15vw !important ;}
	.TopPadding15{padding-top:15vw !important ;}
	.LeftPadding15{padding-left:15vw !important ;}
	.BottomPadding15{padding-bottom:15vw !important ;}
	.RightPadding15{padding-right:15vw !important ;}
	.HPadding15{padding-right:15vw;padding-left:15vw !important ;}
	.VPadding15{padding-top:15vw;padding-bottom:15vw !important ;}
	
	.Padding15px{padding:15px !important ;}
	.TopPadding15px{padding-top:15px !important ;}
	.LeftPadding15px{padding-left:15px !important ;}
	.BottomPadding15px{padding-bottom:15px !important ;}
	.RightPadding15px{padding-right:15px !important ;}
	.HPadding15px{padding-right:15px;padding-left:15px !important ;}
	.VPadding15px{padding-top:15px;padding-bottom:15px !important ;}

	.Padding20{padding:20vw !important ;}
	.TopPadding20{padding-top:20vw !important ;}
	.LeftPadding20{padding-left:20vw !important ;}
	.BottomPadding20{padding-bottom:20vw !important ;}
	.RightPadding20{padding-right:20vw !important ;}
	.HPadding20{padding-right:20vw;padding-left:20vw !important ;}
	.VPadding20{padding-top:20vw;padding-bottom:20vw !important ;}

	.Padding20px{padding:20px !important ;}
	.TopPadding20px{padding-top:20px !important ;}
	.LeftPadding20px{padding-left:20px !important ;}
	.BottomPadding20px{padding-bottom:20px !important ;}
	.RightPadding20px{padding-right:20px !important ;}
	.HPadding20px{padding-right:20px;padding-left:20px !important ;}
	.VPadding20px{padding-top:20px;padding-bottom:20px !important ;}

	.Padding30{padding:30vw !important ;}
	.TopPadding30{padding-top:30vw !important ;}
	.LeftPadding30{padding-left:30vw !important ;}
	.BottomPadding30{padding-bottom:30vw !important ;}
	.RightPadding30{padding-right:30vw !important ;}
	.HPadding30{padding-right:30vw;padding-left:30vw !important ;}
	.VPadding30{padding-top:30vw;padding-bottom:30vw !important ;}

	.Padding30px{padding:30px !important ;}
	.TopPadding30px{padding-top:30px !important ;}
	.LeftPadding30px{padding-left:30px !important ;}
	.BottomPadding30px{padding-bottom:30px !important ;}
	.RightPadding30px{padding-right:30px !important ;}
	.HPadding30px{padding-right:30px;padding-left:30px !important ;}
	.VPadding30px{padding-top:30px;padding-bottom:30px !important ;}

	.Padding40{padding:40vw !important ;}
	.TopPadding40{padding-top:40vw !important ;}
	.LeftPadding40{padding-left:40vw !important ;}
	.BottomPadding40{padding-bottom:40vw !important ;}
	.RightPadding40{padding-right:40vw !important ;}
	.HPadding40{padding-right:40vw;padding-left:40vw !important ;}
	.VPadding40{padding-top:40vw;padding-bottom:40vw !important ;}

	.Padding40px{padding:40px !important ;}
	.TopPadding40px{padding-top:40px !important ;}
	.LeftPadding40px{padding-left:40px !important ;}
	.BottomPadding40px{padding-bottom:40px !important ;}
	.RightPadding40px{padding-right:40px !important ;}
	.HPadding40px{padding-right:40px;padding-left:40px !important ;}
	.VPadding40px{padding-top:40px;padding-bottom:40px !important ;}

	.Padding50px{padding:50px !important ;}
	.TopPadding50px{padding-top:50px !important ;}
	.LeftPadding50px{padding-left:50px !important ;}
	.BottomPadding50px{padding-bottom:50px !important ;}
	.RightPadding50px{padding-right:50px !important ;}
	.HPadding50px{padding-right:50px;padding-left:50px !important ;}
	.VPadding50px{padding-top:50px;padding-bottom:50px !important ;}

	.Padding50{padding:50vw !important ;}
	.TopPadding50{padding-top:50vw !important ;}
	.LeftPadding50{padding-left:50vw !important ;}
	.BottomPadding50{padding-bottom:50vw !important ;}
	.RightPadding50{padding-right:50vw !important ;}
	.HPadding50{padding-right:50vw;padding-left:50vw !important ;}
	.VPadding50{padding-top:50vw;padding-bottom:50vw !important ;}

	.Padding60px{padding:60px !important ;}
	.TopPadding60px{padding-top:60px !important ;}
	.LeftPadding60px{padding-left:60px !important ;}
	.BottomPadding60px{padding-bottom:60px !important ;}
	.RightPadding60px{padding-right:60px !important ;}
	.HPadding60px{padding-right:60px;padding-left:60px !important ;}
	.VPadding60px{padding-top:60px;padding-bottom:60px !important ;}

	.Padding60{padding:60vw !important ;}
	.TopPadding60{padding-top:60vw !important ;}
	.LeftPadding60{padding-left:60vw !important ;}
	.BottomPadding60{padding-bottom:60vw !important ;}
	.RightPadding60{padding-right:60vw !important ;}
	.HPadding60{padding-right:60vw;padding-left:60vw !important ;}
	.VPadding60{padding-top:60vw;padding-bottom:60vw !important ;}
	

	.Padding70px{padding:70px !important ;}
	.TopPadding70px{padding-top:70px !important ;}
	.LeftPadding70px{padding-left:70px !important ;}
	.BottomPadding70px{padding-bottom:70px !important ;}
	.RightPadding70px{padding-right:70px !important ;}
	.HPadding70px{padding-right:70px;padding-left:70px !important ;}
	.VPadding70px{padding-top:70px;padding-bottom:70px !important ;}

	.Padding70{padding:70vw !important ;}
	.TopPadding70{padding-top:70vw !important ;}
	.LeftPadding70{padding-left:70vw !important ;}
	.BottomPadding70{padding-bottom:70vw !important ;}
	.RightPadding70{padding-right:70vw !important ;}
	.HPadding70{padding-right:70vw;padding-left:70vw !important ;}
	.VPadding70{padding-top:70vw;padding-bottom:70vw !important ;}


	.Padding80px{padding:80px !important ;}
	.TopPadding80px{padding-top:80px !important ;}
	.LeftPadding80px{padding-left:80px !important ;}
	.BottomPadding80px{padding-bottom:80px !important ;}
	.RightPadding80px{padding-right:80px !important ;}
	.HPadding80px{padding-right:80px;padding-left:80px !important ;}
	.VPadding80px{padding-top:80px;padding-bottom:80px !important ;}

	.Padding80{padding:80vw !important ;}
	.TopPadding80{padding-top:80vw !important ;}
	.LeftPadding80{padding-left:80vw !important ;}
	.BottomPadding80{padding-bottom:80vw !important ;}
	.RightPadding80{padding-right:80vw !important ;}
	.HPadding80{padding-right:80vw;padding-left:80vw !important ;}
	.VPadding80{padding-top:80vw;padding-bottom:80vw !important ;}

	
	.Padding90px{padding:90px !important ;}
	.TopPadding90px{padding-top:90px !important ;}
	.LeftPadding90px{padding-left:90px !important ;}
	.BottomPadding90px{padding-bottom:90px !important ;}
	.RightPadding90px{padding-right:90px !important ;}
	.HPadding90px{padding-right:90px;padding-left:90px !important ;}
	.VPadding90px{padding-top:90px;padding-bottom:90px !important ;}

	.Padding90{padding:90vw !important ;}
	.TopPadding90{padding-top:90vw !important ;}
	.LeftPadding90{padding-left:90vw !important ;}
	.BottomPadding90{padding-bottom:90vw !important ;}
	.RightPadding90{padding-right:90vw !important ;}
	.HPadding90{padding-right:90vw;padding-left:90vw !important ;}
	.VPadding90{padding-top:90vw;padding-bottom:90vw !important ;}

	.Padding100px{padding:100px !important ;}
	.TopPadding100px{padding-top:100px !important ;}
	.LeftPadding100px{padding-left:100px !important ;}
	.BottomPadding100px{padding-bottom:100px !important ;}
	.RightPadding100px{padding-right:100px !important ;}
	.HPadding100px{padding-right:100px;padding-left:100px !important ;}
	.VPadding100px{padding-top:100px;padding-bottom:100px !important ;}

	.Padding100{padding:100vw !important ;}
	.TopPadding100{padding-top:100vw !important ;}
	.LeftPadding100{padding-left:100vw !important ;} 
	.BottomPadding100{padding-bottom:100vw !important ;}
	.RightPadding100{padding-right:100vw !important ;}
	.HPadding100{padding-right:100vw;padding-left:100vw !important ;}
	.VPadding100{padding-top:100vw;padding-bottom:100vw !important ;}

	.Padding200px{padding:200px !important ;}
	.TopPadding200px{padding-top:200px !important ;} 
	.LeftPadding200px{padding-left:200px !important ;}
	.BottomPadding200px{padding-bottom:200px !important ;}
	.RightPadding200px{padding-right:200px !important ;}
	.HPadding200px{padding-right:200px;padding-left:200px !important ;}
	.VPadding200px{padding-top:200px;padding-bottom:200px !important ;}


@media screen and (max-width: 719px) {

	.Padding5M0{padding: 0vw !important ;}
	.PaddingT5M0{padding-top: 0vw !important ;}
	.PaddingR5M0{padding-right: 0vw !important ;}
	.PaddingB5M0{padding-bottom: 0vw !important ;}
	.PaddingL5M0{padding-left: 0vw !important ;}
	.PaddingH5M0{padding-right: 0vw;padding-left: 0vw !important ;}
	.PaddingV5M0{padding-top: 0vw;padding-bottom: 0vw !important ;}


	.Padding10M0{padding: 0vw !important ;}
	.PaddingT10M0{padding-top: 0vw !important ;}
	.PaddingR10M0{padding-right: 0vw !important ;}
	.PaddingB10M0{padding-bottom: 0vw !important ;}
	.PaddingL10M0{padding-left: 0vw !important ;}
	.PaddingH10M0{padding-right: 0vw;padding-left: 0vw !important ;}
	.PaddingV10M0{padding-top: 0vw;padding-bottom: 0vw !important ;}

	.Padding10M5{padding: 5vw !important ;}
	.PaddingT10M5{padding-top: 5vw !important ;}
	.PaddingR10M5{padding-right: 5vw !important ;}
	.PaddingB10M5{padding-bottom: 5vw !important ;}
	.PaddingL10M5{padding-left: 5vw !important ;}
	.PaddingH10M5{padding-right: 5vw;padding-left: 5vw !important ;}
	.PaddingV10M5{padding-top: 5vw;padding-bottom: 5vw !important ;}
	

	.Padding15M0{padding: 0vw !important ;}
	.PaddingT15M0{padding-top: 0vw !important ;}
	.PaddingR15M0{padding-right: 0vw !important ;}
	.PaddingB15M0{padding-bottom: 0vw !important ;}
	.PaddingL15M0{padding-left: 0vw !important ;}
	.PaddingH15M0{padding-right: 0vw;padding-left: 0vw !important ;}
	.PaddingV15M0{padding-top: 0vw;padding-bottom: 0vw !important ;}

	.Padding15M5{padding: 5vw !important ;}
	.PaddingT15M5{padding-top: 5vw !important ;}
	.PaddingR15M5{padding-right: 5vw !important ;}
	.PaddingB15M5{padding-bottom: 5vw !important ;}
	.PaddingL15M5{padding-left: 5vw !important ;}
	.PaddingH15M5{padding-right: 5vw;padding-left: 5vw !important ;}
	.PaddingV15M5{padding-top: 5vw;padding-bottom: 5vw !important ;}

	.Padding15M10{padding: 10vw !important ;}
	.PaddingT15M10{padding-top: 10vw !important ;}
	.PaddingR15M10{padding-right: 10vw !important ;}
	.PaddingB15M10{padding-bottom: 10vw !important ;}
	.PaddingL15M10{padding-left: 10vw !important ;}
	.PaddingH15M10{padding-right: 10vw;padding-left: 10vw !important ;}
	.PaddingV15M10{padding-top: 10vw;padding-bottom: 10vw !important ;}


	.Padding20M0{padding: 0vw !important ;}
	.PaddingT20M0{padding-top: 0vw !important ;}
	.PaddingR20M0{padding-right: 0vw !important ;}
	.PaddingB20M0{padding-bottom: 0vw !important ;}
	.PaddingL20M0{padding-left: 0vw !important ;}
	.PaddingH20M0{padding-right: 0vw;padding-left: 0vw !important ;}
	.PaddingV20M0{padding-top: 0vw;padding-bottom: 0vw !important ;}

	.Padding20M5{padding: 5vw !important ;}
	.PaddingT20M5{padding-top: 5vw !important ;}
	.PaddingR20M5{padding-right: 5vw !important ;}
	.PaddingB20M5{padding-bottom: 5vw !important ;}
	.PaddingL20M5{padding-left: 5vw !important ;}
	.PaddingH20M5{padding-right: 5vw;padding-left: 5vw !important ;}
	.PaddingV20M5{padding-top: 5vw;padding-bottom: 5vw !important ;}

	.Padding20M10{padding: 10vw !important ;}
	.PaddingT20M10{padding-top: 10vw !important ;}
	.PaddingR20M10{padding-right: 10vw !important ;}
	.PaddingB20M10{padding-bottom: 10vw !important ;}
	.PaddingL20M10{padding-left: 10vw !important ;}
	.PaddingH20M10{padding-right: 10vw;padding-left: 10vw !important ;}
	.PaddingV20M10{padding-top: 10vw;padding-bottom: 10vw !important ;}
	
	.Padding20M15{padding: 15vw !important ;}
	.PaddingT20M15{padding-top: 15vw !important ;}
	.PaddingR20M15{padding-right: 15vw !important ;}
	.PaddingB20M15{padding-bottom: 15vw !important ;}
	.PaddingL20M15{padding-left: 15vw !important ;}
	.PaddingH20M15{padding-right: 15vw;padding-left: 15vw !important ;}
	.PaddingV20M15{padding-top: 15vw;padding-bottom: 15vw !important ;}
}


@media screen and (min-width: 720px) {
	.Padding5M0{padding: 5vw !important ;}
	.PaddingT5M0{padding-top: 5vw !important ;}
	.PaddingR5M0{padding-right: 5vw !important ;}
	.PaddingB5M0{padding-bottom: 5vw !important ;}
	.PaddingL5M0{padding-left: 5vw !important ;}
	.PaddingH5M0{padding-right: 5vw;padding-left: 5vw !important ;}
	.PaddingV5M0{padding-top: 5vw;padding-bottom: 5vw !important ;}


	.Padding10M0{padding: 10vw !important ;}
	.PaddingT10M0{padding-top: 10vw !important ;}
	.PaddingR10M0{padding-right: 10vw !important ;}
	.PaddingB10M0{padding-bottom: 10vw !important ;}
	.PaddingL10M0{padding-left: 10vw !important ;}
	.PaddingH10M0{padding-right: 10vw;padding-left: 10vw !important ;}
	.PaddingV10M0{padding-top: 10vw;padding-bottom: 10vw !important ;}

	.Padding10M5{padding: 10vw !important ;}
	.PaddingT10M5{padding-top: 10vw !important ;}
	.PaddingR10M5{padding-right: 10vw !important ;}
	.PaddingB10M5{padding-bottom: 10vw !important ;}
	.PaddingL10M5{padding-left: 10vw !important ;}
	.PaddingH10M5{padding-right: 10vw;padding-left: 10vw !important ;}
	.PaddingV10M5{padding-top: 10vw;padding-bottom: 10vw !important ;}
	

	.Padding15M0{padding: 15vw !important ;}
	.PaddingT15M0{padding-top: 15vw !important ;}
	.PaddingR15M0{padding-right: 15vw !important ;}
	.PaddingB15M0{padding-bottom: 15vw !important ;}
	.PaddingL15M0{padding-left: 15vw !important ;}
	.PaddingH15M0{padding-right: 15vw;padding-left: 15vw !important ;}
	.PaddingV15M0{padding-top: 15vw;padding-bottom: 15vw !important ;}

	.Padding15M5{padding: 15vw !important ;}
	.PaddingT15M5{padding-top: 15vw !important ;}
	.PaddingR15M5{padding-right: 15vw !important ;}
	.PaddingB15M5{padding-bottom: 15vw !important ;}
	.PaddingL15M5{padding-left: 15vw !important ;}
	.PaddingH15M5{padding-right: 15vw;padding-left: 15vw !important ;}
	.PaddingV15M5{padding-top: 15vw;padding-bottom: 15vw !important ;}
	
	.Padding15M10{padding: 15vw !important ;}
	.PaddingT15M10{padding-top: 15vw !important ;}
	.PaddingR15M10{padding-right: 15vw !important ;}
	.PaddingB15M10{padding-bottom: 15vw !important ;}
	.PaddingL15M10{padding-left: 15vw !important ;}
	.PaddingH15M10{padding-right: 15vw;padding-left: 15vw !important ;}
	.PaddingV15M10{padding-top: 15vw;padding-bottom: 15vw !important ;}


	.Padding20M0{padding: 20vw !important ;}
	.PaddingT20M0{padding-top: 20vw !important ;}
	.PaddingR20M0{padding-right: 20vw !important ;}
	.PaddingB20M0{padding-bottom: 20vw !important ;}
	.PaddingL20M0{padding-left: 20vw !important ;}
	.PaddingH20M0{padding-right: 20vw;padding-left: 20vw !important ;}
	.PaddingV20M0{padding-top: 20vw;padding-bottom: 20vw !important ;}

	.Padding20M5{padding: 20vw !important ;}
	.PaddingT20M5{padding-top: 20vw !important ;}
	.PaddingR20M5{padding-right: 20vw !important ;}
	.PaddingB20M5{padding-bottom: 20vw !important ;}
	.PaddingL20M5{padding-left: 20vw !important ;}
	.PaddingH20M5{padding-right: 20vw;padding-left: 20vw !important ;}
	.PaddingV20M5{padding-top: 20vw;padding-bottom: 20vw !important ;}
	
	.Padding20M10{padding: 20vw !important ;}
	.PaddingT20M10{padding-top: 20vw !important ;}
	.PaddingR20M10{padding-right: 20vw !important ;}
	.PaddingB20M10{padding-bottom: 20vw !important ;}
	.PaddingL20M10{padding-left: 20vw !important ;}
	.PaddingH20M10{padding-right: 20vw;padding-left: 20vw !important ;}
	.PaddingV20M10{padding-top: 20vw;padding-bottom: 20vw !important ;}

	.Padding20M15{padding: 20vw !important ;}
	.PaddingT20M15{padding-top: 20vw !important ;}
	.PaddingR20M15{padding-right: 20vw !important ;}
	.PaddingB20M15{padding-bottom: 20vw !important ;}
	.PaddingL20M15{padding-left: 20vw !important ;}
	.PaddingH20M15{padding-right: 20vw;padding-left: 20vw !important ;}
	.PaddingV20M15{padding-top: 20vw;padding-bottom: 20vw !important ;}
}

