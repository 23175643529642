
@media screen and (max-width: 980px) {

  .filter_container{
    position: absolute;
    right: 20px;
    bottom: 90px;
    z-index:999;
  }

}


@media screen and (min-width: 981px) {

  .filter_container{
    position: absolute;
    right: 20px;
    bottom: 30px;
    z-index:999;
  }
  
}

.filter{
  /* flex:1; */
  border-radius: 50%;
  background-color: #ad182f;
  height: 60px;
  width: 60px;
  position: relative;
  /* float: left; */
  margin-bottom: 10px;
  cursor: pointer;
}

.text_filter_container{
  position:absolute;
  top:0px;
  left:0px;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text_filter{
  color: #ffffff;
  font-size: 13;
  font-family: FuturaH;
  text-transform: uppercase
}

.image_filter{
  border-radius: 50%;
  height: 100%;
  width: 100%;
}


