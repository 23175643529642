	
	.AppearanceNone{-webkit-appearance: none;-moz-appearance: none;border-width:0 0 0 0;}
	.NoOutline{outline: none;}
	
	/*####################  SHADOWS ####################*/
	.NoBorderShadow{box-shadow: none !important; -webkit-box-shadow: none !important;-moz-box-shadow: none !important;}
	.BottomShadow1{box-shadow: 0 1px 1px -1px #232323 !important;-moz-box-shadow: 0 1px 1px -1px #232323 !important;-webkit-box-shadow: 0 1px 1px -1px #232323 !important;}
	.BottomShadow2{box-shadow: 0 -1px 1px -1px #232323 !important;-moz-box-shadow: 0 -1px 1px -1px #232323 !important;-webkit-box-shadow: 0 -1px 1px -1px #232323 !important;}
	.BottomShadow3{box-shadow: 0 0 1px 1px #232323 !important;-moz-box-shadow: 0 0 1px 1px #232323 !important;-webkit-box-shadow: 0 0 1px 1px #232323 !important;}
	.BottomShadow4{box-shadow: 0 0 -1px 1px #232323 !important;-moz-box-shadow: 0 0px -1px 1px #232323 !important;-webkit-box-shadow: 0 0 -1px 1px #232323 !important;}
	.BottomShadow5{box-shadow: -1px 1px 1px 1px #232323 !important;-moz-box-shadow:-1px 1px 1px 1px #232323 !important;-webkit-box-shadow: -1px 1px 1px 1px #232323 !important;}
	.BottomShadow6{box-shadow: -1px 1px 1px 1px #bbc8dc !important;-moz-box-shadow: -1px 1px 1px 1px #bbc8dc !important;-webkit-box-shadow: -1px 1px 1px 1px #bbc8dc !important;}
	.BottomShadow7{box-shadow: 0px 1px 3px 0px #D3D3D3 !important;-moz-box-shadow: 0px 1px 3px 0px #D3D3D3 !important;-webkit-box-shadow: 0px 1px 3px 0px #D3D3D3 !important;}
	.BottomShadow8{box-shadow: 0px 1px 3px 0px #232323 !important;-moz-box-shadow: 0px 1px 3px 0px #232323 !important;-webkit-box-shadow: 0px 1px 3px 0px #232323 !important;}
	.BottomShadow9{box-shadow: 0px 1px 3px 0px #bbc8dc !important;-moz-box-shadow: 0px 1px 3px 0px #bbc8dc !important;-webkit-box-shadow: 0px 1px 3px 0px #bbc8dc !important;}
	.BottomShadow10{box-shadow: 0px 1px 3px 0px gray !important;-moz-box-shadow: 0px 1px 3px 0px gray !important;-webkit-box-shadow: 0px 1px 3px 0px gray !important;}
	
	.BottomShadowBlue{box-shadow: 0px 1px 3px 0px #58ACFA;-moz-box-shadow: 0px 1px 3px 0px #58ACFA;-webkit-box-shadow: 0px 1px 3px 0px #58ACFA;}
	.BottomShadowBlack{box-shadow: 0px 1px 3px 0px black;-moz-box-shadow: 0px 1px 3px 0px black;-webkit-box-shadow: 0px 1px 3px 0px black;}
	
	.BottomShadowOverBlue:hover{box-shadow: 0px 1px 3px 0px #58ACFA;-moz-box-shadow: 0px 1px 3px 0px #58ACFA;-webkit-box-shadow: 0px 1px 3px 0px #58ACFA;}
	.BottomShadowOverBlack:hover{box-shadow: 0px 1px 3px 0px black;-moz-box-shadow: 0px 1px 3px 0px black;-webkit-box-shadow: 0px 1px 3px 0px black;}
	

	/*################## INPUT SHADOW  ################*/
	.InputShadow2{border-color: #f7f7f7 #f7f7f7 #f5f5f5;border-radius: 0px;webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.38);box-shadow:1px 1px 1px rgba(0, 0, 0, 0.38);}
	.InputShadow20{border-color: #f7f7f7 #f7f7f7 #f5f5f5;border-radius: 0px;webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.48);box-shadow:0px 0px 2px rgba(0, 0, 0, 0.48);}
	.InputShadow21{border-color: #f7f7f7 #f7f7f7 #f5f5f5;border-radius: 0px;webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.48);box-shadow:0px 1px 2px rgba(0, 0, 0, 0.48);}
	.InputShadow22{border-color: #f7f7f7 #f7f7f7 #f5f5f5;border-radius: 0px;webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.48);box-shadow:1px 1px 2px rgba(0, 0, 0, 0.48);}
	.InputShadow22C{border-color: #f7f7f7 #f7f7f7 #f5f5f5;border-radius: 50%;webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.48);box-shadow:1px 1px 2px rgba(0, 0, 0, 0.48);}
	.InputShadow23{border-color: #f7f7f7 #f7f7f7 #f5f5f5;border-radius: 0px;webkit-box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.48);box-shadow:1px 0px 2px rgba(0, 0, 0, 0.48);}
	.InputShadow24{border-color: #f7f7f7 #f7f7f7 #f5f5f5;border-radius: 0px;webkit-box-shadow: 2px 0px 0px rgba(0, 0, 0, 0.48);box-shadow:2px 0px 0px rgba(0, 0, 0, 0.48);}
	.InputShadow5{border-radius: 3px;webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.38);box-shadow:0px 1px 4px rgba(0, 0, 0, 0.38);}
	.InputShadow3{border-radius: 3px;webkit-box-shadow: 0px 1px 4px rgba(159, 182, 205, 0.88);box-shadow:0px 1px 4px rgba(159, 182, 205, 0.88);}
	.InputShadow6{border-radius: 3px;webkit-box-shadow: 0px 1px 4px rgba(50, 50, 50, 0.68);box-shadow: 0px 1px 4px rgba(50, 50, 50, 0.68);}
	.InputShadow1{border-radius: 3px;webkit-box-shadow: 0px 0px 2px rgba(100, 152, 175, 1.00);box-shadow:0px 0px 2px rgba(100, 152, 175, 1.00);}
	.InputShadow2{border-radius: 3px;webkit-box-shadow: 0px 1px 3px rgba(159, 182, 205, 0.68);box-shadow:0px 1px 3px rgba(159, 182, 205, 0.68);}
	.InputShadow4{border-radius: 3px;webkit-box-shadow: 0px 0px 2px rgba(129, 152, 175, 1);	box-shadow:0px 0px 2px rgba(129, 152, 175, 1);}
	.InputShadow3{border-radius: 3px;webkit-box-shadow: 0px 0px 4px rgba(159, 182, 205, 0.68);box-shadow:0px 0px 4px rgba(159, 182, 205, 0.68);}
	.InputShadow7{border-radius: 3px;webkit-box-shadow: 0px 0px 4px rgba(50, 50, 50, 0.68);box-shadow:0px 0px 4px rgba(50, 50, 50, 0.68);}
	.InputShadow8{border-radius: 3px;webkit-box-shadow: 0px 0px 4px rgba(50, 50, 50, 0.88);box-shadow:0px 0px 4px rgba(50, 50, 50, 0.88);}
	.InputShadow0{border-radius: 0px;webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 1.00);box-shadow:0px 0px 2px rgba(0, 0, 0, 1.00);}

	
	/*################## TEXT SHADOW  ################*/
	.TextShadowBlack1{text-shadow:  1px 1px 3px #000;}
	.TextShadowGray1{text-shadow:  1px 1px 3px gray;}
	.TextShadowBlue1{text-shadow:  1px 1px 3px #839CA5;}
	.TextShadowSilver1{text-shadow:  1px 1px 3px #C8C8C8;}
	
	.TextShadowBlack2{text-shadow:0px 0px 5px #000;}
	.TextShadowGray2{text-shadow:0px 0px 5px gray;}
	.TextShadowBlue2{text-shadow: 0px 0px 5px #839CA5;}
	.TextShadowSilver2{text-shadow:0px 0px 5px #C8C8C8;}
	
	.TextShadowBlack3{text-shadow:0px 0px 8px #000;}
	.TextShadowGray3{text-shadow:0px 0px 8px gray;}
	.TextShadowBlue3{text-shadow: 0px 0px 8px #839CA5;}
	.TextShadowSilver3{text-shadow:0px 0px 8px #C8C8C8;}

	.TextShadowOverBlack1:hover{text-shadow:  1px 1px 3px #000;}
	.TextShadowOverGray1:hover{text-shadow:  1px 1px 3px gray;}
	.TextShadowOverBlue1:hover{text-shadow:  1px 1px 3px #839CA5;}
	.TextShadowOverSilver1:hover{text-shadow:  1px 1px 3px #C8C8C8;}
	
	.TextShadowOverBlack2:hover{text-shadow:0px 0px 5px #000;}
	.TextShadowOverGray2:hover{text-shadow:0px 0px 5px gray;}
	.TextShadowOverBlue2:hover{text-shadow: 0px 0px 5px #839CA5;}
	.TextShadowOverSilver2:hover{text-shadow:0px 0px 5px #C8C8C8;}
	
	.TextShadowBlack3:hover{text-shadow:0px 0px 8px #000;}
	.TextShadowGray3:hover{text-shadow:0px 0px 8px gray;}
	.TextShadowBlue3:hover{text-shadow: 0px 0px 8px #839CA5;}
	.TextShadowSilver3:hover{text-shadow:0px 0px 8px #C8C8C8;}

	.TextShadowOverblack1:hover{text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;}
	.TextShadowOverblack2:hover{text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;}
	.TextShadowOverblack3:hover{text-shadow: -3px 0 black, 0 3px black, 3px 0 black, 0 -3px black;}
	.TextShadowOverblack4:hover{text-shadow: -4px 0 black, 0 4px black, 4px 0 black, 0 -4px black;}

	/*###############  FONT DECORATION #####################*/
	.NoDecoration{text-decoration:none;color: inherit;}
	.Bold{font-weight:bold;}
	.Italic{font-style:italic;}
	.OverUnderline:hover{text-decoration: underline;}
	.OutlineNone{outline: none;}
	.Underline{text-decoration: underline;}
	.HoverUnderline:hover{text-decoration: underline;}
	.BreakWord{word-wrap: break-word;}
	
	/*################## MOUSE ICONE ##############*/
	.Hand{cursor: pointer;}
	.Move{cursor: move;}
	.ZoomIn{cursor: zoom-in;}
	.ZoomOut{cursor: zoom-out;}

	/*############## Currency Symbol ################*/
	.EURSymbol{content:"€";}
	.USDSymbol{content:"$";}

	/*##################  Transaction confirm detail button  ###################*/
	.Confirm{}
	.NoBorderShadow{box-shadow: none;-webkit-box-shadow: none;-moz-box-shadow: none;}
	.BottomShadow{box-shadow: 0 4px 4px -3px #232323;-moz-box-shadow: 0 4px 4px -3px #232323;-webkit-box-shadow: 0 4px 4px -3px #232323;}
	.TextShadow1{text-shadow: -3px 0 black, 0 3px black, 3px 0 black, 0 -3px black;}
	.TextShadow2{text-shadow: -3px 0 white, 0 3px white, 3px 0 white, 0 -3px white;}
	.Bold{font-weight:bold;}
	.Pointer{cursor:pointer;}
	.OverUnderline:hover{text-decoration: underline;}
	.PreWhiteSpace {white-space: pre-wrap;white-space: -moz-pre-wrap;white-space: -pre-wrap;white-space: -o-pre-wrap;word-wrap: break-word;}
	.NoWhiteSpace {white-space: nowrap;white-space: -moz-nowrap;white-space: -pre-wrap;white-space: -o-nowrap;word-wrap: nowrap;}
	.Capital{text-transform: uppercase;}
	.Ellipsis{text-overflow: ellipsis}
	.TextFadeOut {
		content:'';
		background-color: red;
		width:100%;
		height:100%;    
		position:absolute;
		left:0;
		top:0;
		background:linear-gradient(transparent 80%, white);
	}
